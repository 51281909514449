import { useState } from 'react';
import { useQuery } from 'react-query';

import { getSampleRequestPatientsByCount } from 'apis/sampleRequest';

import styled from 'styled-components';

import { SpaceBetween } from 'components/atoms/common';
import { ModalContainer } from 'components/atoms/modal';
import { Table } from 'components/atoms/table';
import { BtnBlue, BtnPink } from 'components/atoms/button';

import Pagination from 'components/organisms/Pagination';


const display = 10;

const ModalSampleRequest = ({
  templateDefaultId,
  thOptions, thItems,
  state, setState, position,
  onClose
}: {
  templateDefaultId: number;
  thOptions: any[]; thItems: any[];
  state: any; setState: any; position: number;
  onClose: () => void;
}) => {
  const { isLoading } = useQuery(
    ['getSampleRequestPatientsByCount', state.id, { templateDefaultId, position }],
    () => getSampleRequestPatientsByCount(state.id, { templateDefaultId, position }),
    {
      onSuccess: ({ patients }) => {
        setPatients(patients.map((patient: any, index: number) => ({ ...patient, index })));
      }
    }
  );

  const [code, setCode] = useState('');
  const [page, setPage] = useState(1);
  const [patients, setPatients]: any[] = useState([]);

  const patientFilter = (patient: any) => {
    const patientIds: number[] = state.patients[templateDefaultId].map((patient: any) => patient.id);

    return !patientIds.includes(patient.id) && (code ? patient.code.includes(code) : true);
  }

  const onChange = ({ target }: { target: any; }) => {
    setCode(target.value);
    setPage(1);
  };

  const onChangeValue = (target: any, patientIndex: number, itemId: number) => {
    if (Number(target.value) > 0) {
      patients[patientIndex].items[itemId] = Number(target.value.includes('.') ? target.value.replace('.', '') : target.value);
    } else {
      delete patients[patientIndex].items[itemId];
    }

    setPatients([ ...patients ]);
  };

  const onClick = (patient: any) => {
    if (validationPatients(patient)) {
      state.patients[templateDefaultId].unshift(patient);

      setState({ ...state });
    }
  };

  const validationPatients = (patient: any) => {
    if (Object.keys(patient.items).length === 0) {
      alert('수량을 입력하세요.');

      return false;
    }

    return true;
  };

  if (isLoading) {
    return <></>;
  }

  return <ModalContainer style={{ maxWidth: '1400px' }}>
    <Title>환자 목록</Title>
    <SpaceBetween>
      <div>
        <Total>총 {patients.filter(patientFilter).length}건</Total>
      </div>
      <div>
        <Input type="text" value={code} onChange={onChange} placeholder="고유번호로 검색" />
      </div>
    </SpaceBetween>
    <Table>
      <thead>
        <TR>
          <TH rowSpan={2} width="40px" borderLeft={false}>순번</TH>
          <TH rowSpan={2} width="100px">고유번호</TH>
          <TH rowSpan={2} width="180px">병원</TH>
          {thOptions.map((option: any, index: number) =>
            <TH key={`key-request-count-patient-thead-group-${index}`} colSpan={option.colSpan} rowSpan={option.rowSpan}>{option.value}</TH>
          )}
          <TH rowSpan={2} width="43px">목록</TH>
        </TR>
        <TR>
          {thItems.filter((item: any) => item.name !== '').map((item: any, index: number) =>
            <TH key={`key-request-count-patient-thead-${index}`} borderTop={false}>{item.name}</TH>
          )}
        </TR>
      </thead>
      <tbody>
        {patients.filter(patientFilter).map((patient: any, rowIndex: number) => (((page - 1) * display < rowIndex + 1) && (rowIndex + 1 <= page * display))
          && <TR key={`key-request-count-patient-tbody-${rowIndex}`}>
            <TD borderLeft={false}>{patients.filter(patientFilter).length - rowIndex}</TD>
            <TD>{patient.code}</TD>
            <TD>{patient.hospital_name}</TD>
            {thItems.map((item: any, index: number) =>
              <TD key={`key-request-count-patient-tbody-${rowIndex}-${index}`}>
                <Input type="number" value={patient.items[item.id] || ''} onChange={({ target }) => onChangeValue(target, patient.index, item.id)} min="0" />
              </TD>
            )}
            <TD><BtnPink xSmall onClick={() => onClick(patient)}>추가</BtnPink></TD>
          </TR>
        )}
      </tbody>
    </Table>
    <Footer>
      <Pagination total={patients.filter(patientFilter).length} display={display} page={page} onChange={setPage} />
    </Footer>
    <SpaceBetween>
      <div></div>
      <div><BtnBlue onClick={onClose}>닫기</BtnBlue></div>
    </SpaceBetween>
  </ModalContainer>;
};

const Title = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  margin-bottom: 25px;
`;

const Total = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  flex: 1;
  margin-left: 10px;
`;

const TR = styled.tr`
  border-bottom: 1px solid #dfe0e0;
  &:hover { background: #f9f9f8; }
`;

const TH = styled.th<{
  width?: string;
  borderTop?: boolean;
  borderLeft?: boolean;
}>`
  text-align: center;
  color: #989899;
  background: #fafafc;
  height: 45px;
  font-size: 14px;
  width: ${({ width }) => width};
  border-top: ${({ borderTop=true }) => borderTop && '1px solid #000000'};
  border-left: ${({ borderLeft=true }) => borderLeft && '1px solid #dfe0e0'};
`;

const TD = styled.td<{
  borderLeft?: boolean;
}>`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  height: 31px;
  border-left: ${({ borderLeft=true }) => borderLeft && '1px solid #dfe0e0'};
  &:hover { background: #f3f3f1; }
`;

const Input = styled.input`
  padding: 5px;
  background: unset;
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  width: 100%;
  height: 30px;
`;

const Footer = styled.footer`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;

export default ModalSampleRequest;

import styled from "styled-components";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

import { ReactComponent as Trash } from "assets/icons/Trash.svg";
import { ReactComponent as Correction } from "assets/icons/Correction.svg";
import {
  getTemplateDefaults,
  deleteTemplateDefault,
} from "apis/templateDefault";
import TemplateDefaultType from "types/templateDefault";

import { Table, TR, TH, TD } from "components/atoms/table";
import { SpaceBetween, Click } from "components/atoms/common";
import { BtnBlue } from "components/atoms/button";
import Modal from "components/organisms/Modal";
import ModalTemplateDefault from "components/templates/admin/Templates/Modal";
import ModalTemplateDefaultSectionWrapper from "components/templates/admin/Templates/ModalSectionWrapper";

const TemplateDefaults = () => {
  const { projectId = "" } = useParams();
  const { data, isLoading } = useQuery(
    ["getTemplateDefaults", { projectId }],
    () => getTemplateDefaults({ projectId })
  );

  const handleDelete = (id: number) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      deleteTemplateDefault(id);
    }
  };

  if (isLoading) return <></>;

  return (
    <Container>
      <SpaceBetween>
        <Title>총 {data.length} 개</Title>
        <Modal
          activator={(onOpen) => (
            <BtnBlue onClick={onOpen}>기본 템플릿 추가 +</BtnBlue>
          )}
          content={(onClose) => <ModalTemplateDefault onClose={onClose} />}
        />
      </SpaceBetween>
      <Table>
        <thead>
          <TR>
            <TH>순번</TH>
            <TH>이름</TH>
            <TH>방문예정일</TH>
            <TH>섹션관리</TH>
            <TH>관리</TH>
          </TR>
        </thead>
        <tbody>
          {data.map((td: TemplateDefaultType, i: number) => (
            <TR key={td.id}>
              <TD>{i + 1}</TD>
              <TD>{td.name}</TD>
              <TD>{td.day_after}일 후</TD>
              <TD>
                <Modal
                  activator={(onOpen) => (
                    <Click onClick={onOpen}>섹션 관리</Click>
                  )}
                  content={(onClose) => (
                    <ModalTemplateDefaultSectionWrapper
                      onClose={onClose}
                      id={td.id}
                    />
                  )}
                />
              </TD>
              <TD>
                <Modal
                  activator={(onOpen) => (
                    <BtnCorrection>
                      <Correction onClick={onOpen} />
                    </BtnCorrection>
                  )}
                  content={(onClose) => (
                    <ModalTemplateDefault onClose={onClose} data={td} />
                  )}
                />
                <BtnCorrection>
                  <Trash onClick={() => handleDelete(td.id)} />
                </BtnCorrection>
              </TD>
            </TR>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 27px;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  flex: 1;
`;
const BtnCorrection = styled.button`
  background-color: #fff;
  outline: none;
  border: none;
`;

export default TemplateDefaults;

import { Link } from "react-router-dom";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { getItemOptions, deleteItemOption } from "apis/itemOption";
import { ItemOptionType, ItemType } from "types";
import { ReactComponent as ImgDelete } from "assets/icons/delete.svg";

import { BtnBlue, BtnSkyBlue, BtnBlack } from "components/atoms/button";
import { Input } from "components/atoms/input";
import Modal from "components/organisms/Modal";
import { Item, Name, Value } from "components/atoms/item";
import ModalAddItem from "components/organisms/item/ModalAdd";
import ModalOption from "./ModalOption";

const EditItemOptions = ({ itemId }: { itemId: number }) => {
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery(["getItemOptions", itemId], () =>
    getItemOptions({ itemId })
  );
  const mutation = useMutation((id: number) => deleteItemOption(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getItemOptions"]);
    },
  });

  const handleDelete = async (id: number, subItemCount: number) => {
    if (subItemCount > 0) return alert("하위질문을 먼저 삭제해 주세요.");
    if (!window.confirm("옵션을 삭제하시겠습니까?")) return;
    mutation.mutate(id);
  };

  if (isLoading) return <></>;
  return (
    <Item size={1}>
      <Name>옵션</Name>
      <Value>
        <Container>
          {data.map((option: ItemOptionType) => {
            const { id, value, subItems } = option;
            return (
              <Option key={id}>
                <ValueContainer key={id}>
                  <BtnDelete
                    type="button"
                    onClick={() => handleDelete(id, subItems.length)}
                  >
                    <ImgDelete />
                  </BtnDelete>
                  <Input readOnly value={value} middle />
                  <Modal
                    activator={(onOpen) => (
                      <BtnBlack onClick={onOpen}>수정</BtnBlack>
                    )}
                    content={(onClose) => (
                      <ModalOption data={option} onClose={onClose} />
                    )}
                  />

                  <Modal
                    activator={(onOpen) => (
                      <BtnBlue onClick={onOpen} noline small>
                        연계 항목 +
                      </BtnBlue>
                    )}
                    content={(onClose) => (
                      <ModalAddItem optionId={id} onClose={onClose} />
                    )}
                  />
                </ValueContainer>
                {subItems.length > 0 && (
                  <SubItems>
                    {subItems
                      .sort((a, b) => a.position - b.position)
                      .map(({ id, name }: ItemType) => (
                        <Link to={`../item/${id}`} key={id}>
                          <SubItem>{name}</SubItem>
                        </Link>
                      ))}
                  </SubItems>
                )}
              </Option>
            );
          })}
          <BtnAddWrapper>
            <Modal
              activator={(onOpen) => (
                <BtnSkyBlue onClick={onOpen} small>
                  옵션 추가 +
                </BtnSkyBlue>
              )}
              content={(onClose) => (
                <ModalOption itemId={itemId} onClose={onClose} />
              )}
            />
          </BtnAddWrapper>
        </Container>
      </Value>
    </Item>
  );
};

const Container = styled.div`
  flex: 1;
`;

const Option = styled.div`
  margin-bottom: 10px;
`;

const ValueContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BtnDelete = styled.button`
  padding: 0;
  background: none;
  border: none;
  margin-right: 7px;
  display: flex;
  align-items: center;
`;

const SubItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0px 20px;
`;
const SubItem = styled.div`
  background: #f5f5f7;
  border-radius: 4px;
  padding: 10px;
  font-size: 12px;
  margin: 5px;
`;

const BtnAddWrapper = styled.div`
  margin-left: 23px;
`;

export default EditItemOptions;

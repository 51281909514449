import PatientType from "types/patient";
import getAge from "./common/getAge";

const fuageYear = {
  id: 33,
  name: "fuageYear",
  datas: ["fu_date"],
  formula: ({ fu_date }: any, { birthday }: PatientType) => {
    if (!fu_date || !birthday) {
      return;
    }
    return (getAge(birthday, fu_date) / 12).toFixed(1);
  },
  help: `만나이() = (((측정년도-출생년도)×12))+(측정월-출생월)+((측정일-출생일)÷30.4))/12)`,
};

export default fuageYear;

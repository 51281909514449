import BoardType from "types/board";
import instance from ".";

// notice: 공지사항 게시판
export const getBoards = async (params: {
  page: number;
  query: string;
  startDate: string;
  endDate: string;
}) => {
  try {
    const result = await instance.get("/boards", { params });
    return result.data;
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
  }
};

export const getBoard = async (id: string) => {
  if (!id) return;
  const result = await instance.get(`/boards/${id}`);
  return result.data;
};

export const createBoard = async (values: BoardType) => {
  const data = {
    ...values,
  };
  const result = await instance.post(`/boards`, data);
  return result.data;
};

export const editBoard = async (values: BoardType) => {
  const data = {
    ...values,
  };
  const result = await instance.put(`/boards/${values.id}`, data);
  return result.data;
};

export const deleteBoard = async (id: number) => {
  try {
    const response = await instance.delete(`/boards/${id}`);
    if (response.status === 200) {
      alert("게시글을 삭제하였습니다.");
    }
    return response;
  } catch (err: any) {
    if (err.response) {
      alert(err.response.data.message);
    }
  }
};

import { Fragment } from 'react';

import styled from 'styled-components';

import { BtnBlue, BtnGray } from 'components/atoms/button';

import Modal from 'components/organisms/Modal';
import ModalSampleRequest from 'components/templates/sample/request/Modal';

import { ReactComponent as Trash } from 'assets/icons/Trash.svg';


const SampleRequestFormCount = ({
  templateDefaultId,
  count, state, setState,
  isTemp, isRequest,
  isAdmin,
  getThOptions, getThItems
}: {
  templateDefaultId: number;
  count: any; state: any; setState: any;
  isTemp: boolean; isRequest: boolean;
  isAdmin: boolean;
  getThOptions: any; getThItems: any;
}) => {
  const section = count[templateDefaultId].sections[0];
  const thItems: any = getThItems(count[templateDefaultId][section.id]);
  const thOptions: any = getThOptions(count[templateDefaultId][section.id], thItems);

  const onChange = ({ target, rowIndex }: { target: any; rowIndex: number; }) => {
    if (target.name === 'count-item-sort') {
      const rows = state.count.map((count: any) => count[templateDefaultId]);
      const row = rows.splice(rowIndex, 1)[0];

      rows.splice(target.value, 0, row);

      state.count.forEach((count: any, index: number) => count[templateDefaultId] = rows[index]);
    } else {
      const key = target.name.replace('count-item-', '');

      if (Number(target.value) > 0) {
        if (!state.count[rowIndex][templateDefaultId]) {
          state.count[rowIndex][templateDefaultId] = {};
        }

        state.count[rowIndex][templateDefaultId][key] = target.value.includes('.') ? target.value.replace('.', '') : target.value;
      } else {
        delete state.count[rowIndex][templateDefaultId][key];
      }
    }

    setState({ ...state });
  };

  const onClick = () => {
    state.count.push({});
    count.templates.forEach((template: any) => state.count[state.count.length - 1][template.id] = {});

    setState({ ...state });
  };

  const onDelete = (rowIndex: number) => {
    state.count.splice(rowIndex, 1);

    setState({ ...state });
  };

  return <>
    <TemplateHeader>{section.name}</TemplateHeader>
    <Table>
      <thead>
        <TR>
          <TH rowSpan={2} width="60px" whiteSpace="pre-line" borderLeft={false}>{`우선\n순위`}</TH>
          {thOptions.map((option: any, index: number) =>
            <TH key={`key-request-count-${section.id}-thead-group-${index}`} colSpan={option.colSpan} rowSpan={option.rowSpan}>{option.value}</TH>
          )}
          {(isTemp || (isRequest && isAdmin))
            && <TH rowSpan={2} width="45px">{isTemp ? '삭제' : '환자'}</TH>
          }
        </TR>
        <TR>
          {thItems.filter((item: any) => item.name !== '').map((item: any, index: number) =>
            <TH key={`key-request-count-${section.id}-thead-${index}`}>{item.name}</TH>
          )}
        </TR>
      </thead>
      <tbody>
        {state.count.map((count: any, rowIndex: number) =>
          <TR key={`key-request-count-${section.id}-tbody-${rowIndex}`}>
            <TD borderLeft={false}>
              {isTemp
                ? <Select name="count-item-sort" value={rowIndex} onChange={({ target }) => onChange({ target, rowIndex })}>
                  {state.count.map((count: any, sortIndex: number) =>
                    <option key={`key-request-count-${section.id}-sort-${rowIndex}-${sortIndex}`} value={sortIndex}>{sortIndex + 1}</option>
                  )}
                </Select>
                : rowIndex + 1
              }
            </TD>
            {thItems.map((item: any, index: number) =>
              <TD key={`key-request-count-${section.id}-tbody-${rowIndex}-${index}`}>
                {isTemp
                  ? <Input type="number" name={`count-item-${item.id}`} value={count[templateDefaultId]?.[item.id] || ''} onChange={({ target }) => onChange({ target, rowIndex })} min="0" />
                  : count[templateDefaultId]?.[item.id]
                }
              </TD>
            )}
            {(isTemp || (isRequest && isAdmin))
              && <TD>
                {isTemp
                  ? <BtnCorrection onClick={() => onDelete(rowIndex)}><Trash /></BtnCorrection>
                  : <Modal activator={(onOpen) => count[templateDefaultId] ? <BtnBlue xSmall onClick={onOpen}>조회</BtnBlue> : <BtnGray xSmall disabled>조회</BtnGray>}
                    content={(onClose) => <ModalSampleRequest thOptions={thOptions} thItems={thItems} templateDefaultId={templateDefaultId} state={state} setState={setState} position={rowIndex} onClose={onClose} />}
                  />
                }
              </TD>
            }
          </TR>
        )}
      </tbody>
    </Table>
    {isTemp
      && <SpaceBetween>
        <div></div>
        <div><BtnBlue small onClick={onClick}>수량 조건 추가</BtnBlue>
        </div>
      </SpaceBetween>
    }
  </>;
};

const TemplateHeader = styled.header`
  color: #0043b7;
  font-weight: 600;
  font-size: 15px;
  line-height: 35px;
  text-align: center;
  background: #edf1fa;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid #dfe0e0;
  min-height: 30px;
`;

const Name = styled.div`
  background: #fafafc;
  border-right: 1px solid #dfe0e0;
  width: 150px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  word-break: keep-all;
`;

const Value = styled.div`
  display: flex;
  min-height: 20px;
  align-items: center;
  padding: 2px;
  flex: 1;
  font-size: 14px;
  position: relative;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 0px;
  border-top: 1px solid #dfe0e0;
`;

const TR = styled.tr`
  border-bottom: 1px solid #dfe0e0;
  &:hover { background: #f9f9f8; }
`;

const TH = styled.th<{
  width?: string;
  whiteSpace?: string;
  borderLeft?: boolean;
}>`
  text-align: center;
  color: #989899;
  background: #fafafc;
  font-size: 14px;
  height: 45px;
  width: ${({ width }) => width};
  white-space: ${({ whiteSpace }) => whiteSpace};
  border-left: ${({ borderLeft = true }) => borderLeft && '1px solid #dfe0e0'};
`;

const TD = styled.td<{
  borderLeft?: boolean;
}>`
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  height: 31px;
  border-left: ${({ borderLeft = true }) => borderLeft && '1px solid #dfe0e0'};
  &:hover { background: #f3f3f1; }
`;

const Input = styled.input<{
  isTransparent?: boolean;
}>`
  padding: 5px;
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  width: 100%;
  height: 30px;
  background: ${({ isTransparent = true }) => isTransparent ? 'unset' : '#ffffff'};
`;

const Select = styled.select`
  width: 100%;
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  height: 30px;
  background: unset;
`;

const Span = styled.span`
  margin-left: 5px;
  margin-right: 15px;
`;

const BtnCorrection = styled.button`
  background-color: #fff;
  outline: none;
  border: none;
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
`;

export default SampleRequestFormCount;

import { atom } from "recoil";
import SampleStockSearchType from "types/sampleStockSearch";

const sampleStockSearchStateInit = atom<SampleStockSearchType>({
  key: "sampleStockSearchStateInit",
  default: {
    tab: 'stock',
    templateDefaultId: '',
    keyword: '',
    isHold: '',
    hospitalId: '',
    page: 1
  },
});

export default sampleStockSearchStateInit;

import styled from "styled-components";

import { ReactComponent as ImgHelp } from "assets/icons/help.svg";

import Dropdown from "components/organisms/Dropdown";

const ItemHelp = ({ data }: { data: string }) => {
  return (
    <Dropdown
      activator={(onOpen) => (
        <BtnHelp onClick={onOpen} type="button">
          <ImgHelp />
        </BtnHelp>
      )}
      content={
        <Help>
          <Title>도움말</Title>
          <Content>{data}</Content>
        </Help>
      }
    />
  );
};

const BtnHelp = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
`;

const Help = styled.div`
  padding: 15px 20px;
  background: #fbfbfb;
  border: 1px solid #dfe0e0;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.15);
  width: 300px;
`;
const Title = styled.span`
  color: #0043b7;
  font-weight: 500;
  font-size: 12px;
`;
const Content = styled.div`
  font-size: 12px;
  line-height: 17px;
  white-space: pre-wrap;
  margin-top: 10px;
  text-align: left;
  color: #000000;
  font-weight: normal;
`;

export default ItemHelp;

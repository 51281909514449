import getPeriod from "./common/dateCalculator";

const period = {
  id: 24,
  name: "period",
  datas: ["start_date", "end_date"],
  formula: ({ start_date, end_date }: any) => {
    return getPeriod(start_date, end_date);
  },
  help: `날짜 일수 계산`,
};

export default period;

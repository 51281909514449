import instance from ".";

export const getStudies = async (params: { projectId: string }) => {
  try {
    const result = await instance.get(`/study`, { params });
    result.data.studyId = result.data[0].id;
    result.data.studyTitle = result.data[0].study_title;
    return result.data;
  } catch (error) {}
};

export const getStudyTitles = async (params: { projectId: string }) => {
  const result = await instance.get(`/study/titles`, { params });
  return result.data;
};

export const createStudy = async (data: {
  projectId: string;
  studyTitle: string;
  studyName: string;
}) => {
  const result = await instance.post(`/study`, data);
  return result.data;
};

export const editStudy = async (data: {
  id: number;
  studyTitle: string;
  studyName: string;
}) => {
  const result = await instance.put(`/study/${data.id}`, data);
  return result.data;
};

export const getStudy = async (id: number) => {
  const result = await instance.get(`/study/${id}`);
  return result.data;
};

export const deleteStudy = async (id: number) => {
  const result = await instance.delete(`/study/${id}`);
  return result.data;
};

export const assignStudy = async (data: {
  studyId: number;
  patientId: number;
  studyValue?: string;
}) => {
  const result = await instance.post(`/study/${data.studyId}`, data);
  return result.data;
};

export const initStudy = async (studyId: number) => {
  const result = await instance.delete(`/study/${studyId}/init`);
  return result.data;
};

export const geteGFRValue = async (patientId: number) => {
  const result = await instance.get(`/study/eGFR/${patientId}`);
  console.log(result.data[0]);
  if (result.data.length !== 1) {
    console.log("[Err] eGFR has multiple values.");
    return "Invalid value";
  }
  return result.data[0].content;
};

export const getWillAddTemplates = async (studyId: number) => {
  const result = await instance.get(`/study/templates/${studyId}`);
  return result.data;
};

/** study 난수표 76 */
const ER = [
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "4주",
  "6주",
  "6주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "4주",
  "6주",
  "6주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "4주",
  "6주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
];

/** 124 */
const IR = [
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "4주",
  "4주",
  "6주",
  "6주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "4주",
  "6주",
  "6주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "4주",
  "4주",
  "6주",
  "6주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "6주",
  "4주",
];

/** 50 */
const LR = [
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "6주",
  "4주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "6주",
  "4주",
  "6주",
  "4주",
  "4주",
  "6주",
  "4주",
  "6주",
  "4주",
  "6주",
  "6주",
  "4주",
];

// a : CNI 단독군
// b : CNI+MMF 병합요법군
/** eGRF >= 90 인 정상신기능군 */
const SRNS_Normal = [
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
];

/** eGRF < 90인 신기능저하군 */
const SRNS_Under = [
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
  "CNI+MMF 병합요법군",
  "CNI 단독군",
];

/** 임상시험을 위한 난수표입니다 */
export const randomPaper = {
  ER: ER, // 조기
  IR: IR, // 중기
  LR: LR, // 후기
  SRNS_Normal: SRNS_Normal,
  SRNS_Under: SRNS_Under,
};

import { useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { OnChangeType, ItemType, ItemParentType } from "types";
import ItemHelp from "../ItemHelp";

import { useQuery } from "react-query";
import { getItems } from "apis/item";
import { useParams } from "react-router";
import { BtnBlue } from 'components/atoms/button';

const Radio = ({
  data,
  onChange,
  value,
  readOnly,
}: {
  data: ItemType;
  onChange: OnChangeType;
  value: any;
  readOnly?: boolean;
}) => {
  const { is_required, options } = data;
  const id = data.id + "";
  const valueList = value ?? [];

  const { sectionId = "", projectId = "" } = useParams();

  useEffect(() => {
    onChange({ target: { name: id, value: valueList } });
  }, []);

  const itemId = data.id;

  const itemName = data.name;

  const { data: items, isLoading: isLoadingItems } = useQuery(
    ["getItems", { sectionId, projectId }],
    () => getItems({ sectionId, projectId })
  );

  const sortedData = items?.sort(
    (a: ItemType, b: ItemType) => a.position - b.position
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    onChange({ target: { name: id, value: [+value] } });

    const subItems = sortedData.filter(
      ({ parent }: { parent: ItemParentType }) => parent.id === itemId
    );
    if (subItems.length > 0) {
      for (const subItem of subItems) {
        onChange({ target: { name: subItem.id.toString(), value: null } });
        if (subItem.options) {
          const subItems = sortedData.filter(
            ({ parent }: { parent: ItemParentType }) => parent.id === subItem.id
          );
          if (subItems.length > 0) {
            for (const subItem of subItems) {
              onChange({
                target: { name: subItem.id.toString(), value: null },
              });
              if (subItem.options) {
                const subItems = sortedData.filter(
                  ({ parent }: { parent: ItemParentType }) =>
                    parent.id === subItem.id
                );
                if (subItems.length > 0) {
                  for (const subItem of subItems) {
                    onChange({
                      target: { name: subItem.id.toString(), value: null },
                    });
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  const reset = () => {
    onChange({ target: { name: id, value: null } });
  };

  return (
    <>
      <div>
        {[...options]
          ?.sort((a, b) => a.position - b.position)
          .map(({ id, value, help }) => (
            <Option key={id}>
              <label>
                <input
                  type="radio"
                  name={data.id + ""}
                  onChange={handleChange}
                  value={id ?? ""}
                  checked={valueList.includes(id)}
                  readOnly={readOnly}
                  required={is_required === 1}
                />
                {value}
              </label>
              {help && <ItemHelp data={help} />}
            </Option>
          ))}
      </div>
      {projectId === "674" && itemName === "용법" && (
        <Reset outline onClick={reset} small>
          reset
        </Reset>)}
    </>
  );
};

const Option = styled.div`
  display: flex;
  align-items: center;
`;

const Reset = styled(BtnBlue)`
  margin-left:auto;
  margin-right: 5px;
`;

export default Radio;

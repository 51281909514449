import { assignStudy, getStudies, randomPaper } from "apis/study";
import axios, { AxiosError } from "axios";
import { BtnPink, BtnWhite } from "components/atoms/button";
import { SpaceBetween, TemplateHeader } from "components/atoms/common";
import { Select } from "components/atoms/input";
import { Items, Item, Name, Value } from "components/atoms/item";
import { ModalContainer } from "components/atoms/modal";
import useInputs from "hooks/useInputs";
import moment from "moment";
import { FormEvent, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { eGFR, FirstResearchFlag, SRNSFlag } from "stores/studyFlag";
import StudyType from "types/study";
import ModalFirstReaserch from "./ModalFirstReaserch";
import ModalSRNS from "./ModalSRNS";

export type patientDataType = {
  id: number;
  name: string;
  hospitalName: string;
  sex: string;
  adminName: string;
  birthday: string;
};

const ModalAssginStudy = ({
  onClose,
  data,
  patientData,
}: {
  onClose: () => void;
  data?: StudyType;
  patientData: patientDataType;
}) => {
  const queryClient = useQueryClient();
  const [state, onChange] = useInputs({ ...data });
  const { studyId, studyTitle }: { studyId: number; studyTitle: string } =
    state;
  const { projectId = "" } = useParams();
  const { name, hospitalName, sex, adminName } = patientData;

  const today = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  let daydiff = moment(endDate).diff(moment(startDate), "days");

  // Inclusion and Exclusion flag
  const flagA = useRecoilValue(FirstResearchFlag);
  const flagB = useRecoilValue(SRNSFlag);
  //const [eGFRInput, setEGFRInput] = useRecoilState(eGFR);

  const [eGFRInput, setEGFRInput] = useState(0);

  const mutation = useMutation(
    (assignData: { studyId: number; patientId: number; studyValue: string }) =>
      assignStudy(assignData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["assignStudy"]);
        window.location.reload();
      },
      onError: (err: Error | AxiosError) => {
        if (axios.isAxiosError(err)) {
          const msg = err.response?.data as { message: string };
          alert(msg.message);
        } else {
          alert(err);
        }
      },
    }
  );

  const { data: studiesData, isLoading } = useQuery(
    ["getStudies", { projectId }],
    () => getStudies({ projectId })
  );

  // TODO: 추가되는 템플릿
  //const willAddTemps = ["srns-1", "srns-2", "srns-3"];
  // const { data: willAddTemplates, isLoading: isWillAddTemplatesLoading } =
  //   useQuery(["getWillAddTemplates", studyId], () =>
  //     // FIXME: studyId 변동에 따라가야함
  //     getWillAddTemplates(studyId)
  //   );

  const handleSubmit = (e: FormEvent) => {
    try {
      e.preventDefault();
      if (mutation.isLoading) return;

      // TODO: check the Flag
      if (!flagA && studyTitle === "초회연구") {
        alert("임상시험 Enroll 기준을 충족하지 않습니다.");
        return;
      } else if (!flagB && studyTitle === "SRNS") {
        alert("임상시험 Enroll 기준을 충족하지 않습니다.");
        return;
      }

      // Check. state is valid
      if (Object.keys(state).length !== 7) {
        alert(
          "임상 리스트 정보가 올바르지 않은 것 같습니다. [Error]: state information is invalid"
        );
        return;
      }

      if (
        !window.confirm(
          `환자명: ${patientData.name}\n연구 참여 기준을 모두 만족하였습니까?\n배정 이후에는 삭제가 불가능하며, 삭제 시 연구 전체에 영향을 줄 수 있습니다.\n배정을 진행하시겠습니까?`
        )
      ) {
        return;
      }

      // FIXME: hard coding
      let study_value = "";
      let finalStudyId = studiesData[0].id;
      if (studyTitle === "초회연구") {
        if (1 <= daydiff && daydiff <= 7) {
          // 조기
          finalStudyId = studiesData[0].id;
          study_value = randomPaper.ER[state[0].next_count - 1];
        } else if (8 <= daydiff && daydiff <= 14) {
          // 중기
          finalStudyId = studiesData[1].id;
          study_value = randomPaper.IR[state[1].next_count - 1];
        } else if (15 <= daydiff && daydiff <= 28) {
          // 후기
          finalStudyId = studiesData[2].id;
          study_value = randomPaper.LR[state[2].next_count - 1];
        } else {
          alert("치료기간 범위가 잘못 되었습니다.");
          return;
        }
      } else if (studyTitle === "SRNS") {
        // CASE 0. 총 SRNS 참여인원이 140명인 경우 배정 불가
        const total = state[3].next_count + state[4].next_count - 2;
        if (total >= 140) {
          alert("SRNS 참여 환자가 140명입니다. 추가 배정은 불가능합니다.");
          return;
        }
        // CASE 1. eGFR < 90 --> 신기능저하군 SRNS_Under
        // Do. Randomization -> CNI || CNI+MMF
        // CASE 2. eGFR >= 90 --> 정상군 SNRS_Normal
        // Do. Randomization -> CNI || CNI+MMF
        if (eGFR === null || eGFR === undefined) {
          alert("eGFR 값의 입력이 잘못 되었습니다.");
          return;
        } else if (eGFRInput < 90) {
          finalStudyId = studiesData[3].id;
          study_value = randomPaper.SRNS_Under[state[3].next_count - 1];
        } else if (eGFRInput >= 90 && eGFRInput <= 300) {
          finalStudyId = studiesData[4].id;
          study_value = randomPaper.SRNS_Normal[state[4].next_count - 1];
        } else {
          // err
          alert("eGFR 값 범위 오류");
          return;
        }
      }

      if (study_value === undefined) {
        alert("더 이상 배정할 수 없습니다!");
        return;
      }

      mutation.mutate({
        patientId: patientData.id,
        studyId: finalStudyId,
        studyValue: study_value,
      });

      if (studyTitle === "SRNS") {
        // TODO: Add Template?
      }

      // TODO: 임상 배정이 되었으면, 템플릿을 생성하여 넣어줘야 함
      // TODO: 임상1의 경우에는 기존에 존재하는 템플릿에 섹션만 추가해보기
      // 방문예정일이 같으므로 기존 템플릿에 섹션만 추가해보자.
      // 단, 이 경우 템플릿 이름 정도는 수정하여 임상1에 참가중임을 표시할 수 있으면 좋겠다.
      // 혹은 이름 변경이 아니라, 태그(컬럼)을 하나 추가해도 괜찮을듯

      // TODO: 임상2의 경우에는 방문예정일이 다르므로 템플릿을 추가해야함. 그러나, 어떤 시점의 추적 데이터와 같으므로
      // 해당 데이터와 같다라고 표기 혹은 연결하여 두번입력하지 않도록
    } catch (err) {
      alert(err);
    }
  };

  if (isLoading) return <></>;

  return (
    <ModalContainer>
      <form onSubmit={handleSubmit}>
        <TemplateHeader>환자 정보</TemplateHeader>
        <Items>
          <Item>
            <Name>이름</Name>
            <Value>{name}</Value>
            <Name>성별</Name>
            <Value>{sex}</Value>
          </Item>
          <Item>
            <Name>병원</Name>
            <Value>{hospitalName}</Value>
            <Name>담당 연구원</Name>
            <Value>{adminName}</Value>
          </Item>
        </Items>
        <SpaceBetween></SpaceBetween>
        <TemplateHeader>임상 배정</TemplateHeader>
        <Items>
          <Item>
            <Name>선택</Name>
            <Value>
              <Select value={studyTitle} name="studyTitle" onChange={onChange}>
                <option value={"초회연구"}>초회연구(임상1)</option>
                <option value={"SRNS"}>SRNS(임상2)</option>
              </Select>
            </Value>
          </Item>
        </Items>
        {studyTitle === "초회연구" ? (
          <ModalFirstReaserch
            dateData={{ startDate, endDate }}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        ) : (
          ""
        )}
        {studyTitle === "SRNS" ? (
          <ModalSRNS
            patientData={patientData}
            setEGFRInput={setEGFRInput}
            eGFRInput={eGFRInput}
          />
        ) : (
          ""
        )}
        <SpaceBetween>
          <BtnWhite onClick={onClose}>닫기</BtnWhite>
          <BtnPink type="submit">배정</BtnPink>
        </SpaceBetween>
      </form>
    </ModalContainer>
  );
};

export default ModalAssginStudy;

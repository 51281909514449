import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";

const TabParent = styled.div`
  padding: 0 10px;
  min-width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dfe0e0;
  border-bottom: 1px solid #000;
  background-color: #fafafc;
  cursor: pointer;
`;

const activeTabStyle = css`
  border: 1px solid #000000;
  border-bottom: none;
  background-color: #fff;
  color: #000;
`;

export const TabConatiner = styled.div`
  display: flex;
  height: 46px;
  width: 100%;
  overflow: auto;
`;

export const Tab = styled(TabParent)<{ isActive?: boolean }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #989899;
  cursor: ${({ isActive }) => !isActive && "pointer"};
  ${({ isActive }) => isActive && activeTabStyle};
`;

export const TabLine = styled.div`
  border-bottom: 1px solid #000000;
  flex: 1;
`;

export const TabLink = styled(NavLink)`
  display: flex;
  &.active {
    ${Tab} {
      ${activeTabStyle}
    }
  }
`;

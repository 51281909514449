import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router";

import styled from "styled-components";
import { SpaceBetween } from "components/atoms/common";
import { Table, TR, TH, TD } from "components/atoms/table";
import { deleteStudy, getStudies } from "apis/study";
import StudyType from "types/study";

const Study = () => {
  const queryClient = useQueryClient();
  const { projectId = "" } = useParams();

  const { data, isLoading } = useQuery(["getStudies", { projectId }], () =>
    getStudies({ projectId })
  );

  const mutation = useMutation((id: number) => deleteStudy(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getStudies"]);
    },
  });
  const handleDelete = (id: number) => {
    if (!window.confirm("정말 삭제하시겠습니까?")) return;
    mutation.mutate(id);
  };

  if (isLoading) return <>loading...</>;

  return (
    <>
      <Container>
        <SpaceBetween>
          <Title>총 {data ? data.length : "0"} 개</Title>
          {/* <Modal
            activator={(onOpen) => (
              <BtnBlue onClick={onOpen}>임상 추가+</BtnBlue>
            )}
            content={(onClose) => <ModalStudy onClose={onClose} />}
          /> */}
        </SpaceBetween>
        <Table>
          <thead>
            <TR>
              <TH>순번</TH>
              <TH>임상명</TH>
              <TH>분류</TH>
              <TH>next count</TH>
              <TH>관리</TH>
            </TR>
          </thead>
          <tbody>
            {data?.map((study: StudyType, i: number) => (
              <TR key={study.id}>
                <TD>{i + 1}</TD>
                <TD>{study.study_title}</TD>
                <TD>{study.study_name}</TD>
                <TD>{study.next_count}</TD>
                <TD>
                  {/* <Modal
                    activator={(onOpen) => (
                      <BtnCorrection>
                        <Correction onClick={onOpen} />
                      </BtnCorrection>
                    )}
                    content={(onClose) => (
                      <ModalStudy onClose={onClose} data={study} />
                    )}
                  />
                  <BtnCorrection>
                    <Trash onClick={() => handleDelete(study.id)} />
                  </BtnCorrection> */}
                </TD>
              </TR>
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  );
};

const Container = styled.div`
  margin-top: 27px;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  flex: 1;
`;

export default Study;

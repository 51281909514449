//계산식 추가
//f/u 시점 Azathioprine 체중당 용량 (= /mg/kg 자동계산)
const fu = {
  id: 7,
  name: "fu",
  datas: ["fu", "weight"],
  formula: ({ fu, weight }: any) => {
    if (!fu || !weight) {
      return;
    }
    return (fu / weight).toFixed(1);
  },
  help: "mg/kg",
};

export default fu;

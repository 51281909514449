import {
  getPrednisoloneDose,
  getDeflazacortDose,
  getMethylprednisoloneDose,
  getDexamethasoneDose,
} from "./common/getCumulativeDose";

const totalDoseSteroid2 = {
  id: 34,
  name: "Total Dose of Steroid ver.2",
  datas: [
    "daily_dose_1",
    "daily_dose_2",
    "daily_dose_3",
    "daily_dose_4",
    "daily_dose_5",
    "daily_dose_6",
    "daily_dose_7",
    "daily_dose_8",
    "daily_dose_9",
    "daily_dose_10",
    "daily_dose_11",
    "daily_dose_12",
    "daily_dose_13",
    "daily_dose_14",
    "daily_dose_15",
    "daily_dose_16",
    "daily_dose_17",
    "daily_dose_18",
    "daily_dose_19",
    "daily_dose_20",
    "daily_dose_21",
    "daily_dose_22",
    "daily_dose_23",
    "daily_dose_24",
    "daily_dose_25",
    "daily_dose_26",
    "daily_dose_27",
    "daily_dose_28",
    "daily_dose_29",
    "daily_dose_30",
    "daily_dose_31",
    "daily_dose_32",
    "daily_dose_33",
    "daily_dose_34",
    "daily_dose_35",
    "daily_dose_36",
    "daily_dose_37",
    "daily_dose_38",
    "daily_dose_39",
    "daily_dose_40",
    "daily_dose_41",
    "daily_dose_42",
    "daily_dose_43",
    "daily_dose_44",
    "daily_dose_45",
    "daily_dose_46",
    "daily_dose_47",
    "daily_dose_48",
    "daily_dose_49",
    "daily_dose_50",
    "daily_dose_51",
    "daily_dose_52",
    "daily_dose_53",
    "daily_dose_54",
    "daily_dose_55",
    "daily_dose_56",
    "daily_dose_57",
    "daily_dose_58",
    "daily_dose_59",
    "daily_dose_60",
    "daily_dose_61",
    "daily_dose_62",
    "daily_dose_63",
    "daily_dose_64",
    "daily_dose_65",
    "daily_dose_21_Prednisolone",
    "daily_dose_22_Prednisolone",
    "daily_dose_23_Prednisolone",
    "daily_dose_24_Prednisolone",
    "daily_dose_25_Prednisolone",
    "daily_dose_26_Prednisolone",
    "daily_dose_27_Prednisolone",
    "daily_dose_28_Prednisolone",
    "daily_dose_29_Prednisolone",
    "daily_dose_30_Prednisolone",
    "daily_dose_21_Deflazacort",
    "daily_dose_22_Deflazacort",
    "daily_dose_23_Deflazacort",
    "daily_dose_24_Deflazacort",
    "daily_dose_25_Deflazacort",
    "daily_dose_26_Deflazacort",
    "daily_dose_27_Deflazacort",
    "daily_dose_28_Deflazacort",
    "daily_dose_29_Deflazacort",
    "daily_dose_30_Deflazacort",
    "daily_dose_21_Dexamethasone",
    "daily_dose_22_Dexamethasone",
    "daily_dose_23_Dexamethasone",
    "daily_dose_24_Dexamethasone",
    "daily_dose_25_Dexamethasone",
    "daily_dose_26_Dexamethasone",
    "daily_dose_27_Dexamethasone",
    "daily_dose_28_Dexamethasone",
    "daily_dose_29_Dexamethasone",
    "daily_dose_30_Dexamethasone",
    "daily_dose_6_Methylprednisolone",
    "daily_dose_7_Methylprednisolone",
    "daily_dose_8_Methylprednisolone",
    "daily_dose_9_Methylprednisolone",
    "daily_dose_10_Methylprednisolone",
    "start_date_1",
    "end_date_1",
    "start_date_2",
    "end_date_2",
    "start_date_3",
    "end_date_3",
    "start_date_4",
    "end_date_4",
    "start_date_5",
    "end_date_5",
    "start_date_6",
    "end_date_6",
    "start_date_7",
    "end_date_7",
    "start_date_8",
    "end_date_8",
    "start_date_9",
    "end_date_9",
    "start_date_10",
    "end_date_10",
    "start_date_11",
    "end_date_11",
    "start_date_12",
    "end_date_12",
    "start_date_13",
    "end_date_13",
    "start_date_14",
    "end_date_14",
    "start_date_15",
    "end_date_15",
    "start_date_16",
    "end_date_16",
    "start_date_17",
    "end_date_17",
    "start_date_18",
    "end_date_18",
    "start_date_19",
    "end_date_19",
    "start_date_20",
    "end_date_20",
    "start_date_21",
    "end_date_21",
    "start_date_22",
    "end_date_22",
    "start_date_23",
    "end_date_23",
    "start_date_24",
    "end_date_24",
    "start_date_25",
    "end_date_25",
    "start_date_26",
    "end_date_26",
    "start_date_27",
    "end_date_27",
    "start_date_28",
    "end_date_28",
    "start_date_29",
    "end_date_29",
    "start_date_30",
    "end_date_30",
    "start_date_31",
    "end_date_31",
    "start_date_32",
    "end_date_32",
    "start_date_33",
    "end_date_33",
    "start_date_34",
    "end_date_34",
    "start_date_35",
    "end_date_35",
    "start_date_36",
    "end_date_36",
    "start_date_37",
    "end_date_37",
    "start_date_38",
    "end_date_38",
    "start_date_39",
    "end_date_39",
    "start_date_40",
    "end_date_40",
    "start_date_41",
    "end_date_41",
    "start_date_42",
    "end_date_42",
    "start_date_43",
    "end_date_43",
    "start_date_44",
    "end_date_44",
    "start_date_45",
    "end_date_45",
    "start_date_46",
    "end_date_46",
    "start_date_47",
    "end_date_47",
    "start_date_48",
    "end_date_48",
    "start_date_49",
    "end_date_49",
    "start_date_50",
    "end_date_50",
    "start_date_51",
    "end_date_51",
    "start_date_52",
    "end_date_52",
    "start_date_53",
    "end_date_53",
    "start_date_54",
    "end_date_54",
    "start_date_55",
    "end_date_55",
    "start_date_56",
    "end_date_56",
    "start_date_57",
    "end_date_57",
    "start_date_58",
    "end_date_58",
    "start_date_59",
    "end_date_59",
    "start_date_60",
    "end_date_60",
    "start_date_61",
    "end_date_61",
    "start_date_62",
    "end_date_62",
    "start_date_63",
    "end_date_63",
    "start_date_64",
    "end_date_64",
    "start_date_65",
    "end_date_65",
    "start_date_21_Prednisolone",
    "end_date_21_Prednisolone",
    "start_date_22_Prednisolone",
    "end_date_22_Prednisolone",
    "start_date_23_Prednisolone",
    "end_date_23_Prednisolone",
    "start_date_24_Prednisolone",
    "end_date_24_Prednisolone",
    "start_date_25_Prednisolone",
    "end_date_25_Prednisolone",
    "start_date_26_Prednisolone",
    "end_date_26_Prednisolone",
    "start_date_27_Prednisolone",
    "end_date_27_Prednisolone",
    "start_date_28_Prednisolone",
    "end_date_28_Prednisolone",
    "start_date_29_Prednisolone",
    "end_date_29_Prednisolone",
    "start_date_30_Prednisolone",
    "end_date_30_Prednisolone",
    "start_date_21_Deflazacort",
    "end_date_21_Deflazacort",
    "start_date_22_Deflazacort",
    "end_date_22_Deflazacort",
    "start_date_23_Deflazacort",
    "end_date_23_Deflazacort",
    "start_date_24_Deflazacort",
    "end_date_24_Deflazacort",
    "start_date_25_Deflazacort",
    "end_date_25_Deflazacort",
    "start_date_26_Deflazacort",
    "end_date_26_Deflazacort",
    "start_date_27_Deflazacort",
    "end_date_27_Deflazacort",
    "start_date_28_Deflazacort",
    "end_date_28_Deflazacort",
    "start_date_29_Deflazacort",
    "end_date_29_Deflazacort",
    "start_date_30_Deflazacort",
    "end_date_30_Deflazacort",
    "start_date_21_Dexamethasone",
    "end_date_21_Dexamethasone",
    "start_date_22_Dexamethasone",
    "end_date_22_Dexamethasone",
    "start_date_23_Dexamethasone",
    "end_date_23_Dexamethasone",
    "start_date_24_Dexamethasone",
    "end_date_24_Dexamethasone",
    "start_date_25_Dexamethasone",
    "end_date_25_Dexamethasone",
    "start_date_26_Dexamethasone",
    "end_date_26_Dexamethasone",
    "start_date_27_Dexamethasone",
    "end_date_27_Dexamethasone",
    "start_date_28_Dexamethasone",
    "end_date_28_Dexamethasone",
    "start_date_29_Dexamethasone",
    "end_date_29_Dexamethasone",
    "start_date_30_Dexamethasone",
    "end_date_30_Dexamethasone",
    "start_date_6_Methylprednisolone",
    "end_date_6_Methylprednisolone",
    "start_date_7_Methylprednisolone",
    "end_date_7_Methylprednisolone",
    "start_date_8_Methylprednisolone",
    "end_date_8_Methylprednisolone",
    "start_date_9_Methylprednisolone",
    "end_date_9_Methylprednisolone",
    "start_date_10_Methylprednisolone",
    "end_date_10_Methylprednisolone",
    "usage_1_Prednisolone",
    "usage_2_Prednisolone",
    "usage_3_Prednisolone",
    "usage_4_Prednisolone",
    "usage_5_Prednisolone",
    "usage_6_Prednisolone",
    "usage_7_Prednisolone",
    "usage_8_Prednisolone",
    "usage_9_Prednisolone",
    "usage_10_Prednisolone",
    "usage_11_Prednisolone",
    "usage_12_Prednisolone",
    "usage_13_Prednisolone",
    "usage_14_Prednisolone",
    "usage_15_Prednisolone",
    "usage_16_Prednisolone",
    "usage_17_Prednisolone",
    "usage_18_Prednisolone",
    "usage_19_Prednisolone",
    "usage_20_Prednisolone",
    "usage_21_Prednisolone",
    "usage_22_Prednisolone",
    "usage_23_Prednisolone",
    "usage_24_Prednisolone",
    "usage_25_Prednisolone",
    "usage_26_Prednisolone",
    "usage_27_Prednisolone",
    "usage_28_Prednisolone",
    "usage_29_Prednisolone",
    "usage_30_Prednisolone",
    "usage_1_Deflazacort",
    "usage_2_Deflazacort",
    "usage_3_Deflazacort",
    "usage_4_Deflazacort",
    "usage_5_Deflazacort",
    "usage_6_Deflazacort",
    "usage_7_Deflazacort",
    "usage_8_Deflazacort",
    "usage_9_Deflazacort",
    "usage_10_Deflazacort",
    "usage_11_Deflazacort",
    "usage_12_Deflazacort",
    "usage_13_Deflazacort",
    "usage_14_Deflazacort",
    "usage_15_Deflazacort",
    "usage_16_Deflazacort",
    "usage_17_Deflazacort",
    "usage_18_Deflazacort",
    "usage_19_Deflazacort",
    "usage_20_Deflazacort",
    "usage_21_Deflazacort",
    "usage_22_Deflazacort",
    "usage_23_Deflazacort",
    "usage_24_Deflazacort",
    "usage_25_Deflazacort",
    "usage_26_Deflazacort",
    "usage_27_Deflazacort",
    "usage_28_Deflazacort",
    "usage_29_Deflazacort",
    "usage_30_Deflazacort",
    "usage_1_Dexamethasone",
    "usage_2_Dexamethasone",
    "usage_3_Dexamethasone",
    "usage_4_Dexamethasone",
    "usage_5_Dexamethasone",
    "usage_6_Dexamethasone",
    "usage_7_Dexamethasone",
    "usage_8_Dexamethasone",
    "usage_9_Dexamethasone",
    "usage_10_Dexamethasone",
    "usage_11_Dexamethasone",
    "usage_12_Dexamethasone",
    "usage_13_Dexamethasone",
    "usage_14_Dexamethasone",
    "usage_15_Dexamethasone",
    "usage_16_Dexamethasone",
    "usage_17_Dexamethasone",
    "usage_18_Dexamethasone",
    "usage_19_Dexamethasone",
    "usage_20_Dexamethasone",
    "usage_21_Dexamethasone",
    "usage_22_Dexamethasone",
    "usage_23_Dexamethasone",
    "usage_24_Dexamethasone",
    "usage_25_Dexamethasone",
    "usage_26_Dexamethasone",
    "usage_27_Dexamethasone",
    "usage_28_Dexamethasone",
    "usage_29_Dexamethasone",
    "usage_30_Dexamethasone",
    "usage_1_Methylprednisolone",
    "usage_2_Methylprednisolone",
    "usage_3_Methylprednisolone",
    "usage_4_Methylprednisolone",
    "usage_5_Methylprednisolone",
    "usage_6_Methylprednisolone",
    "usage_7_Methylprednisolone",
    "usage_8_Methylprednisolone",
    "usage_9_Methylprednisolone",
    "usage_10_Methylprednisolone",
  ],
  formula: ({
    daily_dose_1,
    daily_dose_2,
    daily_dose_3,
    daily_dose_4,
    daily_dose_5,
    daily_dose_6,
    daily_dose_7,
    daily_dose_8,
    daily_dose_9,
    daily_dose_10,
    daily_dose_11,
    daily_dose_12,
    daily_dose_13,
    daily_dose_14,
    daily_dose_15,
    daily_dose_16,
    daily_dose_17,
    daily_dose_18,
    daily_dose_19,
    daily_dose_20,
    daily_dose_21,
    daily_dose_22,
    daily_dose_23,
    daily_dose_24,
    daily_dose_25,
    daily_dose_26,
    daily_dose_27,
    daily_dose_28,
    daily_dose_29,
    daily_dose_30,
    daily_dose_31,
    daily_dose_32,
    daily_dose_33,
    daily_dose_34,
    daily_dose_35,
    daily_dose_36,
    daily_dose_37,
    daily_dose_38,
    daily_dose_39,
    daily_dose_40,
    daily_dose_41,
    daily_dose_42,
    daily_dose_43,
    daily_dose_44,
    daily_dose_45,
    daily_dose_46,
    daily_dose_47,
    daily_dose_48,
    daily_dose_49,
    daily_dose_50,
    daily_dose_51,
    daily_dose_52,
    daily_dose_53,
    daily_dose_54,
    daily_dose_55,
    daily_dose_56,
    daily_dose_57,
    daily_dose_58,
    daily_dose_59,
    daily_dose_60,
    daily_dose_61,
    daily_dose_62,
    daily_dose_63,
    daily_dose_64,
    daily_dose_65,
    daily_dose_21_Prednisolone,
    daily_dose_22_Prednisolone,
    daily_dose_23_Prednisolone,
    daily_dose_24_Prednisolone,
    daily_dose_25_Prednisolone,
    daily_dose_26_Prednisolone,
    daily_dose_27_Prednisolone,
    daily_dose_28_Prednisolone,
    daily_dose_29_Prednisolone,
    daily_dose_30_Prednisolone,
    daily_dose_21_Deflazacort,
    daily_dose_22_Deflazacort,
    daily_dose_23_Deflazacort,
    daily_dose_24_Deflazacort,
    daily_dose_25_Deflazacort,
    daily_dose_26_Deflazacort,
    daily_dose_27_Deflazacort,
    daily_dose_28_Deflazacort,
    daily_dose_29_Deflazacort,
    daily_dose_30_Deflazacort,
    daily_dose_21_Dexamethasone,
    daily_dose_22_Dexamethasone,
    daily_dose_23_Dexamethasone,
    daily_dose_24_Dexamethasone,
    daily_dose_25_Dexamethasone,
    daily_dose_26_Dexamethasone,
    daily_dose_27_Dexamethasone,
    daily_dose_28_Dexamethasone,
    daily_dose_29_Dexamethasone,
    daily_dose_30_Dexamethasone,
    daily_dose_6_Methylprednisolone,
    daily_dose_7_Methylprednisolone,
    daily_dose_8_Methylprednisolone,
    daily_dose_9_Methylprednisolone,
    daily_dose_10_Methylprednisolone,
    start_date_1,
    end_date_1,
    start_date_2,
    end_date_2,
    start_date_3,
    end_date_3,
    start_date_4,
    end_date_4,
    start_date_5,
    end_date_5,
    start_date_6,
    end_date_6,
    start_date_7,
    end_date_7,
    start_date_8,
    end_date_8,
    start_date_9,
    end_date_9,
    start_date_10,
    end_date_10,
    start_date_11,
    end_date_11,
    start_date_12,
    end_date_12,
    start_date_13,
    end_date_13,
    start_date_14,
    end_date_14,
    start_date_15,
    end_date_15,
    start_date_16,
    end_date_16,
    start_date_17,
    end_date_17,
    start_date_18,
    end_date_18,
    start_date_19,
    end_date_19,
    start_date_20,
    end_date_20,
    start_date_21,
    end_date_21,
    start_date_22,
    end_date_22,
    start_date_23,
    end_date_23,
    start_date_24,
    end_date_24,
    start_date_25,
    end_date_25,
    start_date_26,
    end_date_26,
    start_date_27,
    end_date_27,
    start_date_28,
    end_date_28,
    start_date_29,
    end_date_29,
    start_date_30,
    end_date_30,
    start_date_31,
    end_date_31,
    start_date_32,
    end_date_32,
    start_date_33,
    end_date_33,
    start_date_34,
    end_date_34,
    start_date_35,
    end_date_35,
    start_date_36,
    end_date_36,
    start_date_37,
    end_date_37,
    start_date_38,
    end_date_38,
    start_date_39,
    end_date_39,
    start_date_40,
    end_date_40,
    start_date_41,
    end_date_41,
    start_date_42,
    end_date_42,
    start_date_43,
    end_date_43,
    start_date_44,
    end_date_44,
    start_date_45,
    end_date_45,
    start_date_46,
    end_date_46,
    start_date_47,
    end_date_47,
    start_date_48,
    end_date_48,
    start_date_49,
    end_date_49,
    start_date_50,
    end_date_50,
    start_date_51,
    end_date_51,
    start_date_52,
    end_date_52,
    start_date_53,
    end_date_53,
    start_date_54,
    end_date_54,
    start_date_55,
    end_date_55,
    start_date_56,
    end_date_56,
    start_date_57,
    end_date_57,
    start_date_58,
    end_date_58,
    start_date_59,
    end_date_59,
    start_date_60,
    end_date_60,
    start_date_61,
    end_date_61,
    start_date_62,
    end_date_62,
    start_date_63,
    end_date_63,
    start_date_64,
    end_date_64,
    start_date_65,
    end_date_65,
    start_date_21_Prednisolone,
    end_date_21_Prednisolone,
    start_date_22_Prednisolone,
    end_date_22_Prednisolone,
    start_date_23_Prednisolone,
    end_date_23_Prednisolone,
    start_date_24_Prednisolone,
    end_date_24_Prednisolone,
    start_date_25_Prednisolone,
    end_date_25_Prednisolone,
    start_date_26_Prednisolone,
    end_date_26_Prednisolone,
    start_date_27_Prednisolone,
    end_date_27_Prednisolone,
    start_date_28_Prednisolone,
    end_date_28_Prednisolone,
    start_date_29_Prednisolone,
    end_date_29_Prednisolone,
    start_date_30_Prednisolone,
    end_date_30_Prednisolone,
    start_date_21_Deflazacort,
    end_date_21_Deflazacort,
    start_date_22_Deflazacort,
    end_date_22_Deflazacort,
    start_date_23_Deflazacort,
    end_date_23_Deflazacort,
    start_date_24_Deflazacort,
    end_date_24_Deflazacort,
    start_date_25_Deflazacort,
    end_date_25_Deflazacort,
    start_date_26_Deflazacort,
    end_date_26_Deflazacort,
    start_date_27_Deflazacort,
    end_date_27_Deflazacort,
    start_date_28_Deflazacort,
    end_date_28_Deflazacort,
    start_date_29_Deflazacort,
    end_date_29_Deflazacort,
    start_date_30_Deflazacort,
    end_date_30_Deflazacort,
    start_date_21_Dexamethasone,
    end_date_21_Dexamethasone,
    start_date_22_Dexamethasone,
    end_date_22_Dexamethasone,
    start_date_23_Dexamethasone,
    end_date_23_Dexamethasone,
    start_date_24_Dexamethasone,
    end_date_24_Dexamethasone,
    start_date_25_Dexamethasone,
    end_date_25_Dexamethasone,
    start_date_26_Dexamethasone,
    end_date_26_Dexamethasone,
    start_date_27_Dexamethasone,
    end_date_27_Dexamethasone,
    start_date_28_Dexamethasone,
    end_date_28_Dexamethasone,
    start_date_29_Dexamethasone,
    end_date_29_Dexamethasone,
    start_date_30_Dexamethasone,
    end_date_30_Dexamethasone,
    start_date_6_Methylprednisolone,
    end_date_6_Methylprednisolone,
    start_date_7_Methylprednisolone,
    end_date_7_Methylprednisolone,
    start_date_8_Methylprednisolone,
    end_date_8_Methylprednisolone,
    start_date_9_Methylprednisolone,
    end_date_9_Methylprednisolone,
    start_date_10_Methylprednisolone,
    end_date_10_Methylprednisolone,
    usage_1_Prednisolone,
    usage_2_Prednisolone,
    usage_3_Prednisolone,
    usage_4_Prednisolone,
    usage_5_Prednisolone,
    usage_6_Prednisolone,
    usage_7_Prednisolone,
    usage_8_Prednisolone,
    usage_9_Prednisolone,
    usage_10_Prednisolone,
    usage_11_Prednisolone,
    usage_12_Prednisolone,
    usage_13_Prednisolone,
    usage_14_Prednisolone,
    usage_15_Prednisolone,
    usage_16_Prednisolone,
    usage_17_Prednisolone,
    usage_18_Prednisolone,
    usage_19_Prednisolone,
    usage_20_Prednisolone,
    usage_21_Prednisolone,
    usage_22_Prednisolone,
    usage_23_Prednisolone,
    usage_24_Prednisolone,
    usage_25_Prednisolone,
    usage_26_Prednisolone,
    usage_27_Prednisolone,
    usage_28_Prednisolone,
    usage_29_Prednisolone,
    usage_30_Prednisolone,
    usage_1_Deflazacort,
    usage_2_Deflazacort,
    usage_3_Deflazacort,
    usage_4_Deflazacort,
    usage_5_Deflazacort,
    usage_6_Deflazacort,
    usage_7_Deflazacort,
    usage_8_Deflazacort,
    usage_9_Deflazacort,
    usage_10_Deflazacort,
    usage_11_Deflazacort,
    usage_12_Deflazacort,
    usage_13_Deflazacort,
    usage_14_Deflazacort,
    usage_15_Deflazacort,
    usage_16_Deflazacort,
    usage_17_Deflazacort,
    usage_18_Deflazacort,
    usage_19_Deflazacort,
    usage_20_Deflazacort,
    usage_21_Deflazacort,
    usage_22_Deflazacort,
    usage_23_Deflazacort,
    usage_24_Deflazacort,
    usage_25_Deflazacort,
    usage_26_Deflazacort,
    usage_27_Deflazacort,
    usage_28_Deflazacort,
    usage_29_Deflazacort,
    usage_30_Deflazacort,
    usage_1_Dexamethasone,
    usage_2_Dexamethasone,
    usage_3_Dexamethasone,
    usage_4_Dexamethasone,
    usage_5_Dexamethasone,
    usage_6_Dexamethasone,
    usage_7_Dexamethasone,
    usage_8_Dexamethasone,
    usage_9_Dexamethasone,
    usage_10_Dexamethasone,
    usage_11_Dexamethasone,
    usage_12_Dexamethasone,
    usage_13_Dexamethasone,
    usage_14_Dexamethasone,
    usage_15_Dexamethasone,
    usage_16_Dexamethasone,
    usage_17_Dexamethasone,
    usage_18_Dexamethasone,
    usage_19_Dexamethasone,
    usage_20_Dexamethasone,
    usage_21_Dexamethasone,
    usage_22_Dexamethasone,
    usage_23_Dexamethasone,
    usage_24_Dexamethasone,
    usage_25_Dexamethasone,
    usage_26_Dexamethasone,
    usage_27_Dexamethasone,
    usage_28_Dexamethasone,
    usage_29_Dexamethasone,
    usage_30_Dexamethasone,
    usage_1_Methylprednisolone,
    usage_2_Methylprednisolone,
    usage_3_Methylprednisolone,
    usage_4_Methylprednisolone,
    usage_5_Methylprednisolone,
    usage_6_Methylprednisolone,
    usage_7_Methylprednisolone,
    usage_8_Methylprednisolone,
    usage_9_Methylprednisolone,
    usage_10_Methylprednisolone,
  }: any) => {
    if (!daily_dose_1 && !daily_dose_21 && !daily_dose_41 && !daily_dose_46) {
      return;
    }
    return (
      getPrednisoloneDose([
        [daily_dose_1, start_date_1, end_date_1, usage_1_Prednisolone],
        [daily_dose_2, start_date_2, end_date_2, usage_2_Prednisolone],
        [daily_dose_3, start_date_3, end_date_3, usage_3_Prednisolone],
        [daily_dose_4, start_date_4, end_date_4, usage_4_Prednisolone],
        [daily_dose_5, start_date_5, end_date_5, usage_5_Prednisolone],
        [daily_dose_6, start_date_6, end_date_6, usage_6_Prednisolone],
        [daily_dose_7, start_date_7, end_date_7, usage_7_Prednisolone],
        [daily_dose_8, start_date_8, end_date_8, usage_8_Prednisolone],
        [daily_dose_9, start_date_9, end_date_9, usage_9_Prednisolone],
        [daily_dose_10, start_date_10, end_date_10, usage_10_Prednisolone],
        [daily_dose_11, start_date_11, end_date_11, usage_11_Prednisolone],
        [daily_dose_12, start_date_12, end_date_12, usage_12_Prednisolone],
        [daily_dose_13, start_date_13, end_date_13, usage_13_Prednisolone],
        [daily_dose_14, start_date_14, end_date_14, usage_14_Prednisolone],
        [daily_dose_15, start_date_15, end_date_15, usage_15_Prednisolone],
        [daily_dose_16, start_date_16, end_date_16, usage_16_Prednisolone],
        [daily_dose_17, start_date_17, end_date_17, usage_17_Prednisolone],
        [daily_dose_18, start_date_18, end_date_18, usage_18_Prednisolone],
        [daily_dose_19, start_date_19, end_date_19, usage_19_Prednisolone],
        [daily_dose_20, start_date_20, end_date_20, usage_20_Prednisolone],
        [
          daily_dose_21_Prednisolone,
          start_date_21_Prednisolone,
          end_date_21_Prednisolone,
          usage_21_Prednisolone,
        ],
        [
          daily_dose_22_Prednisolone,
          start_date_22_Prednisolone,
          end_date_22_Prednisolone,
          usage_22_Prednisolone,
        ],
        [
          daily_dose_23_Prednisolone,
          start_date_23_Prednisolone,
          end_date_23_Prednisolone,
          usage_23_Prednisolone,
        ],
        [
          daily_dose_24_Prednisolone,
          start_date_24_Prednisolone,
          end_date_24_Prednisolone,
          usage_24_Prednisolone,
        ],
        [
          daily_dose_25_Prednisolone,
          start_date_25_Prednisolone,
          end_date_25_Prednisolone,
          usage_25_Prednisolone,
        ],
        [
          daily_dose_26_Prednisolone,
          start_date_26_Prednisolone,
          end_date_26_Prednisolone,
          usage_26_Prednisolone,
        ],
        [
          daily_dose_27_Prednisolone,
          start_date_27_Prednisolone,
          end_date_27_Prednisolone,
          usage_27_Prednisolone,
        ],
        [
          daily_dose_28_Prednisolone,
          start_date_28_Prednisolone,
          end_date_28_Prednisolone,
          usage_28_Prednisolone,
        ],
        [
          daily_dose_29_Prednisolone,
          start_date_29_Prednisolone,
          end_date_29_Prednisolone,
          usage_29_Prednisolone,
        ],
        [
          daily_dose_30_Prednisolone,
          start_date_30_Prednisolone,
          end_date_30_Prednisolone,
          usage_30_Prednisolone,
        ],
      ]) +
      getDeflazacortDose([
        [daily_dose_21, start_date_21, end_date_21, usage_1_Deflazacort],
        [daily_dose_22, start_date_22, end_date_22, usage_2_Deflazacort],
        [daily_dose_23, start_date_23, end_date_23, usage_3_Deflazacort],
        [daily_dose_24, start_date_24, end_date_24, usage_4_Deflazacort],
        [daily_dose_25, start_date_25, end_date_25, usage_5_Deflazacort],
        [daily_dose_26, start_date_26, end_date_26, usage_6_Deflazacort],
        [daily_dose_27, start_date_27, end_date_27, usage_7_Deflazacort],
        [daily_dose_28, start_date_28, end_date_28, usage_8_Deflazacort],
        [daily_dose_29, start_date_29, end_date_29, usage_9_Deflazacort],
        [daily_dose_30, start_date_30, end_date_30, usage_10_Deflazacort],
        [daily_dose_31, start_date_31, end_date_31, usage_11_Deflazacort],
        [daily_dose_32, start_date_32, end_date_32, usage_12_Deflazacort],
        [daily_dose_33, start_date_33, end_date_33, usage_13_Deflazacort],
        [daily_dose_34, start_date_34, end_date_34, usage_14_Deflazacort],
        [daily_dose_35, start_date_35, end_date_35, usage_15_Deflazacort],
        [daily_dose_36, start_date_36, end_date_36, usage_16_Deflazacort],
        [daily_dose_37, start_date_37, end_date_37, usage_17_Deflazacort],
        [daily_dose_38, start_date_38, end_date_38, usage_18_Deflazacort],
        [daily_dose_39, start_date_39, end_date_39, usage_19_Deflazacort],
        [daily_dose_40, start_date_40, end_date_40, usage_20_Deflazacort],
        [
          daily_dose_21_Deflazacort,
          start_date_21_Deflazacort,
          end_date_21_Deflazacort,
          usage_21_Deflazacort,
        ],
        [
          daily_dose_22_Deflazacort,
          start_date_22_Deflazacort,
          end_date_22_Deflazacort,
          usage_22_Deflazacort,
        ],
        [
          daily_dose_23_Deflazacort,
          start_date_23_Deflazacort,
          end_date_23_Deflazacort,
          usage_23_Deflazacort,
        ],
        [
          daily_dose_24_Deflazacort,
          start_date_24_Deflazacort,
          end_date_24_Deflazacort,
          usage_24_Deflazacort,
        ],
        [
          daily_dose_25_Deflazacort,
          start_date_25_Deflazacort,
          end_date_25_Deflazacort,
          usage_25_Deflazacort,
        ],
        [
          daily_dose_26_Deflazacort,
          start_date_26_Deflazacort,
          end_date_26_Deflazacort,
          usage_26_Deflazacort,
        ],
        [
          daily_dose_27_Deflazacort,
          start_date_27_Deflazacort,
          end_date_27_Deflazacort,
          usage_27_Deflazacort,
        ],
        [
          daily_dose_28_Deflazacort,
          start_date_28_Deflazacort,
          end_date_28_Deflazacort,
          usage_28_Deflazacort,
        ],
        [
          daily_dose_29_Deflazacort,
          start_date_29_Deflazacort,
          end_date_29_Deflazacort,
          usage_29_Deflazacort,
        ],
        [
          daily_dose_30_Deflazacort,
          start_date_30_Deflazacort,
          end_date_30_Deflazacort,
          usage_30_Deflazacort,
        ],
      ]) +
      getMethylprednisoloneDose([
        [daily_dose_41, start_date_41, end_date_41, usage_1_Methylprednisolone],
        [daily_dose_42, start_date_42, end_date_42, usage_2_Methylprednisolone],
        [daily_dose_43, start_date_43, end_date_43, usage_3_Methylprednisolone],
        [daily_dose_44, start_date_44, end_date_44, usage_4_Methylprednisolone],
        [daily_dose_45, start_date_45, end_date_45, usage_5_Methylprednisolone],
        [
          daily_dose_6_Methylprednisolone,
          start_date_6_Methylprednisolone,
          end_date_6_Methylprednisolone,
          usage_6_Methylprednisolone,
        ],
        [
          daily_dose_7_Methylprednisolone,
          start_date_7_Methylprednisolone,
          end_date_7_Methylprednisolone,
          usage_7_Methylprednisolone,
        ],
        [
          daily_dose_8_Methylprednisolone,
          start_date_8_Methylprednisolone,
          end_date_8_Methylprednisolone,
          usage_8_Methylprednisolone,
        ],
        [
          daily_dose_9_Methylprednisolone,
          start_date_9_Methylprednisolone,
          end_date_9_Methylprednisolone,
          usage_9_Methylprednisolone,
        ],
        [
          daily_dose_10_Methylprednisolone,
          start_date_10_Methylprednisolone,
          end_date_10_Methylprednisolone,
          usage_10_Methylprednisolone,
        ],
      ]) +
      getDexamethasoneDose([
        [daily_dose_46, start_date_46, end_date_46, usage_1_Dexamethasone],
        [daily_dose_47, start_date_47, end_date_47, usage_2_Dexamethasone],
        [daily_dose_48, start_date_48, end_date_48, usage_3_Dexamethasone],
        [daily_dose_49, start_date_49, end_date_49, usage_4_Dexamethasone],
        [daily_dose_50, start_date_50, end_date_50, usage_5_Dexamethasone],
        [daily_dose_51, start_date_51, end_date_51, usage_6_Dexamethasone],
        [daily_dose_52, start_date_52, end_date_52, usage_7_Dexamethasone],
        [daily_dose_53, start_date_53, end_date_53, usage_8_Dexamethasone],
        [daily_dose_54, start_date_54, end_date_54, usage_9_Dexamethasone],
        [daily_dose_55, start_date_55, end_date_55, usage_10_Dexamethasone],
        [daily_dose_56, start_date_56, end_date_56, usage_11_Dexamethasone],
        [daily_dose_57, start_date_57, end_date_57, usage_12_Dexamethasone],
        [daily_dose_58, start_date_58, end_date_58, usage_13_Dexamethasone],
        [daily_dose_59, start_date_59, end_date_59, usage_14_Dexamethasone],
        [daily_dose_60, start_date_60, end_date_60, usage_15_Dexamethasone],
        [daily_dose_61, start_date_61, end_date_61, usage_16_Dexamethasone],
        [daily_dose_62, start_date_62, end_date_62, usage_17_Dexamethasone],
        [daily_dose_63, start_date_63, end_date_63, usage_18_Dexamethasone],
        [daily_dose_64, start_date_64, end_date_64, usage_19_Dexamethasone],
        [daily_dose_65, start_date_65, end_date_65, usage_20_Dexamethasone],
        [
          daily_dose_21_Dexamethasone,
          start_date_21_Dexamethasone,
          end_date_21_Dexamethasone,
          usage_21_Dexamethasone,
        ],
        [
          daily_dose_22_Dexamethasone,
          start_date_22_Dexamethasone,
          end_date_22_Dexamethasone,
          usage_22_Dexamethasone,
        ],
        [
          daily_dose_23_Dexamethasone,
          start_date_23_Dexamethasone,
          end_date_23_Dexamethasone,
          usage_23_Dexamethasone,
        ],
        [
          daily_dose_24_Dexamethasone,
          start_date_24_Dexamethasone,
          end_date_24_Dexamethasone,
          usage_24_Dexamethasone,
        ],
        [
          daily_dose_25_Dexamethasone,
          start_date_25_Dexamethasone,
          end_date_25_Dexamethasone,
          usage_25_Dexamethasone,
        ],
        [
          daily_dose_26_Dexamethasone,
          start_date_26_Dexamethasone,
          end_date_26_Dexamethasone,
          usage_26_Dexamethasone,
        ],
        [
          daily_dose_27_Dexamethasone,
          start_date_27_Dexamethasone,
          end_date_27_Dexamethasone,
          usage_27_Dexamethasone,
        ],
        [
          daily_dose_28_Dexamethasone,
          start_date_28_Dexamethasone,
          end_date_28_Dexamethasone,
          usage_28_Dexamethasone,
        ],
        [
          daily_dose_29_Dexamethasone,
          start_date_29_Dexamethasone,
          end_date_29_Dexamethasone,
          usage_29_Dexamethasone,
        ],
        [
          daily_dose_30_Dexamethasone,
          start_date_30_Dexamethasone,
          end_date_30_Dexamethasone,
          usage_30_Dexamethasone,
        ],
      ])
    );
  },
  help: `Steroid 누적용량 = (Prednisolone 누적용량 * Prednisolone 누적 사용일) + (Deflazacort 누적용량 * Deflazacort 누적 사용일) + (Methylprednisolone 누적용량 * Methylprednisolone 누적 사용일) + (Dexamethasone 누적용량 * Dexamethasone 누적 사용일)`,
};

export default totalDoseSteroid2;

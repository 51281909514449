import { ChangeEvent, useEffect } from "react";
import styled from "styled-components";

import { OnChangeType, ItemType, ItemParentType } from "types";
import ItemHelp from "../ItemHelp";

import { useQuery } from "react-query";
import { getItems } from "apis/item";
import { useParams } from "react-router";

const Checkbox = ({
  data,
  onChange,
  value,
  readOnly,
}: {
  data: ItemType;
  onChange: OnChangeType;
  value: any;
  readOnly?: boolean;
}) => {
  const { is_required, options } = data;
  const id = data.id + "";
  const valueList = value ?? [];

  const { sectionId = "" } = useParams();
  const { projectId = "" } = useParams();

  const isRequired = (is_required: number, valueList: []) => {
    if (is_required === 1 && valueList.length === 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    onChange({ target: { name: id, value: valueList } });
  }, []);

  const itemId = data.id;

  const { data: items, isLoading: isLoadingItems } = useQuery(
    ["getItems", { sectionId, projectId }],
    () => getItems({ sectionId, projectId })
  );

  const sortedData = items?.sort(
    (a: ItemType, b: ItemType) => a.position - b.position
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    const newValues = checked
      ? [...valueList, +value]
      : valueList.filter((e: number) => e !== +value);
    onChange({ target: { name: id, value: newValues } });

    const subItems = sortedData.filter(
      ({ parent }: { parent: ItemParentType }) => parent.id === itemId
    );
    if (subItems.length > 0) {
      for (const subItem of subItems) {
        if (!newValues.includes(subItem.item_option_id)) {
          onChange({ target: { name: subItem.id.toString(), value: null } });
          if (subItem.options) {
            const subItems = sortedData.filter(
              ({ parent }: { parent: ItemParentType }) =>
                parent.id === subItem.id
            );
            if (subItems.length > 0) {
              for (const subItem of subItems) {
                onChange({
                  target: { name: subItem.id.toString(), value: null },
                });
                if (subItem.options) {
                  const subItems = sortedData.filter(
                    ({ parent }: { parent: ItemParentType }) =>
                      parent.id === subItem.id
                  );
                  if (subItems.length > 0) {
                    for (const subItem of subItems) {
                      onChange({
                        target: { name: subItem.id.toString(), value: null },
                      });
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <div>
      {[...options]
        ?.sort((a, b) => a.position - b.position)
        .map(({ id, value, help }) => (
          <Option key={id}>
            <label>
              <input
                type="checkbox"
                name={data.id + ""}
                onChange={handleChange}
                value={id}
                checked={valueList.includes(id)}
                readOnly={readOnly}
                required={isRequired(is_required, valueList)}
              />
              {value}
            </label>
            {help && <ItemHelp data={help} />}
          </Option>
        ))}
    </div>
  );
};
export const Option = styled.div`
  display: flex;
  align-items: center;
`;

export default Checkbox;

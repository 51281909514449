import instance from ".";
import { QueryType, QueryAnswerType } from "types";

export const getQueryCount = async (params?: { patientId: String; }) => {
  const result = await instance.get(`/querys/count`, { params });
  return result.data;
};

export const createQuerys = async (data: {
  projectId: String; 
  patient_id?: number;
  template_id?: number;
  section_id?: number;
 }) => {
  const result = await instance.post(`/querys/batch`, data);
  return result.data;
};

export const getQuerys = async (params: {
  projectId: String;
  page: number;
  display: number;
  isSuper: number;
  isProjectSuper: number;
  adminId: String;
  patientId: String;
  }) => {
  const result = await instance.get(`/querys`, { params });
  return result.data;
};

export const getQuery = async (params: { id: number; item_id: number }) => {
  const result = await instance.get(`/querys/${params.id}`, { params });
  return result.data;
};

export const manualQuery = async (params: { item_id: number; patient_id: number; }) => {
  const result = await instance.get(`/querys/manual`, { params });
  return result.data;
};

export const createQuery = async (data: QueryType) => {
  await instance.post(`/querys`, data);
};

export const editQuery = async (data: QueryType) => {
  await instance.put(`/querys/${data.id}`, data);
};

export const getQueryAnswers = async (params: {
  queryId: String;
  page: number;
  display: number;
  isSuper: number;
  isProjectSuper: number;
  }) => {
  const result = await instance.get(`/querys/answer`, { params });
  return result.data;
};

export const createQueryAnswer = async (data: QueryAnswerType) => {
  await instance.post(`/querys/answer`, data);
};

import PatientType from "types/patient";
import { TemplateType } from "types";
import getLMS from "./common/getLMS";
import getAge from "./common/getAge";

const heightLMS = {
  id: 8,
  name: "heightLMS",
  datas: ["height"],
  formula: (
    { height }: any,
    { sex, birthday }: PatientType,
    { reporting_date }: TemplateType
  ) => {
    if (!height || !sex || !birthday || !reporting_date) {
      return;
    }
    const age = getAge(birthday, reporting_date);
    const { L, M, S } = getLMS({ type: "height", sex, age });
    return ((Math.pow(height / M, L) - 1) / (L * S)).toFixed(3);
  },
  help: `키 z score (자동계산) = ((Power (키/M)^L))-1)/(L*S)`,
};

export default heightLMS;

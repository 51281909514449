import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { getTokenPayload } from 'utils/user';

import { getSampleRequestItems } from 'apis/sampleRequest';

import { TabConatiner, Tab, TabLine } from 'components/atoms/tab';

import SampleRequestList from './request/SampleRequestList';
import SampleStockList from './stock/SampleStockList';

const SampleList = () => {
  const { isSuper, isProjectSuper } = getTokenPayload();

  const [searchParam, setSearchParam] = useSearchParams();
  const defaultTab = searchParam.get('tab') ?? 'request';
  const [tab, setTab] = useState(defaultTab);

  const isAdmin = (isSuper || isProjectSuper) ? true : false;

  const { isLoading, data } = useQuery(
    ['getSampleRequestItems'],
    () => getSampleRequestItems()
  );

  const onClick = (tab: string) => {
    setSearchParam({ tab });
    setTab(tab);
  };

  useEffect(() => {
    setTab(defaultTab);
  }, [defaultTab]);

  if (isLoading) {
    return <></>;
  }

  return <>
    {isAdmin
      && <TabConatiner>
        <Tab onClick={() => onClick('request')} isActive={tab === 'request'}>요청</Tab>
        <Tab onClick={() => onClick('stock')} isActive={tab === 'stock'}>재고</Tab>
        <TabLine />
      </TabConatiner>
    }
    {tab === 'request'
      && <SampleRequestList />
    }
    {tab === 'stock'
      && <SampleStockList count={data.count} />
    }
  </>;
};

export default SampleList;

import { useMutation, useQuery, useQueryClient } from "react-query";

import { getTemplateSection, editTemplateSection } from "apis/template";

import ModalOneToMany from "components/organisms/ModalOneToMany";

const ModalTemplateSectionWrapper = ({
  id,
  onClose,
}: {
  id: number;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { isLoading, data } = useQuery(["getTemplateSection", { id }], () =>
    getTemplateSection(id)
  );
  const mutation = useMutation(
    (sections: number[]) => editTemplateSection(id, sections),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["getTemplateSection"]);
        onClose();
      },
    }
  );

  const handleSubmit = (sections: number[]) => {
    if (!window.confirm("수정하시겠습니까?")) return;
    mutation.mutate(sections);
  };

  if (isLoading) return <></>;
  return (
    <ModalOneToMany
      type="template"
      onSubmit={handleSubmit}
      data={data}
      onClose={onClose}
    />
  );
};

export default ModalTemplateSectionWrapper;

import styled from "styled-components";

import { ReactComponent as Search } from "assets/icons/Search.svg";
import { FormEvent, useEffect, useState } from "react";

const SearchBox = ({
  query,
  placeholder,
  onSubmit,
}: {
  query: string;
  placeholder: string;
  onSubmit: (e: string) => void;
}) => {
  const [text, setText] = useState(query);

  useEffect(() => {
    setText(query);
  }, [query]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(text);
  };
  return (
    <Container onSubmit={handleSubmit}>
      <SearchInput
        value={text}
        placeholder={placeholder}
        onChange={(e) => setText(e.target.value)}
      />
      <BtnSearch type="submit">
        <Search />
      </BtnSearch>
    </Container>
  );
};

const Container = styled.form`
  width: 325px;
  height: 45px;
  border: 1px solid #dfe0e0;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;
const SearchInput = styled.input`
  width: 290px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  border: none;
`;
const BtnSearch = styled.button`
  background-color: #fff;
  border: none;
  outline: none;
`;

export default SearchBox;

import { ChangeEvent, useRef, FormEvent } from "react";
import { useMutation } from "react-query";
import styled from "styled-components";

import { BtnBlue } from "components/atoms/button";
import { OnChangeType, ItemType } from "types";
import { remove, upload } from "apis/upload";

const File = ({
  data,
  onChange,
  value,
}: {
  data: ItemType;
  onChange: OnChangeType;
  value: any;
}) => {
  const { id, is_required } = data;
  const mutation = useMutation(async (file: File) => upload(file), {
    onSuccess: (url) => {
      //한글 encode/decode 
      onChange({ target: { name: id + "", value: url } });
    },
  });

  //console.log("value: ", value);

  const fileEl = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileEl.current?.click();
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (mutation.isLoading) return;
    if (e.target.files) {
      const file = e.target.files[0];
      mutation.mutate(file);
      e.target.value = '';
    }
  };

  const mutationDelete = useMutation(
    async (url: any) => remove(url, id),
    {
      onSuccess: () => {
        if (!window.confirm("정말 삭제하시겠습니까?")) return;
        alert("파일이 삭제 되었습니다.");
        onChange({ target: { name: id + "", value: "" } });
      },
      onError: (err) => { alert("삭제에 실패했습니다"); }
    });

  const handleDelete = async (e: FormEvent) => {
    e.preventDefault();
    if (mutationDelete.isLoading) return;
    if (value) mutationDelete.mutate(value);
    // console.log("handleDelete value: ", url);
  }

  const btnUploadText = () => {
    let msg = value ? "업로드 성공" : "업로드 하기";
    if (mutation.isLoading) msg = "업로드 중";
    // if (mutation.isLoading) return "업로드 중";
    // if (mutation.isSuccess) return "업로드 성공";
    // return "업로드 하기";
    return msg;
  };

  return (
    <>
      <BtnBlue outline onClick={handleClick} small>
        {btnUploadText()}
      </BtnBlue>
      {value && (
        <>
          <A href="#" onClick={handleDelete}>
            <Deletebutton> 삭제 </Deletebutton>
          </A>
        </>
      )}
      <InputHide
        type="text"
        value={value ?? ""}
        required={is_required === 1}
        onChange={() => { }}
      />
      <InputHide type="file" onChange={handleFileChange} ref={fileEl} />

    </>
  );
};

const InputHide = styled.input`
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  outline: 0;
  opacity: 0;
`;
const A = styled.a`
  margin-left: 10px;
`;

const Deletebutton = styled.button`
height: 33px;
width: 75px;
background-color: rgb(0, 67, 183);
color: #ffffff;
padding: 7px 10px;
font-size: 12px;
line-height: 17px;
font-style: Arial;
border: none;
`;

export default File;

import { getTokenPayload } from "utils/user";

const Dashboard = () => {
  const { isSuper } = getTokenPayload();
  if (isSuper === 0) {
    window.location.href = "/";
    alert("권한이 없습니다.");
  }
  return <>Dashboard</>;
};

export default Dashboard;

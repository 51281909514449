import { useQuery } from "react-query";
import { Link, Outlet, useParams } from "react-router-dom";
import styled from "styled-components";

import { getProject } from "apis/project";
import { getTokenPayload } from "utils/user";

import { PageContainer, PageLayout, PageHeader } from "components/atoms/page";
import Modal from "components/organisms/Modal";
import ModalProjectName from "components/organisms/ModalProjectName";
import BtnLeft from "components/molecules/BtnLeft";
import BtnPencil from "components/molecules/BtnPencil";
import LinkTabs from "components/molecules/LinkTabs";

const tabs = [
  { name: "연구원", to: "researcher" },
  { name: "환자 타입", to: "type" },
  { name: "환자 추가 질문", to: "patient" },
  { name: "섹션", to: "section" },
  { name: "소속 병원", to: "hospital" },
  { name: "기본 템플릿", to: "template" },
  { name: "임상", to: "casestudy" },
];

const AdminProject = () => {
  const { isSuper } = getTokenPayload();

  const { projectId = "" } = useParams();
  const { data, isLoading } = useQuery(["getProject", projectId], () =>
    getProject({ projectId })
  );

  if (isLoading) return <></>;
  return (
    <>
      <PageHeader>
        <Link to="/admin/project">
          <BtnLeft />
        </Link>
        <ProjectName>{data.name}</ProjectName>
        {isSuper === 1 && (
          <Modal
            activator={(onOpen) => <BtnPencil onClick={onOpen} />}
            content={(onClose) => (
              <ModalProjectName data={data} onClose={onClose} />
            )}
          />
        )}
      </PageHeader>
      <PageLayout>
        <PageContainer>
          <LinkTabs data={tabs} />
          <Outlet />
        </PageContainer>
      </PageLayout>
    </>
  );
};

const ProjectName = styled.span`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  margin-left: 20px;
  margin-right: 5px;
`;

export default AdminProject;

import { ChangeEvent, useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import styled from "styled-components";

import { Select } from "components/atoms/input";
import { Item, Name, Value } from "components/atoms/item";

import formulas from "datas/formulas";
import { SectionType, OnChangeType } from "types";
import { getProjectItems } from "apis/project";

const ItemEditFormula = ({
  formulaId,
  formula,
  onChange,
}: {
  formulaId: number;
  formula: any;
  onChange: OnChangeType;
}) => {
  const { projectId = "" } = useParams();
  const { data: sections, isLoading } = useQuery<SectionType[]>(
    ["getProjectItems", { projectId }],
    () => getProjectItems({ projectId })
  );

  const activeFormula = formulas.find(({ id }) => id === +formulaId);

  useEffect(() => {
    if (!isLoading) {
      const nextFormula: { [key: string]: number } = formula || {};
      activeFormula?.datas.forEach((data) => {
        if (!nextFormula[data] && sections) {
          const { items } = sections[0];
          if (items) {
            nextFormula[data] = items[0]?.id;
          }
        }
      });
      onChange({
        target: { name: "formula", value: nextFormula },
      });
    }
  }, [activeFormula, sections]);

  const handleChangeData = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    const nextFormula = { ...formula, [name]: +value };
    onChange({
      target: { name: "formula", value: nextFormula },
    });
  };
  if (isLoading) return <></>;

  return (
    <Item size={1}>
      <Name>계산식</Name>
      <Flex>
        <Value>
          <Select name="formula_id" onChange={onChange} value={formulaId}>
            {formulas.map(({ id, name }) => (
              <option key={name} value={id}>
                {name}
              </option>
            ))}
          </Select>
        </Value>
        <Item>
          <Name>설명</Name>
          <Value>
            <PreWrap>{activeFormula?.help}</PreWrap>
          </Value>
        </Item>
        {activeFormula?.datas?.map((data) => (
          <Item key={data}>
            <Name>{data}</Name>
            <Value>
              <Select
                name={data}
                onChange={handleChangeData}
                value={formula[data]}
              >
                {sections?.map(({ id, name, items }) => (
                  <optgroup label={name} key={id}>
                    {items
                      ?.filter(
                        ({ type }) =>
                          type === "unit" || type === "date" || type === "radio"
                      )
                      .map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </optgroup>
                ))}
              </Select>
            </Value>
          </Item>
        ))}
      </Flex>
    </Item>
  );
};

const Flex = styled.div`
  flex: 1;
`;
const PreWrap = styled.div`
  white-space: pre-wrap;
`;

export default ItemEditFormula;

import { TemplateType } from "types";
import PatientType from "types/patient";
import getLMS from "./common/getLMS";
import getAge from "./common/getAge";
import { NORMSDIST } from "@formulajs/formulajs";

const weightPercentile = {
  id: 20,
  name: "weightPercentile",
  datas: ["weight"],
  formula: (
    { weight }: any,
    { sex, birthday }: PatientType,
    { reporting_date }: TemplateType
  ) => {
    if (!weight || !sex || !birthday || !reporting_date) {
      return;
    }
    const age = getAge(birthday, reporting_date);
    const { L, M, S } = getLMS({ type: "weight", sex, age });
    return (
      NORMSDIST((Math.pow(weight / M, L) - 1) / (L * S), true) * 100
    ).toFixed(1);
  },
  help: `NORMSDIST((((체중/M)^L)-1)/(L*S))*100`,
};

export default weightPercentile;

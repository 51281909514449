import { useNavigate } from "react-router";
import styled from "styled-components";

import { Items } from "components/atoms/item";
import InputItem from "components/molecules/InputItem";
import ItemTitle from "./Title";

import { ItemType, ItemParentType } from "types";

const EditItem = ({
  data,
  list,
  index,
}: {
  data: ItemType;
  list: ItemType[];
  index: string;
}) => {
  const navigate = useNavigate();
  const { id } = data;
  const subItemCondition = (parent: ItemParentType) => parent.id === id;
  const subItems = list.filter(({ parent }) => subItemCondition(parent));

  const handleClick = () => {
    navigate(`item/${id}`);
  };
  return (
    <>
      <Container onClick={handleClick}>
        <ItemTitle data={data} index={index} />
        <Items>
          <InputItem data={data} list={list} size={1} readOnly />
        </Items>
      </Container>
      <SubItems>
        {subItems.map((item, i) => (
          <EditItem
            key={item.id}
            data={item}
            list={list}
            index={`${index}-${i + 1}`}
          />
        ))}
      </SubItems>
    </>
  );
};

const Container = styled.article`
  margin: 30px 0;
  cursor: pointer;
  border: 1px solid #e6e6e6;
  padding: 30px;
  padding-top: 10px;
  :hover {
    border: 1px solid #000;
  }
`;

const SubItems = styled.div`
  margin-left: 50px;
`;

export default EditItem;

import { useMutation, useQueryClient } from "react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { createItem } from "apis/item";

import { BtnPink } from "components/atoms/button";
import { SpaceBetween } from "components/atoms/common";
import { Item, Items, Name, Value } from "components/atoms/item";

import types from "datas/itemTypes";
import { ModalContainer } from "components/atoms/modal";
import { Input, Select } from "components/atoms/input";

const ModalAddItem = ({
  optionId,
  onClose,
}: {
  optionId?: number;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const { projectId = "", sectionId = "" } = useParams();
  const [name, setName] = useState("");
  const [type, setType] = useState("text");
  const [unit, setUnit] = useState("");
  const mutation = useMutation(createItem, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getItems"]);
      queryClient.invalidateQueries(["getItemOptions"]);
      onClose();
    },
  });

  const handleSubmit = async () => {
    if (mutation.isLoading) return;
    if (!name) return alert("이름을 입력하세요.");
    const defaultParams = { name, type, projectId, sectionId, unit };
    const params = optionId ? { ...defaultParams, optionId } : defaultParams;
    mutation.mutate(params);
  };

  return (
    <ModalContainer>
      <h2>{optionId && "하위 "}항목 추가</h2>
      <Items>
        <Item>
          <Name>항목 이름</Name>
          <Value>
            <Input
              placeholder="항목 이름"
              value={name}
              onChange={(e) => setName(e.target.value)}
              autoFocus
            />
          </Value>
        </Item>
        <Item>
          <Name>타입</Name>
          <Value>
            <Select
              name="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              {types.map(({ code, name }) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </Select>
          </Value>
        </Item>
        {type === "unit" && (
          <Item>
            <Name>단위</Name>
            <Value>
              <Input
                name="unit"
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
              />
            </Value>
          </Item>
        )}
      </Items>
      <SpaceBetween>
        <div></div>
        <BtnPink onClick={handleSubmit} large>
          확인
        </BtnPink>
      </SpaceBetween>
    </ModalContainer>
  );
};

export default ModalAddItem;

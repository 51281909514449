import PatientType from "types/patient";
import { geteGFRU25_Cr } from "./common/geteGFRU25";

const eGFRU25_Cr = {
  id: 15,
  name: "eGFR (U25-Cr)",
  datas: ["height", "blood_creatinine"],
  formula: (
    { height, blood_creatinine }: any,
    { birthday, sex }: PatientType
  ) => {
    if (!height || !blood_creatinine || !birthday || !sex) {
      return;
    }

    const age = (Date.now() - +new Date(birthday)) / 31557600000;

    return geteGFRU25_Cr({ height, age, blood_creatinine }, sex);
  },
  help: `K1 x height ÷ blood_creatinine
  
  *남자 K1
  12세 미만: 39.0 x 1.008^(age-12)
  12세 이상 ~ 18세 미만: 39.0 x 1.045^(age-12)
  18세 이상: 50.8
  -----------------------------------
  *여자 K1
  12세 미만: 36.1 x 1.008^(age-12)
  12세 이상 ~ 18세 미만: 36.1 x 1.023^(age-12)
  18세 이상: 41.4

  *height(m)
  `,
};

export default eGFRU25_Cr;

import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  Location,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import styled from "styled-components";
import { useSetRecoilState } from "recoil";

import { getPatient } from "apis/patient";
import patientState from "stores/patientState";

import { PageContainer, PageHeader } from "components/atoms/page";
import LinkTabs from "components/molecules/LinkTabs";
import Info from "components/templates/Patient/Info";

import { ReactComponent as ArrowLeft } from "assets/icons/BackBtn.svg";

const tabs = [
  { name: "환자정보", to: "", end: true },
  { name: "템플릿", to: "template" },
];

type LocationProps = {
  state: {
    page: Location;
    search?: Location;
    sort?: Location;
    searchCondition?: Location;
  };
};

// TODO: go-back to list btn
// 1. 이전의 페이지 위치를 기억해야 함
const Patient = () => {
  const location = useLocation() as LocationProps;
  const { projectId = "" } = useParams();
  const [prevPage, setPrevPAge] = useState(location.state?.page);
  const [prevName, setPrevName] = useState(location.state?.search ?? "");
  const [prevSort, setPrevSort] = useState(location.state?.sort);
  const [prevSearchCondition, setPrevSearchCondition] = useState(
    location.state?.searchCondition
  );

  const searchCondition = prevSearchCondition
    ? JSON.parse(JSON.stringify(prevSearchCondition))
    : null;

  const setPatient = useSetRecoilState(patientState);
  const { patientId = "" } = useParams();
  const { isLoading, data } = useQuery(["getPatient", patientId], () =>
    getPatient(patientId)
  );
  const navi = useNavigate();
  useEffect(() => {
    if (!isLoading) {
      setPatient(data);
    }
  }, [data]);
  if (isLoading) return <></>;

  const { projectName } = data;
  return (
    <>
      <PageHeader>
        <ArrowLeft
          onClick={() => {
            //navi(-1);
            navi(
              `/project/${projectId}?page=${prevPage}&search=${prevName}&sort=${prevSort}&hospital=${searchCondition?.hospital}&researcher=${searchCondition?.researcher}&confirmation=${searchCondition?.confirmation}`
            );
          }}
        />
        <ProjectName>{projectName}</ProjectName>
      </PageHeader>
      <Info data={data} />
      <Main>
        <PageContainer>
          <LinkTabs data={tabs} />
          <Outlet />
        </PageContainer>
      </Main>
    </>
  );
};

const Main = styled.main`
  padding: 30px 90px;
  @media (max-width: 1315px) {
    padding: 30px;
  } ;
`;
const ProjectName = styled.span`
  font-weight: 500;
  font-size: 24px;
`;

export default Patient;

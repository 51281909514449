import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useParams, useSearchParams, useLocation } from "react-router-dom";
import styled from "styled-components";

import { deletePatient, getPatients } from "apis/patient";
import { ReactComponent as Trash } from "assets/icons/Trash.svg";
import { ReactComponent as Addstudy } from "assets/icons/user-add.svg";
import PatientType from "types/patient";

import { Table, TR, TH, TD } from "components/atoms/table";
import SearchBox from "components/molecules/SearchBox";
import { SpaceBetween } from "components/atoms/common";
import Pagination from "components/organisms/Pagination";
import { getLocaleDate } from "utils/time";
import { getStudies, initStudy } from "apis/study";
import Modal from "components/organisms/Modal";
import ModalAssginStudy from "./Modal";

import { getTokenPayload } from "utils/user";
import { getMyInfo } from "apis/user";

import { getHospitals } from "apis/hospital";
import HospitalType from "types/hospital";

import { getResearchers } from "apis/researcher";
import ResearcherType from "types/researcher";

import { Red } from "components/atoms/query";

const THs = [
  "순번",
  "이름",
  "고유번호",
  "병원",
  "담당 연구원",
  "타입",
  "최근 템플릿",
  "성별",
  "생년월일",
  "관리",
];

// 서울대 신증후군 경우 다른 헤드 사용
const THsWithStudy = [
  "순번",
  "이름",
  "고유번호",
  "병원",
  "담당 연구원",
  "타입",
  "최근 템플릿",
  "성별",
  "생년월일",
  "임상",
  "동의철회여부",
  "동의철회일자",
  "쿼리",  // 오토쿼리 관련 추가 
  "관리",
];

// 소아 희귀난치 뇌전증 후향적 코호트
const THs_7 = [
  "순번",
  "이름",
  "고유번호",
  "병원",
  "담당 연구원",
  "타입",
  "최근 템플릿",
  "성별",
  "생년월일",
  "서명",
  "관리",
];

const display = 15;

export type TabType = {
  typeId: number;
  studyId?: number;
};

// TODO: 환자 정보 페이지에서 돌아올때 원래의 탭정보를 기억..

const Patients = ({ tab }: { tab: TabType }) => {
  let location = useLocation();
  const locationObj = JSON.parse(JSON.stringify(location));
  const [searchParam, setSearchParam] = useSearchParams();
  const search = searchParam.get("search");
  // page
  const cur_page = parseInt(searchParam.get("page") ?? "1");
  const qsSort = searchParam.get("sort");
  const qsSearchCondition = searchParam.get("searchCondition");
  const qsHospital = searchParam.get("hospital");
  const qsResearcher = searchParam.get("researcher");
  const qsConfirmation = searchParam.get("confirmation");

  const queryClient = useQueryClient();
  const { projectId = "" } = useParams();
  const { isSuper, isProjectSuper } = getTokenPayload();
  const isResearcher = !isSuper && !isProjectSuper;
  const [page, setPage] = useState(cur_page ?? 1);
  const [name, setName] = useState("");
  const [sort, setSort] = useState(qsSort ?? "");
  const [searchCondition, setSearchCondition] = useState({
    hospital: qsHospital ?? "",
    researcher: qsResearcher ?? "",
    confirmation: qsConfirmation ?? "",
  });
  const params: any = { projectId, page, display, sort, searchCondition };

  if (name) params.name = name;
  if (search) params.name = search;
  if (tab.typeId) params.typeId = tab.typeId;
  if (tab.studyId) params.studyId = tab.studyId;

  const { data, isLoading } = useQuery(
    ["getPatients", params],
    () => getPatients(params),
    { keepPreviousData: true }
  );

  const mutation = useMutation((id: number) => deletePatient(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getPatients"]);
    },
  });

  const { data: myInfo, isLoading: isLoadingMyInfo } = useQuery(
    "getMyInfo",
    getMyInfo
  );

  const { data: hospitals, isLoading: isLoadingHospitals } = useQuery(
    ["getHospitals", { projectId }],
    () => getHospitals({ projectId })
  );

  const { data: researchers, isLoading: isLoadingResearchers } = useQuery(
    ["getResearchers", { projectId }],
    () => getResearchers({ projectId })
  );

  // const { isLoading: isLoadingConfirmation, data: Confirmation } = useQuery(
  //   ["getSection", { templateId, sectionId, patientId }],
  //   () => getSection({ templateId, sectionId, patientId })
  // );

  /** 연구원 계정으로 접속 시, 본인 소속 병원 연구원만 보이도록 필터 */
  const myHospitalResearchers =
    isResearcher &&
    researchers?.filter(
      (researcher: ResearcherType) =>
        researcher.hospital_id === myInfo.hospital_id
    );
  // study 관련
  const { data: studies, isLoading: isStudiesLoading } = useQuery(
    ["getStudies", projectId],
    () => getStudies({ projectId })
  );
  const initMutation = useMutation((studyId: number) => initStudy(studyId));

  useEffect(() => {
    setPage(cur_page);
  }, [cur_page]);

  useEffect(() => {
    if (!locationObj.search) {
      setPage(1);
      setName("");
      setSearchCondition({
        hospital: "",
        researcher: "",
        confirmation: "",
      });
    }
  }, [locationObj.search]);

  const handleDelete = async (id: number, name: string, code: string) => {
    if (!isProjectSuper && !isSuper) {
      alert("프로젝트 관리자만 삭제할 수 있습니다.");
      return;
    }
    if (window.confirm(`정말 ${name}(${code})의 데이터를 삭제하시겠습니까?`)) {
      mutation.mutate(id);
    }
  };

  const handleSearch = (query: string) => {
    // setName(query);
    //setPage(1);
    setSearchParam({ search: query, page: "1" });
  };

  const handleInitStudy = async () => {
    // FIXME: for await -> backend 에서 처리하도록
    const studyIds = [1, 2, 3, 4, 5, 11, 12, 13, 14, 16, 22];
    for await (const sid of studyIds) {
      initMutation.mutate(sid);
    }

    window.location.reload();
  };

  const selectTHs = (projectId: string, studies?: any) => {
    if (studies) {
      return THsWithStudy.map((th, i: number) => <TH key={i}>{th}</TH>);
    } else if (projectId === "7") {
      return THs_7.map((th, i: number) => <TH key={i}>{th}</TH>);
    } else {
      return THs.map((th, i: number) => <TH key={i}>{th}</TH>);
    }
  };

  const checkConfirmation = (projectId: string, confirmation?: string) => {
    if (projectId === "7") {
      if (confirmation) {
        return <TD>완료</TD>;
      }
      return <TD>작업중</TD>;
    }
  };

  if (isLoading || isLoadingHospitals || isLoadingResearchers) return <></>;

  const { total, items } = data;

  // 674는 신증후군, 4444는 임상연구 테스트용
  return (
    <Container>
      <SpaceBetween>
        <Title>총 {total}명</Title>
        {/* <button onClick={() => handleInitStudy()}>임상 배정 초기화</button> */}
        {projectId === "7" && (
          <OrderSelect
            name="confirmation"
            value={searchCondition.confirmation}
            onChange={(e) => {
              setSearchCondition({
                ...searchCondition,
                [e.target.name]: e.target.value,
              });
              setSearchParam({ ...searchParam, page: "1" });
            }}
          >
            <option value="">환자 전체</option>
            <option key={1} value="1">
              완료
            </option>
            <option key={2} value="2">
              작업중
            </option>
            {/* {hospitals.map((hospital: HospitalType, i: number) => (
              <option key={hospital.id} value={hospital.id}>
                {hospital.name}
              </option>
            ))} */}
          </OrderSelect>
        )}
        {!isResearcher && (
          <OrderSelect
            name="hospital"
            value={searchCondition.hospital}
            onChange={(e) => {
              setSearchCondition({
                ...searchCondition,
                [e.target.name]: e.target.value,
              });
              setSearchParam({ ...searchParam, page: "1" });
            }}
          >
            <option value="">병원 전체</option>
            {hospitals.map((hospital: HospitalType, i: number) => (
              <option key={hospital.id} value={hospital.id}>
                {hospital.name}
              </option>
            ))}
          </OrderSelect>
        )}
        <OrderSelect
          name="researcher"
          value={searchCondition.researcher}
          onChange={(e) => {
            setSearchCondition({
              ...searchCondition,
              [e.target.name]: e.target.value,
            });
            setSearchParam({ ...searchParam, page: "1" });
          }}
        >
          <option value="">연구원 전체</option>
          {!isResearcher
            ? researchers.map((researcher: ResearcherType, i: number) => (
              <option key={researcher.id} value={researcher.id}>
                {researcher.name}
              </option>
            ))
            : myHospitalResearchers.map(
              (researcher: ResearcherType, i: number) => (
                <option key={researcher.id} value={researcher.id}>
                  {researcher.name}
                </option>
              )
            )}
        </OrderSelect>
        <OrderSelect
          onChange={(e) => {
            setSort(e.target.value);
            setSearchParam({ ...searchParam, page: "1" });
          }}
        >
          <option value="">기본순</option>
          <option value="hospital">병원순</option>
          <option value="recentTemplate">최근 템플릿순</option>
        </OrderSelect>
        <SearchBox
          query={name}
          placeholder="이름/고유번호로 검색"
          onSubmit={handleSearch}
        />
      </SpaceBetween>
      <Table>
        <thead>
          <TR>{selectTHs(projectId, studies)}</TR>
        </thead>
        <tbody>
          {items.map(
            (
              {
                id,
                sex,
                name,
                birthday,
                code,
                hospitalName,
                type,
                lastDefaultTemplateTitle,
                admin,
                study,
                //value,
                content,
                confirmation,
                query_count,
              }: PatientType,
              i: number
            ) => (
              <TR key={id}>
                <TD>{total - ((page - 1) * display + i)}</TD>
                <TD>
                  <Link
                    to={`patient/${id}`}
                    state={{
                      page: page,
                      search: search,
                      sort,
                      searchCondition,
                    }}
                  >
                    <Name>{name}</Name>
                  </Link>
                </TD>
                <TD>{code}</TD>
                <TD>{hospitalName}</TD>
                <TD>{admin.name}</TD>
                <TD>{type.name}</TD>
                <TD>{lastDefaultTemplateTitle}</TD>
                <TD>{sex === "male" ? "남자" : "여자"}</TD>
                <TD>{getLocaleDate(birthday)}</TD>
                {(() => {
                  if (studies) {
                    if (study[0].name === null) {
                      return (
                        <>
                          <TD>
                            <Modal
                              activator={(onOpen) => (
                                <Addstudy onClick={onOpen} />
                              )}
                              content={(onClose) => (
                                <ModalAssginStudy
                                  onClose={onClose}
                                  data={studies}
                                  patientData={{
                                    id,
                                    name,
                                    hospitalName,
                                    sex,
                                    adminName: admin.name,
                                    birthday,
                                  }}
                                />
                              )}
                            />
                          </TD>
                          {(parseInt(projectId) === 674 ||
                            parseInt(projectId) === 4444) && (
                              <TD>{content ? "Y" : "N"}</TD>
                            )}
                          {(parseInt(projectId) === 674 ||
                            parseInt(projectId) === 4444) && (
                              <TD>{content ? getLocaleDate(content) : ""}</TD>
                            )}
                          {(parseInt(projectId) === 674 ||
                            parseInt(projectId) === 4444) && (
                              <TD>
                                <Link to={{ pathname: '/querys', search: 'patient_id=' + id }}>
                                  {query_count > 0 ? <Red>{query_count}</Red> : query_count}
                                </Link>
                              </TD>
                            )}
                        </>
                      );
                    } else {
                      return (
                        <>
                          <TD>
                            {study[0].name} {study[0].value}
                          </TD>
                          {(parseInt(projectId) === 674 ||
                            parseInt(projectId) === 4444) && (
                              <TD>{content ? "Y" : "N"}</TD>
                            )}
                          {(parseInt(projectId) === 674 ||
                            parseInt(projectId) === 4444) && (
                              <TD>{content ? getLocaleDate(content) : ""}</TD>
                            )}
                          {(parseInt(projectId) === 674 ||
                            parseInt(projectId) === 4444) && (
                              <TD>
                                <Link to={{ pathname: '/querys', search: 'patient_id=' + id }}>
                                  {query_count > 0 ? <Red>{query_count}</Red> : query_count}
                                </Link>
                              </TD>
                            )}
                        </>
                      );
                    }
                  }
                })()}
                {checkConfirmation(projectId, confirmation)}
                <TD>
                  <Trash onClick={() => handleDelete(id, name, code)} />
                </TD>
              </TR>
            )
          )}
        </tbody>
      </Table>
      <Footer>
        <Pagination
          total={total}
          display={display}
          page={page}
          onChange={(page: number) => {
            setPage(page);
            setSearchParam({ page: page + "" });
          }}
        />
      </Footer>
    </Container>
  );
};

/*
const TableScroll = styled.div`
  position:relative;
  max-width:100%;
  margin:auto;
  overflow:auto;
`;

const TablePatient = styled(Table)`
  min-width:900px;
`;*/

const Container = styled.div`
  margin-top: 27px;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  flex: 1;
`;
const Footer = styled.footer`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;
const Name = styled.span`
  text-decoration: underline;
`;
const OrderSelect = styled.select`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  border: 1px solid #dfe0e0;
  padding: 10px;
  background: #ffffff;
  height: 45px;
  margin-right: 10px;
`;

export default Patients;

import getAge from "./common/getAge";

const onsetAge = {
  id: 32,
  name: "onsetAge",
  datas: ["birthday", "onset_date"],
  formula: ({ birthday, onset_date }: any) => {
    if (!birthday || !onset_date) {
      return;
    }
    const result = getAge(birthday, onset_date);
    if (birthday === "1111-11-11") {
      return "Antenatal";
    } else if (result >= 792) {
      return "Elderly";
    } else if (result >= 228) {
      return "Adult";
    } else if (result >= 13) {
      return "Children";
    } else if (result >= 0) {
      return "Infancy";
    } else {
      return;
    }
  },
  help: `만나이(개월) = ((측정년도-출생년도)×12))+(측정월-출생월)+((측정일-출생일)÷30.4))`,
};

export default onsetAge;

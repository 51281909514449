import instance from ".";

export const getTemplates = async (params: {
  patientId: string;
  sectionId?: string;
}) => {
  const result = await instance({
    method: "GET",
    url: `/templates`,
    params,
  });
  return result.data;
};

export const getTemplate = async ({ templateId }: { templateId: string }) => {
  const result = await instance({
    method: "GET",
    url: `/templates/${templateId}`,
  });
  return result.data;
};

export const createTemplate = async (data: {
  patientId: string;
  name: string;
  reporting_date: string;
  scheduled_date: string;
}) => {
  const result = await instance({ method: "POST", url: `/templates`, data });
  return result.data;
};

export const editTemplate = async (data: {
  id: number;
  name: string;
  reporting_date: string;
  scheduled_date: string;
}) => {
  const result = await instance({
    method: "PUT",
    url: `/templates/${data.id}`,
    data,
  });
  return result.data;
};

export const deleteTemplate = async (id: number) => {
  const result = await instance({ method: "DELETE", url: `/templates/${id}` });
  return result.data;
};

export const getTemplateSection = async (id: number) => {
  const result = await instance({
    method: "GET",
    url: `/templates/${id}/sections`,
  });
  return result.data;
};

export const editTemplateSection = async (id: number, sections: number[]) => {
  const result = await instance({
    method: "PUT",
    url: `/templates/${id}/sections`,
    data: { sections },
  });
  return result.data;
};

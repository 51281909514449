import { useQuery } from "react-query";
import { useParams } from "react-router";

import { getSections } from "apis/section";

import { SectionType } from "types";

import styled from "styled-components";
import { TemplateHeader } from "components/atoms/common";
import ViewTemplate from "components/organisms/item/View";

const Sections = () => {
  const { projectId = "", templateId = "", patientId = "" } = useParams();
  const { isLoading, data } = useQuery(
    ["getSections", { projectId, templateId, needValues: true, patientId }],
    () => getSections({ projectId, templateId, needValues: true, patientId })
  );

  if (isLoading) return <></>;
  return (
    // 전체보기 화면 view 모드 
    <>
      {data.map(({ id, name, items }: SectionType) => (
        <Container key={id}>
          <TemplateHeader>{name}</TemplateHeader>
          <ViewTemplate data={items ?? []} />
        </Container>
      ))}
    </>
  );
};

const Container = styled.div`
  margin-top: 35px;
`;
export default Sections;

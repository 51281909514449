import getTotalDose from "./common/getTotalDose";

const totalDoseSteroid = {
  id: 28,
  name: "Total Dose of Steroid",
  datas: [
    "daily_dose_1",
    "daily_dose_2",
    "daily_dose_3",
    "daily_dose_4",
    "daily_dose_5",
    "daily_dose_6",
    "daily_dose_7",
    "daily_dose_8",
    "daily_dose_9",
    "daily_dose_10",
    "daily_dose_11",
    "daily_dose_12",
    "daily_dose_13",
    "daily_dose_14",
    "daily_dose_15",
    "daily_dose_16",
    "daily_dose_17",
    "daily_dose_18",
    "daily_dose_19",
    "daily_dose_20",
    "daily_dose_21",
    "daily_dose_22",
    "daily_dose_23",
    "daily_dose_24",
    "daily_dose_25",
    "daily_dose_26",
    "daily_dose_27",
    "daily_dose_28",
    "daily_dose_29",
    "daily_dose_30",
    "daily_dose_31",
    "daily_dose_32",
    "daily_dose_33",
    "daily_dose_34",
    "daily_dose_35",
    "daily_dose_36",
    "daily_dose_37",
    "daily_dose_38",
    "daily_dose_39",
    "daily_dose_40",
    "daily_dose_41",
    "daily_dose_42",
    "daily_dose_43",
    "daily_dose_44",
    "daily_dose_45",
    "daily_dose_46",
    "daily_dose_47",
    "daily_dose_48",
    "daily_dose_49",
    "daily_dose_50",
    "daily_dose_51",
    "daily_dose_52",
    "daily_dose_53",
    "daily_dose_54",
    "daily_dose_55",
    "daily_dose_56",
    "daily_dose_57",
    "daily_dose_58",
    "daily_dose_59",
    "daily_dose_60",
    "daily_dose_61",
    "daily_dose_62",
    "daily_dose_63",
    "daily_dose_64",
    "daily_dose_65",
  ],
  formula: ({
    daily_dose_1,
    daily_dose_2,
    daily_dose_3,
    daily_dose_4,
    daily_dose_5,
    daily_dose_6,
    daily_dose_7,
    daily_dose_8,
    daily_dose_9,
    daily_dose_10,
    daily_dose_11,
    daily_dose_12,
    daily_dose_13,
    daily_dose_14,
    daily_dose_15,
    daily_dose_16,
    daily_dose_17,
    daily_dose_18,
    daily_dose_19,
    daily_dose_20,
    daily_dose_21,
    daily_dose_22,
    daily_dose_23,
    daily_dose_24,
    daily_dose_25,
    daily_dose_26,
    daily_dose_27,
    daily_dose_28,
    daily_dose_29,
    daily_dose_30,
    daily_dose_31,
    daily_dose_32,
    daily_dose_33,
    daily_dose_34,
    daily_dose_35,
    daily_dose_36,
    daily_dose_37,
    daily_dose_38,
    daily_dose_39,
    daily_dose_40,
    daily_dose_41,
    daily_dose_42,
    daily_dose_43,
    daily_dose_44,
    daily_dose_45,
    daily_dose_46,
    daily_dose_47,
    daily_dose_48,
    daily_dose_49,
    daily_dose_50,
    daily_dose_51,
    daily_dose_52,
    daily_dose_53,
    daily_dose_54,
    daily_dose_55,
    daily_dose_56,
    daily_dose_57,
    daily_dose_58,
    daily_dose_59,
    daily_dose_60,
    daily_dose_61,
    daily_dose_62,
    daily_dose_63,
    daily_dose_64,
    daily_dose_65,
  }: any) => {
    return (
      getTotalDose([
        daily_dose_1,
        daily_dose_2,
        daily_dose_3,
        daily_dose_4,
        daily_dose_5,
        daily_dose_6,
        daily_dose_7,
        daily_dose_8,
        daily_dose_9,
        daily_dose_10,
        daily_dose_11,
        daily_dose_12,
        daily_dose_13,
        daily_dose_14,
        daily_dose_15,
        daily_dose_16,
        daily_dose_17,
        daily_dose_18,
        daily_dose_19,
        daily_dose_20,
      ]) +
      getTotalDose([
        daily_dose_21,
        daily_dose_22,
        daily_dose_23,
        daily_dose_24,
        daily_dose_25,
        daily_dose_26,
        daily_dose_27,
        daily_dose_28,
        daily_dose_29,
        daily_dose_30,
        daily_dose_31,
        daily_dose_32,
        daily_dose_33,
        daily_dose_34,
        daily_dose_35,
        daily_dose_36,
        daily_dose_37,
        daily_dose_38,
        daily_dose_39,
        daily_dose_40,
      ]) /
        1.2 +
      getTotalDose([
        daily_dose_41,
        daily_dose_42,
        daily_dose_43,
        daily_dose_44,
        daily_dose_45,
      ]) /
        0.8 +
      getTotalDose([
        daily_dose_46,
        daily_dose_47,
        daily_dose_48,
        daily_dose_49,
        daily_dose_50,
        daily_dose_51,
        daily_dose_52,
        daily_dose_53,
        daily_dose_54,
        daily_dose_55,
        daily_dose_56,
        daily_dose_57,
        daily_dose_58,
        daily_dose_59,
        daily_dose_60,
        daily_dose_61,
        daily_dose_62,
        daily_dose_63,
        daily_dose_64,
        daily_dose_65,
      ]) /
        0.1
    );
  },
  help: `Steroid 누적용량 = Prednisolone 누적용량 + Deflazacort 누적용량 + Methylprednisolone 누적용량 + Dexamethasone 누적용량`,
};

export default totalDoseSteroid;

import { useParams, Link } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { deleteTemplate, getTemplates } from "apis/template";
import { getLocaleDate } from "utils/time";
import { getTokenPayload } from "utils/user";

import { TemplateType } from "types";

import styled from "styled-components";
import { ReactComponent as Trash } from "assets/icons/Trash.svg";
import { ReactComponent as Correction } from "assets/icons/Correction.svg";
import { Table, TD, TH, TR } from "components/atoms/table";
import { SpaceBetween, Click } from "components/atoms/common";
import { BtnBlue } from "components/atoms/button";
import Modal from "components/organisms/Modal";
import ModalTemplate from "components/templates/Templates/Modal";
import ModalTemplateSection from "components/templates/Templates/ModalSectionWrapper";
import { Red } from "components/atoms/query";

const Templates = () => {
  const { isSuper } = getTokenPayload();
  const queryClient = useQueryClient();
  const { patientId = "", projectId = "" } = useParams();
  const { isLoading, data } = useQuery(["getTemplates", patientId], () =>
    getTemplates({ patientId })
  );
  const mutation = useMutation((id: number) => deleteTemplate(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getTemplates"]);
    },
  });

  if (isLoading) return <></>;

  // dataCopy = [...data] 이렇게 하면 data 내부값이 object 이기때문에
  // 주소가 copy되어 결국 reporting_date 수정시 원본값도 같이 변함
  let dataCopy = data.map((template: TemplateType, i: number) => {
    const temp: any = { ...template, prev_reporting_date: '' };
    return temp;
  });

  if (projectId === "674") {
    for (let i = (data.length - 1); i > 0; i--) {
      // reporting_date 컬럼이 null 인 경우는 null로 유지
      if (dataCopy[i].reporting_date != null) {
        dataCopy[i].prev_reporting_date = findDate(i);
      }
    }
  }

  function findDate(index: number) {
    for (let i = index - 1; i >= 0; i--) {
      // resporting_date 컬럼 전체가 null일 경우 오류 방지
      const { reporting_date } = data[i];

      if (reporting_date == null) continue;
      return reporting_date;
    }

    return '';
  }

  const handleDelete = (id: number) => {
    if (!window.confirm("템플릿을 삭제하시겠습니까?")) return;
    mutation.mutate(id);
  };
  const handleAlert = () => alert("먼저 방문일을 입력하세요");

  return (
    <>
      <SpaceBetween>
        <span>총 {data.length}개의 템플릿 목록</span>
        <Modal
          activator={(onOpen) => (
            <BtnBlue onClick={onOpen}>템플릿 추가 +</BtnBlue>
          )}
          content={(onClose) => <ModalTemplate onClose={onClose} />}
        />
      </SpaceBetween>
      <Table>
        <colgroup>
          <col />
          <col width="50%" />
          <col />
          <col />
        </colgroup>
        <thead>
          <TR>
            <TH>순번</TH>
            <TH left>이름</TH>
            <TH>방문예정일</TH>
            <TH>방문일</TH>
            <TH>기본템플릿</TH>
            {parseInt(projectId) === 674 && !isSuper && <TH>쿼리</TH>}
            <TH>섹션</TH>
            <TH>관리</TH>
          </TR>
        </thead>
        <tbody>
          {dataCopy.map((template: any, i: number) => {
            const { id, name, reporting_date, is_default, scheduled_date, query_count, prev_reporting_date } =
              template;
            return (
              <TR key={id}>
                <TD>
                  <Link to={id + ""}>{i + 1}</Link>
                </TD>
                <TD left>
                  {reporting_date ? (
                    parseInt(projectId) === 674 && getLocaleDate(prev_reporting_date) ? (
                      <Link to={{
                        pathname: id + "",
                        search: 'reportingDT=' + getLocaleDate(prev_reporting_date)
                      }}>
                        <Click>{name}</Click>
                      </Link>) :
                      (parseInt(projectId) === 674 && !getLocaleDate(prev_reporting_date) ? (
                        <Link to={id + ""}>
                          <Click>{name}</Click>
                        </Link>) :

                        (
                          <Link to={id + ""}>
                            <Click>{name}</Click>
                          </Link>

                        )
                      )) : (
                    <Modal
                      activator={(onOpen) => (
                        <Click
                          onClick={() => {
                            handleAlert();
                            onOpen();
                          }}
                        >
                          {name}
                        </Click>
                      )}
                      content={(onClose) => (
                        <ModalTemplate onClose={onClose} data={template} />
                      )}
                    />
                  )}
                </TD>
                <TD>{scheduled_date && getLocaleDate(scheduled_date)}</TD>
                <TD>{reporting_date && getLocaleDate(reporting_date)}</TD>
                <TD>{is_default === 1 ? "Y" : "N"}</TD>
                {parseInt(projectId) === 674 && !isSuper && (
                  <TD>
                    <Link to={{ pathname: '/querys', search: 'template_id=' + id }}>
                      {query_count > 0 ? <Red>{query_count}</Red> : query_count}
                    </Link>
                  </TD>
                )}
                <TD>
                  <Modal
                    activator={(onOpen) => (
                      <Click onClick={onOpen}>섹션 관리</Click>
                    )}
                    content={(onClose) => (
                      <ModalTemplateSection onClose={onClose} id={id} />
                    )}
                  />
                </TD>

                <TD>
                  <Btn>
                    <Modal
                      activator={(onOpen) => <Correction onClick={onOpen} />}
                      content={(onClose) => (
                        <ModalTemplate onClose={onClose} data={template} />
                      )}
                    />

                  </Btn>
                  {is_default === 0 && (
                    <Btn>
                      <Trash onClick={() => handleDelete(id)} />
                    </Btn>
                  )}
                </TD>
              </TR>
            );
          })}
        </tbody>
      </Table>
    </>

  );
};


const Btn = styled.button`
  background: none;
  border: none;
  padding: 0;
  & + & {
    margin-left: 10px;
  }
`;

export default Templates;
import { useEffect } from "react";

const useDisableScroll = (flag?: boolean) => {
  const dependency = [];
  const isBoolean = typeof flag === "boolean";
  if (isBoolean) {
    dependency.push(flag);
  }
  useEffect(() => {
    if (isBoolean) {
      document.body.style.overflow = flag ? "hidden" : "";
    } else {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, dependency);
};
export default useDisableScroll;

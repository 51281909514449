import styled from "styled-components";
import { BtnWhite } from "./button";

export const Red = styled.span`
  color: #ff0000;
  font-weight: 700;
`;
export const Blue = styled.span`
  color: #0000ff;
`;
export const Gray = styled.span`
  color: #c0c0c0;
`;
export const BtnCancel = styled(BtnWhite)`
  width: 90px;
`;
export const BtnQuery = styled.button`
  border: 1px solid #e4e6e6;
  border-radius: 2px;
  padding: 3px 5px;
  color: #989899;
  background: #fff;
  font-size: 12px;
  margin-left: 10px
`;
export const QueryItem = styled.div<{
    size?: number;
    hsize?: number;
  }>`
    display: flex;
    width: ${({ size = 1 }) => `${100 / size}%`};
    border-top: 1px solid #dfe0e0;
    min-height: ${({ hsize }) => `${hsize ? hsize : 40}px`}};
`;
export const QueryName = styled.div<{ flex?: boolean }>`
  background: #fafafc;
  border-left: 1px solid #dfe0e0;
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 17px;
  flex: ${({ flex }) => flex && 1};
  text-align: center;
  position: relative;
`;
export const QueryValue = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  flex: 1;
  font-size: 14px;
  border-left: 1px solid #dfe0e0;
  position: relative;
`;
export const Header = styled.header`
  display: flex;
`;
export const Footer = styled.footer`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;
export const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  align-items: center;
  flex: 1;
  svg {
    margin-right: 10px;
  }
`;
export const Count = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  flex: 1;
`;
export const Container = styled.div`
  margin-top: 27px;
`;
export const Name = styled.span`
  text-decoration: underline;
`;
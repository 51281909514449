import { useEffect, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { getProject } from "apis/project";
import { getPatientTypes } from "apis/patientType";

import { getTokenPayload } from "utils/user";
import { PatientTypeType } from "types";
import { ReactComponent as ProjectTitleLogo } from "assets/icons/ProjectTitleLogo.svg";

import { PageContainer, PageLayout } from "components/atoms/page";
import { TabConatiner, Tab, TabLine } from "components/atoms/tab";
import { BtnBlue } from "components/atoms/button";
import Patients, { TabType } from "components/templates/Project/Patients";
import Statistics from "components/templates/Project/Statistics";
import { getStudyTitles } from "apis/study";
import Reporting from "components/templates/Project/Reporting";

import { getQueryCount, createQuerys } from "apis/query";
import LoadingBar from "components/templates/Query/LoadingBar";

const defaultTabs = [
  {
    name: "전체",
    id: 0,
  },
  {
    name: "통계",
    id: -1,
  },
  {
    name: "리포팅",
    id: -2,
  },
];

const Project = () => {
  const { isSuper, isProjectSuper } = getTokenPayload();
  const [tabs, setTabs] = useState<PatientTypeType[]>([]);
  const [activeTab, setActiveTab] = useState<TabType>({
    typeId: 0,
    studyId: 0,
  });
  const [studyTabs, setStudyTabs] = useState<PatientTypeType[]>([]);
  const [searchParam, setSearchParam] = useSearchParams();
  const searchParamClear = () => {
    const pageSearch = searchParam.get("page");
    const nameSearch = searchParam.get("search");
    if (pageSearch) searchParam.delete("page");
    if (nameSearch) searchParam.delete("search");
    setSearchParam(searchParam);
  };

  const { projectId = "" } = useParams();
  // DEBUG
  //console.log("projectID: " + projectId);

  const { isLoading, data } = useQuery(["getProject", { projectId }], () =>
    getProject({ projectId })
  );
  // DEBUG
  // console.log("isLoading: " + isLoading +
  // " , data: " + JSON.stringify(data,null,2));

  const { isLoading: isLoadingTypes, data: patientTypes } = useQuery(
    ["getPatientTypes", { projectId }],
    () => getPatientTypes({ projectId })
  );
  // DEBUG
  // console.log("isLoadingTypes: " + isLoadingTypes +
  // " , patientTypes: " + JSON.stringify(patientTypes,null,2));

  const { isLoading: isLoadingTitles, data: studyTitles } = useQuery(
    ["getStudyTitles", { projectId }],
    () => getStudyTitles({ projectId })
  );

  // DEBUG
  // console.log("isLoadingTitles: " + isLoadingTitles +
  // " , studyTitles: " + JSON.stringify(studyTitles,null,2));

  //시작 20230210 오토쿼리 관련 추가 by 장희준
  const { data: query_data, isLoading: isQueryLoading } = useQuery(
    "getQueryCount",
    () => getQueryCount()
  );
  const [show, setShow] = useState(false);
  const queryClient = useQueryClient();

  const mutation_query = useMutation(
    (projectId: String) => createQuerys({ projectId }),
    {
      onMutate: () => {
        //console.log("쿼리 발생 체크 시작");
        setShow(true);
      },
      onSuccess: (result) => {
        queryClient.invalidateQueries("getQueryCount");
        alert("쿼리 체크 완료");
        //console.log("쿼리 발생 체크 완료" + JSON.stringify(result));
      },
      onSettled: () => {
        //console.log("쿼리 발생 체크 완료");
        setShow(false);
      },
    }
  );
  //끝 20230210 오토쿼리 관련 추가 by 장희준

  useEffect(() => {
    if (!isLoadingTypes && !isLoadingTitles) {
      setTabs([
        defaultTabs[0],
        ...patientTypes,
        defaultTabs[1],
        defaultTabs[2],
      ]);
      setStudyTabs([...studyTitles]);
    }
  }, [studyTitles, patientTypes, isLoadingTypes, isLoadingTitles]);

  useEffect(() => {
    setActiveTab({ typeId: 0, studyId: 0 });
  }, [projectId]);

  if (isLoading || isLoadingTypes) return <></>;

  const { name } = data;

  // DEBUG
  //console.log("Tabs: " + JSON.stringify(tabs,null,2));



  //시작 20230210 오토쿼리 관련 추가 by 장희준
  if (isQueryLoading) return <></>;

  const handleQuery = async (id: any) => {
    if (isSuper) {
      alert("슈퍼관리자는 추가할 수 없습니다!");
    } else if (window.confirm("쿼리 체크를 시작하시겠습니까?")) {
      mutation_query.mutate(id);
    }
  };
  //끝 20230210 오토쿼리 관련 추가 by 장희준

  return (
    <PageLayout>
      <Header>
        <ProjectTitle>
          <ProjectTitleLogo />
          {name}
        </ProjectTitle>
        {!isSuper && (
          <Link to="patient">
            <BtnBlue>환자 등록 +</BtnBlue>
          </Link>
        )}
      </Header>

      {isProjectSuper !== 0 && parseInt(projectId) === 674 && (
        <Container>
          <LoadingBar show={show} />
          <Main isProjectSuper={isProjectSuper}>
            <Item>
              <Right>
                <BtnSign type="button" onClick={() => handleQuery(projectId)}>오토 쿼리</BtnSign>
              </Right>
            </Item>
            <Item>
              <Title>전체</Title>
              <Right>
                <CountBox>{query_data.total_cnt}</CountBox>
              </Right>
            </Item>
            <Item>
              <Title>오픈</Title>
              <Right>
                <CountBox>{query_data.state1_cnt}</CountBox>
              </Right>
            </Item>
          </Main>
        </Container>
      )}

      <PageContainer>
        <TabConatiner>
          {tabs.map(({ name, id }) => (
            <Tab
              key={id}
              onClick={() => {
                setActiveTab({ typeId: id });
                searchParamClear();
              }}
              isActive={id === activeTab.typeId}
            >
              {name}
            </Tab>
          ))}
          {studyTabs.map(({ name, id }) => (
            <Tab
              key={id}
              onClick={() => {
                setActiveTab({ typeId: Number.POSITIVE_INFINITY, studyId: id });
                searchParamClear();
              }}
              isActive={id === activeTab.studyId}
            >
              {name}
            </Tab>
          ))}
          <TabLine />
        </TabConatiner>
        {activeTab.typeId === -1 ? (
          <Statistics />
        ) : activeTab.typeId === -2 ? (
          <Reporting />
        ) : (
          <Patients tab={activeTab} />
        )}
      </PageContainer>
    </PageLayout>
  );
};

const Header = styled.header`
  display: flex;
`;
const ProjectTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  align-items: center;
  flex: 1;
  svg {
    margin-right: 10px;
  }
`;

const GapLeft = styled.div`
   margin-left: 10px;
`;
const Container = styled.div`
  margin: 20px 0;
  background: #f7f8fa;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Main = styled.div<{ isProjectSuper?: number }>`
  background: #ffffff;
  box-shadow: 0px 0px 16px rgba(22, 49, 103, 0.07);
  border-radius: 8px;
  display: grid;
  grid-template-columns: repeat(
    ${({ isProjectSuper }) => (isProjectSuper === 0 ? 3 : 6)},
    minmax(auto, auto)
  );
  padding: 20px;
`;

const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 15px;
  & + & {
    :before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 35px;
      background: #e4e6e6;
      width: 1px;
    }
  }
`;

const Right = styled.div`
  margin-left: 12px;
`;

const Title = styled.div`
  color: #989899;
  font-size: 13px;
  font-weight: 300;
`;

const BtnSign = styled.button`
  margin: 0px 20px 0px;
  border-radius: 5px;
  height: 50px;
  width: 120px;
  background-color: rgb(0, 67, 183);
  color: #ffffff;
  font: 14px;
  font-style: Arial;
  border: none;
`;

const CountBox = styled.form`
  margin: 0px 3px 0px;
  border-radius: 5px;
  border: 1px solid #3e91b5;
  height: 50px;
  width: 90px;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  flex: 0.25;
  justify-content: center;
`;

export default Project;

import PatientType from "types/patient";

const MidParentalHeight = {
  id: 14,
  name: "MidParentalHeight",
  datas: ["fathersHeight", "mothersHeight"],
  formula: ({ fathersHeight, mothersHeight }: any, { sex }: PatientType) => {
    if (!fathersHeight || !mothersHeight) {
      return;
    }
    fathersHeight = parseInt(fathersHeight);
    mothersHeight = parseInt(mothersHeight);
    return sex === "male"
      ? (fathersHeight + mothersHeight + 13) / 2
      : (fathersHeight + mothersHeight - 13) / 2;
  },
  help: `*중간 부모키 계산식 : 

  1) 남자인 경우 :
  (아빠키 + 엄마키 + 13) / 2
  
  2) 여자인 경우 :
  (아빠키 + 엄마키 - 13) / 2`,
};

export default MidParentalHeight;

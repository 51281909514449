import bmi from "./bmi";
import k from "./k";
import eGFR from "./eGFR";
import eGFRNone from "./eGFRNone";
import age from "./age";
import onsetAge2 from "./onsetAge2";
import onsetAge from "./onsetAge";
import maxCapacity from "./maxCapacity";
import fu from "./fu";
import bsa from "./bsa";
import heightLMS from "./heightLMS";
import weightLMS from "./weightLMS";
import bmiLMS from "./bmiLMS";
import fuAge from "./fuAge";
import fuageYear from "./fuageYear";
import EENPEN from "./EenPen";
import MidParentalHeight from "./MidParentalHeight";
import eGFRU25_Cr from "./eGFRU25-Cr";
import eGFRU25CystatinC from "./eGFRU25-CysC";
import eGFRU25CrCystatinC from "./eGFRU25-Cr-CysC";
import fuBsa from "./fuBsa";
import heightPercentile from "./heightPercentile";
import weightPercentile from "./weightPercentile";
import bmiPercentile from "./bmiPercentile";
import dbpPercentile from "./dbpPercentile";
import sbpPercentile from "./sbpPercentile";
import period from "./period";
import totalDaysofUse from "./totalDaysofUse";
import periodOfUse10 from "./periodOfUse10";
import periodOfUse5 from "./periodOfUse5";
import totalDoseSteroid from "./totalDoseSteroid";
import totalDose10 from "./totalDose10";
import totalDose5 from "./totalDose5";
import totalDoseSteroid2 from "./totalDoseSteroid2";
import afterThreeMonths from "./afterThreeMonths";
import afterSixMonths from "./afterSixMonths";
import afterOneYear from "./afterOneYear";
import afterTwoYears from "./afterTwoYears";
import afterFiveYears from "./afterFiveYears";
import sixMonthsAgo from "./sixMonthsAgo";
import periodOfUse20 from "./periodOfUse20";
import totalDose20 from "./totalDose20";

const formulas = [
  bmi,
  k,
  eGFR,
  eGFRNone,
  age,
  onsetAge2,
  maxCapacity,
  fu,
  bsa,
  heightLMS,
  weightLMS,
  bmiLMS,
  fuAge,
  EENPEN,
  MidParentalHeight,
  eGFRU25_Cr,
  eGFRU25CystatinC,
  eGFRU25CrCystatinC,
  fuBsa,
  heightPercentile,
  weightPercentile,
  bmiPercentile,
  dbpPercentile,
  sbpPercentile,
  period,
  totalDaysofUse,
  periodOfUse10,
  periodOfUse5,
  totalDoseSteroid,
  totalDose10,
  totalDose5,
  onsetAge,
  fuageYear,
  totalDoseSteroid2,
  afterThreeMonths,
  afterSixMonths,
  afterOneYear,
  afterTwoYears,
  afterFiveYears,
  sixMonthsAgo,
  periodOfUse20,
  totalDose20,
];

export default formulas;

//계산식추가
//f/u 시점 체표면적당 용량 자동계산 (mg/BSA/WK) (=   용량  mg/{(키(cm) x 체중(kg))/3600}1/2 /week)
const fuBsa = {
  id: 4,
  name: "fuBsa",
  datas: ["fu", "height", "weight"],
  formula: ({ fu, height, weight }: any) => {
    if (!fu || !height || !weight) {
      return;
    }
    return (fu / Math.sqrt((height * weight) / 3600)).toFixed(1);
  },
  help: "mg/{(키(cm) x 체중(kg))/3600}1/2",
};

export default fuBsa;

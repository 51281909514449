import { ReactNode, useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  useNavigate,
  useParams,
  Link,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { getSections, getSection } from "apis/section";
import { putValues } from "apis/values";
import useDisableScroll from "hooks/useDisableScroll";

import ItemForm from "components/organisms/item/Form";
// import { Backdrop, ModalContainer } from "components/atoms/modal";
import { SpaceBetween, TemplateHeader } from "components/atoms/common";
import {
  BtnBlack,
  BtnGray,
  BtnWhite,
  BtnPink,
  BtnBlue,
} from "components/atoms/button";
import { getTemplate } from "apis/template";
import { getLocaleDate } from "utils/time";

import { createQuerys } from "apis/query";

import styled from "styled-components";
import InputItem from "components/molecules/InputItem";
import { Items, Value, Required } from "components/atoms/item";
// import { Item, Items, Name, Value, Required } from "components/atoms/item";
import { Input, Textarea } from "components/atoms/input";
// import { Input, Select, Textarea } from "components/atoms/input";

import noticeSearchStateInit from "stores/sampleSearchState";
import { useRecoilState } from "recoil";
import { SampleSearchType } from "types";
import localForage from "localforage";
import { Table, TR, TH } from "components/atoms/table";
import { Tab } from "components/atoms/tab";
import Pagination from "components/organisms/Pagination";
import { getNotices } from "apis/notice";
import { getTokenPayload } from "utils/user";
import { getMyInfo } from "apis/user";
import { PageContainer, PageLayout } from "components/atoms/page";
import { ReactComponent as ProjectTitleLogo } from "assets/icons/ProjectTitleLogo.svg";
import { log } from "console";
import SearchBox from "components/molecules/SearchBox";

const display = 15;

const Notice = () => {
  const { isSuper, isProjectSuper, adminId } = getTokenPayload();
  const { data: myInfo, isLoading: isLoadingMyInfo } = useQuery(
    "getMyInfo",
    getMyInfo
  );
  const queryClient = useQueryClient();
  const wrapperEl = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const {
    projectId = "",
    templateId = "",
    sectionId = "",
    patientId = "",
  } = useParams();
  let location = useLocation();
  const locationObj = JSON.parse(JSON.stringify(location));
  const [searchParam, setSearchParam] = useSearchParams();
  const search = searchParam.get("search");
  const cur_page = parseInt(searchParam.get("page") ?? "1");

  // useDisableScroll();

  const [noticeSearchState, setNoticeSearchState] = useRecoilState(
    noticeSearchStateInit
  );

  const [page, setPage] = useState(cur_page ?? 1);
  const [query, setQuery] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleSearch = (query: string) => {
    setQuery(query);
    setPage(1);
  };

  // const params: any = {
  //   page: noticeSearchState.page,
  //   query: noticeSearchState.study,
  //   startDate: noticeSearchState.startDate,
  //   endDate: noticeSearchState.endDate,
  // };

  const params: any = {
    page: noticeSearchState.page,
    query: query,
    startDate: noticeSearchState.startDate,
    endDate: noticeSearchState.endDate,
  };

  const defaultState = {
    page: 1,
    study: "",
    startDate: "",
    endDate: "",
  };

  const [state, setState] = useState(defaultState);

  const { data, isLoading } = useQuery(
    ["getNotices", params],
    () => getNotices(params),
    { keepPreviousData: true }
  );

  useEffect(() => {
    setNoticeSearchState({
      ...noticeSearchState,
      page: noticeSearchState.page,
    });
  }, [noticeSearchState.page]);

  useEffect(() => {
    localForage.getItem("noticeSearchState").then((value: any) => {
      const serachStateValue = value;
      if (serachStateValue) {
        setNoticeSearchState(serachStateValue);
      }
    });
  }, [setNoticeSearchState]);

  useEffect(() => {
    localForage.setItem("noticeSearchState", noticeSearchState);
  }, [noticeSearchState]);

  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      localForage.clear();
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  useEffect(() => {
    setPage(cur_page);
  }, [cur_page]);

  useEffect(() => {
    setPage(1);
    setQuery("");
    setStartDate("");
    setEndDate("");
  }, []);

  const onChangeInput = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const handleReset = () => {
    setNoticeSearchState({
      page: 1,
      study: "",
      startDate: "",
      endDate: "",
    });
    setState(defaultState);
  };

  if (isLoading) return <></>;

  const { total, items } = data;

  return (
    <PageLayout>
      <Header>
        <ProjectTitle>
          <ProjectTitleLogo />
          공지사항
        </ProjectTitle>
        {adminId === 564 || adminId === 392 ? (
          <Link to="new">
            <BtnBlue>글쓰기</BtnBlue>
          </Link>
        ) : (
          <></>
        )}
      </Header>

      <PageContainer style={{ paddingTop: "10px", paddingBottom: "10px" }}>
        <Container2 style={{ paddingTop: "10px" }}>
          <SpaceBetween>
            <Title style={{ marginBottom: "-20px" }}>총 {total}건</Title>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <InputText
              style={{ marginRight: "10px" }}
              width="300px"
              name="study"
              value={state.study}
              onChange={onChangeInput}
            /> */}
              <SearchBox
                query={query}
                onSubmit={handleSearch}
                placeholder="제목/내용으로 검색"
              />
              {/* <BtnBlue
              onClick={() => setNoticeSearchState({ ...state, page: 1 })}
            >
              검색
            </BtnBlue> */}
            </div>
          </SpaceBetween>
          {/* <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div></div>
        <div>
          <BtnBlue onClick={() => setSampleSearchState({ ...state, page: 1 })}>
            Search
          </BtnBlue>
          <LeftMargin />
          <BtnBlue onClick={handleReset}> Reset </BtnBlue>
        </div>
      </div> */}
          {/* <Title>총 {total}건</Title> */}
          {/* <Table style={{ marginTop: "5px" }}> */}
          <Table>
            <thead>
              <TR>
                <TH>번호</TH>
                <TH style={{ width: "70%" }}>제목</TH>
                <TH>작성자</TH>
                <TH>작성일</TH>
              </TR>
            </thead>
            <tbody>
              {items.map((item: any, index: number) => (
                <TR
                  key={index}
                  style={item.important ? { backgroundColor: "#f9f9f8" } : {}}
                >
                  <TD>
                    {total - ((noticeSearchState.page - 1) * display + index)}
                  </TD>
                  <TD
                    style={
                      item.important ? { color: "red", fontWeight: "bold" } : {}
                    }
                  >
                    <Link to={`../notices/${item.id}`}>
                      {item.important ? <span>📌 </span> : <></>}
                      {item.title}
                    </Link>
                  </TD>
                  <TD>{item.name}</TD>
                  <TD>{getLocaleDate(item.created_at)}</TD>
                </TR>
              ))}
            </tbody>
          </Table>
          <Footer>
            <Pagination
              total={total}
              display={display}
              page={noticeSearchState.page}
              onChange={(page: number) => {
                setPage(page);
                setSearchParam({ page: page + "" });
                setNoticeSearchState({
                  ...noticeSearchState,
                  page: page,
                });
              }}
            />
          </Footer>
        </Container2>
      </PageContainer>
    </PageLayout>
  );
};

const Header = styled.header`
  display: flex;
`;

const ProjectTitle = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  align-items: center;
  flex: 1;
  svg {
    margin-right: 10px;
  }
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  flex: 1;
  margin-left: 10px;
  // margin-top: 12px;
`;

const Footer = styled.footer`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;

const Container2 = styled.div`
  // margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Space = styled.span`
  margin-left: 10px;
`;

const SearchTable = styled.table`
  width: 100%;
  // border: 1px solid #dfe0e0;
  border-collapse: collapse;
  // margin-top: 12px;
  // border-top: 1px solid #dfe0e0;
`;

const SearchTR = styled.tr`
  // border-top: 1px solid #dfe0e0;
  // border-bottom: 1px solid #dfe0e0;
`;

const SearchTH = styled.th`
  font-style: Arial;
  text-align: ${({ left }: { left?: boolean }) => (left ? "left" : "center")};
  padding-left: ${({ left }: { left?: boolean }) => (left ? "10px" : "0px")};
  color: #0043b7;
  // background: #edf1fa;
  height: 40px;
  font-size: 14px;
  // border-top: 1px solid #dfe0e0;
  // border-bottom: 1px solid #dfe0e0;
  // border-left: 1px solid #dfe0e0;
  width: 100px;
`;

const SearchTD = styled.td`
  font-style: Arial;
  font-weight: 400;
  // border-top: 1px solid #dfe0e0;
  // border-bottom: 1px solid #dfe0e0;
  // border-right: 1px solid #dfe0e0;
  // border-left: 1px solid #dfe0e0;
  background: white;
  font-size: 12px;
  height: 40px;
  text-align: ${({ left }: { left?: boolean }) => (left ? "left" : "center")};
  padding-left: ${({ left }: { left?: boolean }) => (left ? "0px" : "0px")};
`;

const InputText = styled.input<{
  width?: string;
  small?: boolean;
  middle?: boolean;
  xSmall?: boolean;
  disabled?: boolean;
}>`
  padding: 10px;
  background: ${({ disabled }) => disabled && "LightGray"};
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  width: ${({ width }) => width ?? "100%"};
  max-width: ${({ middle }) => middle && "400px"};
  width: ${({ small }) => small && "200px"};
  width: ${({ xSmall }) => xSmall && "150px"};
  height: 45px;
`;

const Backdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const ModalContainer = styled.div<{ large?: boolean }>`
  background: #fff;
  padding: 30px 40px;
  width: 90%;
  max-height: 95vh;
  max-width: ${({ large }) => !large && "750px"};
  overflow: auto;
`;

const Layout = styled.div``;

const Container = styled.div`
  // position: fixed;
  top: 0;
  left: 0;
  flex-shrink: 0;
  width: 260px;
  // height: 100vh;
  // overflow: auto;
  display: flex;
  background: #fff;
  flex-direction: column;
  border-right: 1px solid #e4e6e6;
`;

const MainItem = styled.div<{ top?: boolean }>`
  padding: 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #e4e6e6;
  border-top: ${({ top }) => top && "1px solid #e4e6e6"};
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Main = styled.main`
  margin-left: 260px;
  // @media (max-width: 1315px) {
  //   margin-left: 0px;
  // }
`;

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 7px;
  }
`;

const Item = styled.div`
  display: flex;
  width: ${({ size = 1 }: { size?: number }) => `${100 / size}%`};
  border-top: 1px solid #dfe0e0;
  min-height: 60px;
`;

const ValueDown = styled.div<{
  borderLeft?: boolean;
}>`
  border-left: ${({ borderLeft }) => borderLeft && "1px solid #dfe0e0;"};
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  position: relative;
`;

const Select = styled.select<{
  width?: string;
  disabled?: boolean;
}>`
  width: ${({ width }) => width ?? "70px"};
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  height: 30px;
  background: ${({ disabled }) => disabled && "LightGray"};
`;

const Name = styled.div<{ division?: boolean; isSection151?: boolean }>`
  background: #fafafc;
  border-left: 1px solid #dfe0e0;
  width: 140px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

// const Item = styled.div<{ isActive: boolean }>`
//   padding: 20px;
//   font-size: 14px;
//   line-height: 20px;
//   border-bottom: 1px solid #e4e6e6;
//   margin: 0 18px;
//   a {
//     color: ${({ isActive }) => (isActive ? "#000" : "#989899")};
//     font-weight: ${({ isActive }) => (isActive ? "500" : "400")};
//   }
// `;

const Form = styled.form``;

const LeftMargin = styled.span`
  margin-left: 10px;
`;

const TD = styled.td`
  font-weight: 400;
  font-size: 14px;
  height: 45px;
  text-align: ${({ left }: { left?: boolean }) => (left ? "left" : "center")};
`;

export default Notice;

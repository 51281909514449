import { ChangeEvent, useEffect } from "react";

import { Select } from "components/atoms/input";
import { ItemType, OnChangeType, ItemParentType } from "types";

import { useQuery } from "react-query";
import { getItems } from "apis/item";
import { useParams } from "react-router";

const ItemSelect = ({
  data,
  onChange,
  value,
}: {
  data: ItemType;
  onChange: OnChangeType;
  value: any;
}) => {
  const { options } = data;

  const id = data.id + "";
  const valueList = data.valueOptions ?? [options[0]?.id];

  const { sectionId = "" } = useParams();
  const { projectId = "" } = useParams();

  useEffect(() => {
    onChange({ target: { name: id, value: valueList } });
  }, []);

  const itemId = data.id;

  const { data: items, isLoading: isLoadingItems } = useQuery(
    ["getItems", { sectionId, projectId }],
    () => getItems({ sectionId, projectId })
  );

  const sortedData = items?.sort(
    (a: ItemType, b: ItemType) => a.position - b.position
  );

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    onChange({ target: { name: id, value: [+value] } });

    const subItems = sortedData.filter(
      ({ parent }: { parent: ItemParentType }) => parent.id === itemId
    );
    if (subItems.length > 0) {
      for (const subItem of subItems) {
        onChange({ target: { name: subItem.id.toString(), value: null } });
        if (subItem.options) {
          const subItems = sortedData.filter(
            ({ parent }: { parent: ItemParentType }) => parent.id === subItem.id
          );
          if (subItems.length > 0) {
            for (const subItem of subItems) {
              onChange({
                target: { name: subItem.id.toString(), value: null },
              });
              if (subItem.options) {
                const subItems = sortedData.filter(
                  ({ parent }: { parent: ItemParentType }) =>
                    parent.id === subItem.id
                );
                if (subItems.length > 0) {
                  for (const subItem of subItems) {
                    onChange({
                      target: { name: subItem.id.toString(), value: null },
                    });
                  }
                }
              }
            }
          }
        }
      }
    }
  };

  return (
    <Select name={id} onChange={handleChange} value={value ?? valueList[0]}>
      {[...options]
        ?.sort((a, b) => a.position - b.position)
        .map(({ id, value }) => (
          <option key={id} value={id}>
            {value}
          </option>
        ))}
    </Select>
  );
};

export default ItemSelect;

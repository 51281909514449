import { TemplateHeader } from "components/atoms/common";
import { Input } from "components/atoms/input";
import { Item, Items, Name, Value } from "components/atoms/item";
import { Option } from "components/molecules/InputItem/Checkbox";
import { geteGFRU25_Cr } from "datas/formulas/common/geteGFRU25";
import React, { Dispatch, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { SRNSFlag } from "stores/studyFlag";
import { patientDataType } from "./Modal";
import { optionsPartType, options_part3, options_part4 } from "./StudyConstants";

const ModalSRNS = ({
  patientData,
  setEGFRInput,
  eGFRInput,
}: {
  patientData: patientDataType;
  setEGFRInput: Dispatch<React.SetStateAction<number>>;
  eGFRInput: number;
}) => {
  // height, sex, age
  const birthday = patientData.birthday;
  const age = (Date.now() - +new Date(birthday)) / 31557600000;
  const sex = patientData.sex;
  const [checkA, setCheckA] = useState(options_part3);
  const [checkB, setCheckB] = useState(options_part4);
  const [researchFlag, setResearchFlag] = useRecoilState(SRNSFlag);

  const [height, setHeight] = useState(0);
  const [cr, setCr] = useState(0);

  //const [eGFRInput, setEGFRInput] = useRecoilState(eGFR);
  //const setEGFRInput = useSetRecoilState(eGFR);

  //const [eGFRInput, setEGFRInput] = useState(0);

  const checkHandler = (
    e: any,
    myState: optionsPartType[],
    mySetState: Dispatch<React.SetStateAction<optionsPartType[]>>
  ) => {
    let newArr = [...myState];
    newArr[e.target.id] = {
      ...newArr[e.target.id],
      flag: !newArr[e.target.id].flag,
    };
    mySetState(newArr);
  };

  let inclusionFlag = checkA.every((x) => {
    return x.flag === true;
  });
  let exclusionFlag = checkB.every((x) => {
    return x.flag === true;
  });

  const onCrInput = (e: any) => {
    setCr(e.target.value);
  };
  const onHeightInput = (e: any) => {
    setHeight(e.target.value);
  };

  useEffect(() => {
    setResearchFlag(inclusionFlag && exclusionFlag);
  }, [inclusionFlag, exclusionFlag]);

  useEffect(() => {
    const eGFR = Number(
      geteGFRU25_Cr({ height, age, blood_creatinine: cr }, sex)
    );

    // INF
    setEGFRInput(eGFR);
  }, [cr, height]);

  return (
    <>
      <TemplateHeader>Inclusion criteria</TemplateHeader>
      <Items>
        <Item>
          <Name>All must be YES</Name>
          <Value>
            <div>
              {options_part3.map(({ id, name }) => (
                <Option key={id}>
                  <label>
                    <input
                      type="checkbox"
                      id={checkA[id]?.id + ""}
                      checked={checkA[id]?.flag}
                      onChange={(e) => checkHandler(e, checkA, setCheckA)}
                    />
                    {name}
                  </label>
                </Option>
              ))}
            </div>
          </Value>
        </Item>
      </Items>
      <TemplateHeader>Exclusion criteria</TemplateHeader>
      <Items>
        <Item>
          <Name>All must be NO</Name>
          <Value>
            <div>
              {options_part4.map(({ id, name }) => (
                <Option key={id}>
                  <label>
                    <input
                      type="checkbox"
                      id={checkB[id]?.id + ""}
                      checked={checkB[id]?.flag}
                      onChange={(e) => checkHandler(e, checkB, setCheckB)}
                    />
                    {name}
                  </label>
                </Option>
              ))}
            </div>
          </Value>
        </Item>
      </Items>

      <TemplateHeader>임상 enroll 시점 eGFR 수치</TemplateHeader>
      <Items>
        <Item>
          <Name>Height</Name>
          <Value>
            <Input
              xSmall
              onChange={onHeightInput}
              type="number"
              step="0.1"
              defaultValue={0}
            />
            {"cm"}
          </Value>
          <Name>Age</Name>
          <Value>{Math.floor(age)} 세</Value>
        </Item>
        <Item>
          <Name>Cr</Name>
          <Value>
            <Input
              xSmall
              onChange={onCrInput}
              type="number"
              step="0.01"
              defaultValue={0}
            />
            {"mg/dL"}
          </Value>
        </Item>
        <Item>
          <Name>eGFR (U25-Cr)</Name>
          <Value>{eGFRInput}</Value>

          <Name>반응군</Name>
          {0 < eGFRInput && eGFRInput < 90 ? (
            <Value>신기능저하군</Value>
          ) : 90 <= eGFRInput ? (
            <Value>정상신기능군</Value>
          ) : (
            <Value>범위 오류</Value>
          )}
        </Item>
        {/* <Item>
          <Name>추가되는 템플릿</Name>
          <Value>
            {willAddTemplates?.map((item: any) => {
              return `| ${item} | `;
            })}
          </Value>
        </Item> */}
      </Items>
    </>
  );
};

export default ModalSRNS;

import getPeriod from "./common/dateCalculator";

const periodOfUse5 = {
  id: 27,
  name: "period of use (5)",
  datas: [
    "start_date_1",
    "end_date_1",
    "start_date_2",
    "end_date_2",
    "start_date_3",
    "end_date_3",
    "start_date_4",
    "end_date_4",
    "start_date_5",
    "end_date_5",
  ],
  formula: ({
    start_date_1,
    start_date_2,
    start_date_3,
    start_date_4,
    start_date_5,
    end_date_1,
    end_date_2,
    end_date_3,
    end_date_4,
    end_date_5,
  }: any) => {
    if (!start_date_1) {
      return;
    }
    return (
      (getPeriod(start_date_1, end_date_1) || 0) +
      (getPeriod(start_date_2, end_date_2) || 0) +
      (getPeriod(start_date_3, end_date_3) || 0) +
      (getPeriod(start_date_4, end_date_4) || 0) +
      (getPeriod(start_date_5, end_date_5) || 0)
    );
  },
  help: `총 약제 사용일`,
};

export default periodOfUse5;

import { useQuery } from "react-query";
import styled from "styled-components";
import { useParams } from "react-router";

import { Items, Item, Name, Value, Required } from "components/atoms/item";
import { Input, Select, Textarea } from "components/atoms/input";
import { TemplateHeader } from "components/atoms/common";

import {
  OnChangeType,
  PatientType,
  ResearcherType,
  HospitalType,
  PatientTypeType,
} from "types";

import { getHospitals } from "apis/hospital";
import { getResearchers } from "apis/researcher";
import { getPatientTypes } from "apis/patientType";
import ItemHelp from "components/molecules/ItemHelp";

const BasicInfo = ({
  state,
  onChange,
}: {
  state: PatientType;
  onChange: OnChangeType;
}) => {
  const { projectId = "" } = useParams();
  const { data: hospitals, isLoading: isLoadingHospitals } = useQuery(
    ["getHospitals", { projectId }],
    () => getHospitals({ projectId })
  );
  const { data: researchers, isLoading: isLoadingResearchers } = useQuery(
    ["getResearchers", { projectId }],
    () => getResearchers({ projectId })
  );
  const { data: types, isLoading: isLoadingTypes } = useQuery<
    PatientTypeType[]
  >(["getPatientTypes", { projectId }], () => getPatientTypes({ projectId }), {
    onSuccess: () => {
      if (!patient_type_id && types?.length) {
        onChange({ target: { name: "patient_type_id", value: types[0].id } });
      }
    },
    onError: () => {
      console.log("err");
      console.log(types);
    },
  });

  const {
    id,
    name,
    patient_type_id,
    birthday,
    sex,
    note,
    code,
    initial_diagnosis_date,
    hospital_id,
    admin_id,
  } = state;

  if (isLoadingHospitals || isLoadingResearchers || isLoadingTypes)
    return <></>;

  return (
    <>
      <TemplateHeader>기본 정보</TemplateHeader>
      <Items>
        <Item size={1}>
          <Name>
            {projectId !== ''+675 ? `환자명 (Initial)` : `닉네임`}
            {projectId === ''+675 && <ItemHelp data="서울대 등은 빈칸" />}
            <Required>*</Required>
          </Name>
          <Value>
            <Input value={name} onChange={onChange} name="name" />
          </Value>
        </Item>
        {id && (
          <Item size={1}>
            <Name>
              고유번호<Required>*</Required>
            </Name>
            <Value>
              <Input value={code} onChange={onChange} name="code" required />
            </Value>
          </Item>
        )}
        <Item size={2}>
          <Name>소속병원</Name>
          <Value>
            <Select name="hospital_id" onChange={onChange} value={hospital_id}>
              {hospitals.map(({ id, name }: HospitalType) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          </Value>
        </Item>
        <Item size={2}>
          <Name>담당 연구원</Name>
          <Value>
            <Select name="admin_id" onChange={onChange} value={admin_id}>
              {researchers.map(({ id, name }: ResearcherType) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          </Value>
        </Item>
        {!id && (
          <Item size={2}>
            <Name>타입</Name>
            <Value>
              <Select
                value={patient_type_id}
                onChange={onChange}
                name="patient_type_id"
              >
                {types?.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </Value>
          </Item>
        )}
        <Item size={2}>
          <Name>
            등록일<Required>*</Required>
          </Name>
          <Value>
            <Input
              type="date"
              name="initial_diagnosis_date"
              value={initial_diagnosis_date}
              onChange={onChange}
              max="2999-12-31"
              required
              small
            />
          </Value>
        </Item>
        <Item size={2}>
          <Name>성별</Name>
          <Value>
            <Label>
              <input
                type="radio"
                name="sex"
                value="male"
                checked={sex === "male"}
                onChange={onChange}
              />
              남자
            </Label>
            <Label>
              <input
                type="radio"
                name="sex"
                value="female"
                checked={sex === "female"}
                onChange={onChange}
              />
              여자
            </Label>
          </Value>
        </Item>
        <Item size={1}>
          <Name>
            생년월일
            {projectId === ''+675 && <ItemHelp data="서울대 등은 일은 1일로 통일" />}
            <Required>*</Required>
          </Name>
          <Value>
            <Input
              type="date"
              name="birthday"
              value={birthday}
              onChange={onChange}
              max="2999-12-31"
              required
              small
            />
          </Value>
        </Item>
        <Item size={1}>
          <Name>비고</Name>
          <Value>
            <Textarea name="note" onChange={onChange} value={note} />
          </Value>
        </Item>
      </Items>
    </>
  );
};

const Label = styled.label`
  display: flex;
  margin-right: 15px;
  min-width: 70px;
`;

export default BasicInfo;

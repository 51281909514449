import { useEffect } from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import isLoginState from "stores/isLoginState";
const PrivateRoute = () => {
  const navigate = useNavigate();
  const isLoggedIn = useRecoilValue(isLoginState);
  useEffect(() => {
    if (!isLoggedIn) {
      alert("로그인 후 이용해주세요.");
      navigate("/login");
    }
  }, []);
  return isLoggedIn ? <Outlet /> : null;
};
export default PrivateRoute;

import styled from "styled-components";

import { ItemType } from "types";

import { BtnBlue } from "components/atoms/button";
import Modal from "components/organisms/Modal";
import EditItem from "components/organisms/item/Edit";
import ModalItemPreview from "components/organisms/item/ModalPreview";
import ModalAddItem from "components/organisms/item/ModalAdd";

const EditItemWrapper = ({ data }: { data: ItemType[] }) => {
  const sortedData = data.sort(
    (a: ItemType, b: ItemType) => a.position - b.position
  );
  const filteredData = sortedData.filter(
    (item: ItemType) => !item.item_option_id
  );

  return (
    <Container>
      <div>
        <Modal
          activator={(onOpen) => (
            <BtnBlue onClick={onOpen}>상위 항목 추가 +</BtnBlue>
          )}
          content={(onClose) => <ModalAddItem onClose={onClose} />}
        />
      </div>
      {filteredData.map((item: ItemType, i: number) => (
        <EditItem
          data={item}
          list={sortedData}
          key={item.id}
          index={i + 1 + ""}
        />
      ))}
      {data.length > 0 && (
        <div>
          <Modal
            activator={(onOpen) => (
              <BtnBlue onClick={onOpen}>상위 항목 추가 +</BtnBlue>
            )}
            content={(onClose) => <ModalAddItem onClose={onClose} />}
          />
        </div>
      )}
      <Modal
        activator={(onOpen) => (
          <BtnPreview onClick={onOpen}>미리보기</BtnPreview>
        )}
        content={(onClose) => (
          <ModalItemPreview data={data} onClose={onClose} />
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: 60px 0;
`;

const BtnPreview = styled.div`
  cursor: pointer;
  position: fixed;
  right: 0;
  bottom: 150px;
  background: #000;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  padding: 17px;
  width: 120px;
`;

export default EditItemWrapper;

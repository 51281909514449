import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { BtnPink } from "components/atoms/button";
import { SpaceBetween } from "components/atoms/common";
import { Item, Items, Name, Value } from "components/atoms/item";
import { Input } from "components/atoms/input";
import { ModalContainer } from "components/atoms/modal";

import { editProject, createProject } from "apis/project";

const ModalProjectName = ({
  data,
  onClose,
}: {
  data?: { id: number; name: string };
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const [name, setName] = useState(data?.name ?? "");
  const action = data
    ? () => editProject({ id: data.id, name })
    : () => createProject({ name });
  const mutation = useMutation(action, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getProject"]);
      queryClient.invalidateQueries(["getProjects"]);
      onClose();
    },
  });

  const handleSubmit = async () => {
    if (!name) return alert("이름을 입력하세요.");

    mutation.mutate();
  };

  return (
    <ModalContainer>
      <h2>프로젝트 {data ? "수정" : "만들기"}</h2>
      <Items>
        <Item>
          <Name>이름</Name>
          <Value>
            <Input
              placeholder="이름"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Value>
        </Item>
      </Items>
      <SpaceBetween>
        <div></div>
        <BtnPink onClick={handleSubmit} large>
          저장
        </BtnPink>
      </SpaceBetween>
    </ModalContainer>
  );
};

export default ModalProjectName;

import { useState } from "react";
import styled from "styled-components";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

import { ReactComponent as Trash } from "assets/icons/Trash.svg";
import { ReactComponent as Correction } from "assets/icons/Correction.svg";
import ResearcherType from "types/researcher";
import { deleteResearcher, getResearchers } from "apis/researcher";

import { Table, TR, TH, TD } from "components/atoms/table";
import { SpaceBetween } from "components/atoms/common";
import { BtnBlue } from "components/atoms/button";
import Modal from "components/organisms/Modal";
import Pagination from "components/organisms/Pagination";
import ModalResearcher from "components/templates/admin/Researcher/Modal";
import { getLocaleDate } from "utils/time";

const display = 15;
const Researcher = () => {
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const { projectId = "" } = useParams();
  const { data, isLoading } = useQuery(
    ["getResearchers", { projectId, page, display }],
    () => getResearchers({ projectId, page, display }),
    { keepPreviousData: true }
  );
  const mutation = useMutation((id: number) => deleteResearcher(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getResearchers"]);
    },
  });

  const handleDelete = (id: number, patients: number) => {
    if (patients > 0)
      return alert("담당 환자가 있는 연구원은 삭제할 수 없습니다.");
    if (!window.confirm("정말 삭제하시겠습니까?")) return;
    mutation.mutate(id);
  };

  return (
    <Container>
      <SpaceBetween>
        <Title>총{data?.total}명</Title>
        <Modal
          activator={(onOpen) => (
            <BtnBlue onClick={onOpen}>연구원 추가 +</BtnBlue>
          )}
          content={(onClose) => <ModalResearcher onClose={onClose} />}
        />
      </SpaceBetween>
      <Table>
        <thead>
          <TR>
            <TH>순번</TH>
            <TH>분류</TH>
            <TH>이름</TH>
            <TH>소속</TH>
            <TH>비고</TH>
            <TH>등록일</TH>
            <TH>담당 환자</TH>
            <TH>관리</TH>
          </TR>
        </thead>
        <tbody>
          {!isLoading &&
            data.items.map((admin: ResearcherType, i: number) => {
              const {
                id,
                is_project_super,
                name,
                hospitalName,
                note,
                created_at,
                patients,
              } = admin;
              return (
                <TR key={id}>
                  <TD>{data.total - ((page - 1) * display + i)}</TD>
                  <TD>
                    {is_project_super === 1 ? "프로젝트관리자" : "연구원"}
                  </TD>
                  <TD>{name}</TD>
                  <TD>{hospitalName}</TD>
                  <TD>{note}</TD>
                  <TD>{getLocaleDate(created_at)}</TD>
                  <TD>{patients}</TD>
                  <TD>
                    <Modal
                      activator={(onOpen) => (
                        <BtnCorrection>
                          <Correction onClick={onOpen} />
                        </BtnCorrection>
                      )}
                      content={(onClose) => (
                        <ModalResearcher onClose={onClose} data={admin} />
                      )}
                    />
                    <BtnCorrection>
                      <Trash onClick={() => handleDelete(id, patients)} />
                    </BtnCorrection>
                  </TD>
                </TR>
              );
            })}
        </tbody>
      </Table>
      <Footer>
        <Pagination
          total={data?.total}
          display={display}
          page={page}
          onChange={(page: number) => setPage(page)}
        />
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 27px;
`;
const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  flex: 1;
`;
const BtnCorrection = styled.button`
  background-color: #fff;
  outline: none;
  border: none;
`;
const Footer = styled.footer`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;
export default Researcher;

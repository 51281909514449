import { ItemType, OnChangeType } from "types";

import { Input } from "components/atoms/input";

const Text = ({
  data,
  onChange,
  value,
  readOnly,
}: {
  data: ItemType;
  onChange: OnChangeType;
  value: any;
  readOnly?: boolean;
}) => {
  const { id, is_required } = data;
  return (
    <Input
      type="text"
      name={id + ""}
      onChange={onChange}
      value={value ?? ""}
      required={is_required === 1}
      readOnly={readOnly}
    />
  );
};

export default Text;

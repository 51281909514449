import { atom } from "recoil";
import SampleSearchType from "types/sampleSearch";

const sampleSearchStateInit = atom<SampleSearchType>({
  key: "sampleSearchStateInit",
  default: {
    page: 1,
    study: "",
    startDate: "",
    endDate: "",
  },
});

export default sampleSearchStateInit;

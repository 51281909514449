import instance from ".";

export const getHospitals = async (params: { projectId: string }) => {
  const result = await instance.get(`/hospitals`, { params });
  return result.data;
};

export const createHospital = async (data: {
  projectId: string;
  name: string;
  code: string;
}) => {
  const result = await instance.post(`/hospitals`, data);
  return result.data;
};

export const editHospital = async (data: {
  id: number;
  name: string;
  code: string;
}) => {
  const result = await instance.put(`/hospitals/${data.id}`, data);
  return result.data;
};

export const deleteHospital = async (id: number) => {
  const result = await instance.delete(`/hospitals/${id}`);
  return result.data;
};

import getPeriod from "./common/dateCalculator";

const periodOfUse20 = {
  id: 41,
  name: "period of use (20)",
  datas: [
    "start_date_1",
    "end_date_1",
    "start_date_2",
    "end_date_2",
    "start_date_3",
    "end_date_3",
    "start_date_4",
    "end_date_4",
    "start_date_5",
    "end_date_5",
    "start_date_6",
    "end_date_6",
    "start_date_7",
    "end_date_7",
    "start_date_8",
    "end_date_8",
    "start_date_9",
    "end_date_9",
    "start_date_10",
    "end_date_10",
    "start_date_11",
    "end_date_11",
    "start_date_12",
    "end_date_12",
    "start_date_13",
    "end_date_13",
    "start_date_14",
    "end_date_14",
    "start_date_15",
    "end_date_15",
    "start_date_16",
    "end_date_16",
    "start_date_17",
    "end_date_17",
    "start_date_18",
    "end_date_18",
    "start_date_19",
    "end_date_19",
    "start_date_20",
    "end_date_20",
  ],
  formula: ({
    start_date_1,
    start_date_2,
    start_date_3,
    start_date_4,
    start_date_5,
    start_date_6,
    start_date_7,
    start_date_8,
    start_date_9,
    start_date_10,
    start_date_11,
    start_date_12,
    start_date_13,
    start_date_14,
    start_date_15,
    start_date_16,
    start_date_17,
    start_date_18,
    start_date_19,
    start_date_20,
    end_date_1,
    end_date_2,
    end_date_3,
    end_date_4,
    end_date_5,
    end_date_6,
    end_date_7,
    end_date_8,
    end_date_9,
    end_date_10,
    end_date_11,
    end_date_12,
    end_date_13,
    end_date_14,
    end_date_15,
    end_date_16,
    end_date_17,
    end_date_18,
    end_date_19,
    end_date_20,
  }: any) => {
    if (!start_date_1) {
      return;
    }
    return (
      (getPeriod(start_date_1, end_date_1) || 0) +
      (getPeriod(start_date_2, end_date_2) || 0) +
      (getPeriod(start_date_3, end_date_3) || 0) +
      (getPeriod(start_date_4, end_date_4) || 0) +
      (getPeriod(start_date_5, end_date_5) || 0) +
      (getPeriod(start_date_6, end_date_6) || 0) +
      (getPeriod(start_date_7, end_date_7) || 0) +
      (getPeriod(start_date_8, end_date_8) || 0) +
      (getPeriod(start_date_9, end_date_9) || 0) +
      (getPeriod(start_date_10, end_date_10) || 0) +
      (getPeriod(start_date_11, end_date_11) || 0) +
      (getPeriod(start_date_12, end_date_12) || 0) +
      (getPeriod(start_date_13, end_date_13) || 0) +
      (getPeriod(start_date_14, end_date_14) || 0) +
      (getPeriod(start_date_15, end_date_15) || 0) +
      (getPeriod(start_date_16, end_date_16) || 0) +
      (getPeriod(start_date_17, end_date_17) || 0) +
      (getPeriod(start_date_18, end_date_18) || 0) +
      (getPeriod(start_date_19, end_date_19) || 0) +
      (getPeriod(start_date_20, end_date_20) || 0)
    );
  },
  help: `총 약제 사용일`,
};

export default periodOfUse20;

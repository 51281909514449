import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { getTokenPayload } from 'utils/user';

import sampleRequestTypes from 'datas/sampleRequestTypes';

import {
  getSampleRequestAndDetail,
  getSampleRequestItems,
  postSampleRequestAndDetail,
  putSampleRequestAndDetail,
  patchSampleRequestStatus,
  deleteSampleRequest
} from 'apis/sampleRequest';

import styled from 'styled-components';

import { SpaceBetween } from 'components/atoms/common';
import { BtnBlue, BtnPink, BtnSalmon } from 'components/atoms/button';

import SampleRequestWhere from './SampleRequestWhere';
import SampleRequestCountAndPatient from './SampleRequestCountAndPatient';


const SampleRequest = () => {
  const { isSuper, isProjectSuper, adminId } = getTokenPayload();
  const { sampleRequestId = '' } = useParams();
  const queryClient = useQueryClient();

  const { isLoading: isLoadingSampleRequestAndDetail, data: sampleRequestAndDetail } = useQuery(
    ['getSampleRequestAndDetail', sampleRequestId],
    () => getSampleRequestAndDetail(sampleRequestId)
  );

  const { isLoading: isLoadingSampleRequestItems, data: sampleRequestItems } = useQuery(
    ['getSampleRequestItems'],
    () => getSampleRequestItems()
  );

  const defaultState: any = {
    id: sampleRequestAndDetail?.id ?? null,
    study: sampleRequestAndDetail?.study ?? null,
    templateDefaultId: sampleRequestAndDetail?.template_default_id ?? null,
    sex: sampleRequestAndDetail?.sex ?? null,
    where: sampleRequestAndDetail?.where ?? {},
    count: sampleRequestAndDetail?.count ?? [],
    patientCount: sampleRequestAndDetail?.patient_count ?? null,
    noteTemp: sampleRequestAndDetail?.note_temp ?? null,
    noteRequest: sampleRequestAndDetail?.note_request ?? null,
    status: sampleRequestAndDetail?.status ?? 'TEMP',
    adminId: sampleRequestAndDetail?.admin_id ?? adminId,
    adminName: sampleRequestAndDetail?.admin_name ?? null,
    patients: sampleRequestAndDetail?.patients ?? {}
  };
  const [state, setState] = useState(defaultState);

  const navigate = useNavigate();

  const isTemp = state.status === 'TEMP';
  const isRequest = state.status === 'REQUEST';
  const isApproval = state.status === 'APPROVAL';
  const isReceive = state.status === 'RECEIVE';

  const isMine = state.adminId === adminId;
  const isAdmin = (isSuper || isProjectSuper) ? true : false;

  const handleSave = () => {
    if (validation()) {
      if (!state.id) {
        postSaveMutation.mutate();
      } else {
        putSaveMutation.mutate();
      }
    }
  };

  const handleRequest = () => {
    if (validation()) {
      if (!state.id) {
        postRequestMutation.mutate();
      } else {
        putRequestMutation.mutate();
      }
    }
  };

  const handleApproval = () => {
    if (validationPatients()) {
      patchStatusMutation.mutate({ id: state.id, status: 'APPROVAL', noteRequest: state.noteRequest, patients: state.patients });
    }
  };

  const handleReceive = () => {
    if (validationPatients()) {
      patchStatusMutation.mutate({ id: state.id, status: 'RECEIVE', patients: state.patients });
    }
  };

  const deleteTempMutation = useMutation(
    () => deleteSampleRequest(state.id),
    {
      onSuccess: () => showAlert({ defaultMessage: '삭제에 성공했습니다.', goList: true }),
      onError: (err: any) => showAlert({ err, defaultMessage: '삭제에 실패했습니다.' })
    }
  );

  const postSaveMutation = useMutation(
    () => postSampleRequestAndDetail({ ...state }),
    {
      onSuccess: (result) => {
        setState({ ...state, id: result.id });

        showAlert({ defaultMessage: getAlertMessage(result.status, true), refreshList: true });
      },
      onError: () => showAlert({ defaultMessage: getAlertMessage(state.status, false) })
    }
  );

  const putSaveMutation = useMutation(
    () => putSampleRequestAndDetail({ ...state }),
    {
      onSuccess: (result) => showAlert({ defaultMessage: getAlertMessage(result.status, true), refreshList: true, refreshDetail: true }),
      onError: (err: any) => showAlert({ err, defaultMessage: getAlertMessage(state.status, false) })
    }
  );

  const postRequestMutation = useMutation(
    () => postSampleRequestAndDetail({ ...state }),
    {
      onSuccess: (result) => {
        setState({ ...state, id: result.id });

        patchStatusMutation.mutate({ id: result.id, status: 'REQUEST', isPreview: true });
      },
      onError: () => showAlert({ defaultMessage: getAlertMessage('REQUEST', false) })
    }
  );

  const putRequestMutation = useMutation(
    () => putSampleRequestAndDetail({ ...state }),
    {
      onSuccess: (result) => patchStatusMutation.mutate({ id: result.id, status: 'REQUEST', isPreview: true }),
      onError: (err: any) => showAlert({ err, defaultMessage: getAlertMessage('REQUEST', false) })
    }
  );

  const patchStatusMutation = useMutation(
    ({ id, status, isPreview, noteRequest, patients }: { id: number; status: string; isPreview?: boolean; noteRequest?: string; patients?: any }) => patchSampleRequestStatus(id, status, isPreview, noteRequest, patients),
    {
      onSuccess: (result) => {
        if (result.isPreview) {
          let message = '';

          if (result.isPass) {
            message += `${getRequestTypeName('REQUEST')}하시겠습니까?\n\n(예상 환자 수)`;

            for (let i = 0; i < result.patientCount.length; i++) {
              message += `\n우선순위 ${i + 1}번 - ${result.patientCount[i]}명`;
            }

            if (window.confirm(message)) {
              patchStatusMutation.mutate({ id: result.id, status: result.status, isPreview: false });
            }
          } else {
            const sumPatientCount = result.patientCount.reduce((sum: number, value: number) => sum + value, 0);

            message += `환자 수는 최대 ${sumPatientCount}명까지 가능합니다.\n`;

            for (let i = 0; i < result.patientCount.length; i++) {
              message += `\n우선순위 ${i + 1}번 - 최대 ${result.patientCount[i]}명`;
            }

            showAlert({ defaultMessage: message, refreshList: true, refreshDetail: true });
          }
        } else {
          showAlert({ defaultMessage: getAlertMessage(result.status, true), refreshDetail: true, goList: true });
        }
      },
      onError: (err: any) => showAlert({ err, defaultMessage: '상태 변경에 실패했습니다.' })
    }
  );

  const validation = () => {
    if (!state.study) {
      alert('과제명을 입력하세요.');

      return false;
    } else if (!state.templateDefaultId) {
      alert('주차를 선택하세요.');

      return false;
    }

    const requiredItem = where.null.null.items.find((item: any) => item.is_required && !state.where[item.id]);

    if (requiredItem) {
      if (requiredItem.type === 'radio' || requiredItem.type === 'checkbox') {
        alert(`${requiredItem.name}(을)를 선택하세요.`);
      } else {
        alert(`${requiredItem.name}(을)를 입력하세요.`);
      }

      return false;
    }

    if (!state.patientCount) {
      alert('환자 수를 입력하세요.');

      return false;
    }

    if (state.count.length === 0) {
      alert('수량을 입력하세요.');

      return false;
    }

    let count;

    for (let i = 0; i < state.count.length; i++) {
      count = [];

      Object.keys(state.count[i]).forEach(templateDefaultId =>
        Object.keys(state.count[i][templateDefaultId]).forEach(itemId => count.push(itemId))
      );

      if (count.length === 0) {
        alert('수량을 입력하세요.');

        return false;
      }
    }

    return true;
  };

  const validationPatients = () => {
    if (isRequest && getPatientsSize() === 0) {
      alert('환자를 추가하세요.');

      return false;
    }

    const find = Object.keys(state.patients).find((templateDefaultId: string) =>
      state.patients[templateDefaultId].find((patient: any) => Object.keys(patient.items).length === 0)
    );

    if (find) {
      alert('환자 목록의 수량을 입력하세요.');

      return false;
    }

    return true;
  };

  const getAlertMessage = (status: string, isSuccess: boolean) => {
    return `${getRequestTypeName(status)}에 ${isSuccess ? '성공' : '실패'}했습니다.`;
  };

  const showAlert = ({
    err, defaultMessage, refreshList, refreshDetail, goList
  }: {
    err?: any; defaultMessage: string; refreshList?: boolean; refreshDetail?: boolean; goList?: boolean;
  }) => {
    if (err?.response.status === 400) {
      refreshList = true;
      refreshDetail = true;
    }

    if (err?.response.status === 404) {
      refreshList = true;
      goList = true;
    }

    if (refreshList || goList) {
      queryClient.invalidateQueries(['getSampleRequests']);

      if (goList) {
        navigate('/sample-management/list');
      }
    }

    if (refreshDetail) {
      queryClient.invalidateQueries(['getSampleRequestAndDetail']);
    }

    if (err?.response?.data.message) {
      alert(err.response.data.message);
    } else if (err?.response?.data.now_status) {
      alert(`이미 ${getRequestTypeName(err.response.data.now_status)}된 문서입니다.`);
    } else if (err?.response?.data.error) {
      const templateDefaultId = err.response.data.error.template_default_id;
      const patient = state.patients[templateDefaultId].find((patient: any) => patient.id === err.response.data.error.id);

      if (err.response.data.error.now_status) {
        alert(`고유번호 ${patient.code} 환자는 다른 ${getRequestTypeName(err.response.data.error.now_status)} 문서에 추가되어 있습니다.`);
      } else {
        const sections = count[templateDefaultId].sections;
        let item: any;
        let option: any;

        for (let i = 0; i < sections.length; i++) {
          item = count[templateDefaultId][sections[i].id].items.find((item: any) => item.id === err.response.data.error.item_id);

          if (item) {
            break;
          }
        }

        if (item) {
          for (let i = 0; i < sections.length; i++) {
            option = count[templateDefaultId][sections[i].id].options.find((option: any) => option.id === item.item_option_id);

            if (option) {
              break;
            }
          }
        }

        alert(`고유번호 ${patient.code} 환자의 ${count[templateDefaultId].name}${option?.value ? ` ${option.value}` : ''}${item?.name ? ` ${item.name}` : ''} 최대 수량은 ${err.response.data.error.item_value} 입니다.`);
      }
    } else {
      alert(defaultMessage);
    }
  }

  const getRequestTypeName = (status: string) => {
    return sampleRequestTypes.find(({ code }) => code === status)?.name ?? '';
  };

  const getThItems = (section: any) => {
    return section.items.filter((item: any) => item.type === 'unit');
  };

  const getThOptions = (section: any, thItems: any[]) => {
    return section.options.map((option: any) => ({
      ...option,
      colSpan: thItems.filter((item: any) => item.item_option_id === option.id).length,
      rowSpan: thItems.filter((item: any) => item.item_option_id === option.id && item.name === '').length + 1
    })).filter((option: any) => option.colSpan > 0);
  };

  const getPatientsSize = () => {
    const patientIds = new Set<number>();

    Object.keys(state.patients).forEach((templateDefaultId: string) =>
      state.patients[templateDefaultId].forEach((patient: any) => patientIds.add(patient.id))
    );

    return patientIds.size;
  };

  useEffect(() => {
    setState({ ...defaultState });
  }, [defaultState.id]);

  if (isLoadingSampleRequestAndDetail || isLoadingSampleRequestItems) {
    return <></>;
  }

  const { where, count } = sampleRequestItems;

  return <>
    <Items>
      <SampleRequestWhere where={where} state={state} setState={setState} isTemp={isTemp} isRequest={isRequest} isAdmin={isAdmin} getPatientsSize={getPatientsSize} />
      <SampleRequestCountAndPatient count={count} state={state} setState={setState}
        isTemp={isTemp} isRequest={isRequest} isApproval={isApproval} isReceive={isReceive}
        isAdmin={isAdmin}
        getThOptions={getThOptions} getThItems={getThItems} getPatientsSize={getPatientsSize} />
    </Items>
    <SpaceBetween>
      <div></div>
      <div>
        {isMine
          && <>
            {isTemp
              && <>
                {state.id !== null
                  && <BtnSalmon onClick={() => deleteTempMutation.mutate()}>삭제</BtnSalmon>
                }
                <BtnBlue onClick={handleSave}>{getRequestTypeName('TEMP')}</BtnBlue>
                <BtnPink onClick={handleRequest}>{getRequestTypeName('REQUEST')}</BtnPink>
              </>
            }
            {isRequest
              && <BtnSalmon onClick={() => patchStatusMutation.mutate({ id: state.id, status: 'TEMP' })}>취소</BtnSalmon>
            }
            {isApproval
              && <BtnPink onClick={handleReceive}>{getRequestTypeName('RECEIVE')}</BtnPink>
            }
          </>
        }
        {isAdmin
          && <>
            {isRequest
              && <>
                <BtnSalmon onClick={() => patchStatusMutation.mutate({ id: state.id, status: 'REJECT', noteRequest: state.noteRequest })}>{getRequestTypeName('REJECT')}</BtnSalmon>
                <BtnPink onClick={handleApproval}>{getRequestTypeName('APPROVAL')}</BtnPink>
              </>
            }
          </>
        }
      </div>
    </SpaceBetween>
  </>;
};

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dfe0e0;
  border-right: none;
`;

export default SampleRequest;

import InputItem from "components/molecules/InputItem";

import { ItemType } from "types";
import { Items } from "components/atoms/item";

const ItemView = ({ data }: { data: ItemType[] }) => {
  const sortedData = [...data].sort(
    (a: ItemType, b: ItemType) => a.position - b.position
  );
  const filteredData = sortedData.filter(
    ({ item_option_id }) => !item_option_id
  );

  // DEBUG 
  // console.log("components::organisms::item::View.tsx::itemView::filteredData: " + 
  //   JSON.stringify(filteredData,null,2));
 
  return (
    <Items>
      {filteredData.map((item: ItemType) => (
        <InputItem data={item} key={item.id} list={sortedData} view />
      ))}
    </Items>
  );
};

export default ItemView;

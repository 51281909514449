import { ChangeEvent, FormEvent } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { Items, Item, Name, Value } from "components/atoms/item";
import { SpaceBetween } from "components/atoms/common";
import { Textarea, Input, Select } from "components/atoms/input";
import { BtnPink, BtnWhite } from "components/atoms/button";
import { ModalContainer } from "components/atoms/modal";
import Option from "./Option";
import ItemTitle from "components/organisms/item/Title";
import Formula from "./Formula";
import MinMax from "./MinMax";

import { ItemType } from "types";
import types from "datas/itemTypes";
import useInputs from "hooks/useInputs";
import { deleteItem, editItem } from "apis/item";
import { useMutation, useQueryClient } from "react-query";
import { getItemOptions } from "apis/itemOption";

const ItemModalEdit = ({
  data,
  index,
  onClose,
}: {
  data: ItemType;
  index: string;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();
  const editMutation = useMutation(() => editItem(state), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getItems"]);
      onClose();
    },
  });
  const deleteMutation = useMutation((id: number) => deleteItem(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getItems"]);
      onClose();
    },
  });

  const { id, parent } = data;
  const [state, onChange] = useInputs({ ...data });
  const {
    name,
    type,
    position,
    size,
    unit,
    help,
    is_required,
    formula,
    formula_id,
  } = state;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    editMutation.mutate();
  };

  const handleRequired = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    onChange({ target: { name, value: checked } });
  };

  const handleDelete = async (itemId: number) => {
    const options = await getItemOptions({ itemId });
    const hasSubItems = options.some(
      ({ subItems }: any) => subItems.length > 0
    );
    if (hasSubItems) return alert("옵션의 하위질문을 먼저 삭제해 주세요.");
    if (!window.confirm("아이템을 삭제하시겠습니까?")) return;
    deleteMutation.mutate(itemId);
  };

  return (
    <ModalContainer large>
      <Form onSubmit={handleSubmit}>
        <SpaceBetween>
          <ItemTitle data={data} index={index} />
          <BtnWhite onClick={onClose}>닫기</BtnWhite>
        </SpaceBetween>
        <Container>
          <Items>
            {parent.name && (
              <>
                <Item size={2}>
                  <Name>상위 항목</Name>
                  <Value>
                    <ParentName to={`../item/${parent.id}`}>
                      {parent.name}
                    </ParentName>
                  </Value>
                </Item>
                <Item size={2}>
                  <Name>상위 항목 옵션</Name>
                  <Value>{parent.value}</Value>
                </Item>
              </>
            )}
            <Item size={1}>
              <Name>항목명</Name>
              <Value>
                <Input value={name} onChange={onChange} name="name" required />
              </Value>
            </Item>
            <Item size={2}>
              <Name>형식</Name>
              <Value>
                <Select value={type} name="type" onChange={onChange}>
                  {types.map(({ code, name }) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
                </Select>
              </Value>
            </Item>
            <Item size={2}>
              <Name>순서</Name>
              <Value>
                <Input
                  value={position}
                  name="position"
                  onChange={onChange}
                  small
                />
              </Value>
            </Item>
            {type !== "division" && (
              <>
                <Item size={2}>
                  <Name>필수 항목</Name>
                  <Value>
                    <label>
                      <input
                        type="checkbox"
                        name="is_required"
                        onChange={handleRequired}
                        checked={is_required}
                      />
                      필수 항목
                    </label>
                  </Value>
                </Item>
                <Item size={2}>
                  <Name>칸 너비</Name>
                  <Value>
                    {[1, 2, 3, 4].map((i) => (
                      <label key={i}>
                        <input
                          type="radio"
                          name="size"
                          value={i}
                          checked={i === +size}
                          onChange={onChange}
                        />{" "}
                        {Math.floor(100 / i)}%
                      </label>
                    ))}
                  </Value>
                </Item>
              </>
            )}
            <Item size={1}>
              <Name>도움말</Name>
              <Value>
                <Textarea value={help ?? ""} name="help" onChange={onChange} />
              </Value>
            </Item>
            {type === "unit" && (
              <Item size={1}>
                <Name>단위</Name>
                <Value>
                  <Input value={unit} onChange={onChange} name="unit" small />
                </Value>
              </Item>
            )}
            {["unit", "date"].includes(type) && (
              <MinMax onChange={onChange} state={state} />
            )}
            {["radio", "checkbox", "select"].includes(type) && (
              <Option itemId={id} />
            )}
            {type === "formula" && (
              <Formula
                formulaId={formula_id}
                formula={formula}
                onChange={onChange}
              />
            )}
          </Items>
        </Container>
        <SpaceBetween>
          <BtnWhite onClick={() => handleDelete(id)}>삭제하기</BtnWhite>
          <BtnPink type="submit" large>
            저장하기
          </BtnPink>
        </SpaceBetween>
      </Form>
    </ModalContainer>
  );
};

const Form = styled.form`
  margin-top: -25px;
  & + & {
    margin-top: 42px;
  }
`;
const Container = styled.div`
  margin-top: 8px;
  border-top: 1px solid #000;
`;
const ParentName = styled(Link)`
  text-decoration: underline;
`;

export default ItemModalEdit;

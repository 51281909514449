import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getTokenPayload } from "utils/user";
const AdminCheck = () => {
  const { isSuper, isProjectSuper } = getTokenPayload();
  const isResearcher = !isSuper && !isProjectSuper;
  useEffect(() => {
    if (isResearcher) alert("권한이 없습니다.");
  }, []);
  return isResearcher ? <Navigate to="/" /> : <Outlet />;
};

export default AdminCheck;

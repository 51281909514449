import { TemplateHeader } from "components/atoms/common";
import { Input } from "components/atoms/input";
import { Item, Items, Name, Value } from "components/atoms/item";
import { Option } from "components/molecules/InputItem/Checkbox";
import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { FirstResearchFlag } from "stores/studyFlag";
import {
  optionsPartType,
  options_part1,
  options_part2,
} from "./StudyConstants";

type dateData = {
  startDate: string;
  endDate: string;
};

const ModalFirstReaserch = ({
  dateData,
  setStartDate,
  setEndDate,
}: {
  dateData: dateData;
  setStartDate: (a: string) => void;
  setEndDate: (a: string) => void;
}) => {
  const { startDate, endDate } = dateData;
  let daydiff = moment(endDate).diff(moment(startDate), "days");

  const [checkA, setCheckA] = useState(options_part1);
  const [checkB, setCheckB] = useState(options_part2);
  const [researchFlag, setResearchFlag] = useRecoilState(FirstResearchFlag);

  const checkHandler = (
    e: any,
    myState: optionsPartType[],
    mySetState: Dispatch<React.SetStateAction<optionsPartType[]>>
  ) => {
    let newArr = [...myState];
    newArr[e.target.id] = {
      ...newArr[e.target.id],
      flag: !newArr[e.target.id].flag,
    };
    mySetState(newArr);
  };

  let inclusionFlag = checkA.every((x) => {
    return x.flag === true;
  });
  let exclusionFlag = checkB.every((x) => {
    return x.flag === true;
  });

  //console.log(inclusionFlag, exclusionFlag);
  // TRUE, TRUE => DO
  useEffect(() => {
    setResearchFlag(inclusionFlag && exclusionFlag);
  }, [inclusionFlag, exclusionFlag]);

  return (
    <>
      <TemplateHeader>Inclusion criteria</TemplateHeader>
      <Items>
        <Item>
          <Name>All must be YES</Name>
          <Value>
            <div>
              {options_part1.map(({ id, name }) => (
                <Option key={id}>
                  <label>
                    <input
                      type="checkbox"
                      id={checkA[id]?.id + ""}
                      checked={checkA[id]?.flag}
                      onChange={(e) => checkHandler(e, checkA, setCheckA)}
                    />
                    {name}
                  </label>
                </Option>
              ))}
            </div>
          </Value>
        </Item>
      </Items>
      <TemplateHeader>Exclusion criteria</TemplateHeader>
      <Items>
        <Item>
          <Name>All must be NO</Name>
          <Value>
            <div>
              {options_part2.map(({ id, name }) => (
                <Option key={id}>
                  <label>
                    <input
                      type="checkbox"
                      id={checkB[id]?.id + ""}
                      checked={checkB[id]?.flag}
                      onChange={(e) => checkHandler(e, checkB, setCheckB)}
                    />
                    {name}
                  </label>
                </Option>
              ))}
            </div>
          </Value>
        </Item>
      </Items>
      <TemplateHeader>치료 기간</TemplateHeader>
      <Items>
        <Item>
          <Name>치료 시작일</Name>
          <Value>
            <Input
              type="date"
              value={startDate}
              max={endDate}
              xSmall
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Value>
          <Name>관해 도달일</Name>
          <Value>
            <Input
              type="date"
              value={endDate}
              min={startDate}
              xSmall
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Value>
        </Item>
        <Item>
          <Name>관해까지의 기간</Name>
          <Value>{daydiff} 일</Value>
          <Name>반응군</Name>
          {1 <= daydiff && daydiff <= 7 ? (
            <Value>조기반응군 (1-7일)</Value>
          ) : 8 <= daydiff && daydiff <= 14 ? (
            <Value>중기반응군 (8-14일)</Value>
          ) : 15 <= daydiff && daydiff <= 28 ? (
            <Value>후기반응군 (15-28일)</Value>
          ) : (
            <Value>범위 오류</Value>
          )}
        </Item>
      </Items>
    </>
  );
};

export default ModalFirstReaserch;

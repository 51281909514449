import instance from ".";

export const getItems = async (params: {
  projectId?: string;
  sectionId?: string;
}) => {
  const result = await instance.get(`/items`, { params });
  return result.data;
};

export const getItem = async (itemId: string) => {
  const result = await instance.get(`/items/${itemId}`);
  return result.data;
};

export const createItem = async (data: {
  sectionId: string;
  optionId?: number;
  name: string;
  type: string;
  unit?: string;
}) => {
  const result = await instance.post(`/items`, data);
  return result.data;
};

export const editItem = async (data: any) => {
  const result = await instance.put(`/items/${data.id}`, data);
  return result.data;
};
export const deleteItem = async (id: number) => {
  try {
    const response = await instance.delete(`/items/${id}`);
    return response;
  } catch (err: any) {
    if (err.response) {
      alert(err.response.data.message);
    }
  }
};

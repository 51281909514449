const EENPEN = {
  id: 13,
  name: "EEN + PEN",
  datas: ["EEN", "PEN", "CDED"],
  formula: ({ EEN, PEN, CDED }: any) => {
    if (!EEN && !PEN && !CDED) {
      return;
    }
    return (parseInt(EEN) || 0) + (parseInt(PEN) || 0) + (parseInt(CDED) || 0);
  },
  help: "EEN ( ) 주 + PEN ( ) 주 + CDED ( ) 주",
};

export default EENPEN;

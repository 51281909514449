import getAge from "./common/getAge";

const onsetAge2 = {
  id: 30,
  name: "onsetAge2",
  datas: ["birthday", "onset_date"],
  formula: ({ birthday, onset_date }: any) => {
    if (!birthday || !onset_date) {
      return;
    }
    const result = getAge(birthday, onset_date);
    return result;
  },
  help: `만나이() = (((측정년도-출생년도)×12))+(측정월-출생월)+((측정일-출생일)÷30.4))/12)`,
};

export default onsetAge2;

import styled from "styled-components";

import { Item, Required, Name, Value } from "components/atoms/item";

import { OnChangeType, ItemType, ItemParentType } from "types";
import ItemHelp from "../ItemHelp";

import ItemValue from "./Value";

import { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getTokenPayload } from "utils/user";
import { BtnQuery, Red } from "components/atoms/query";
import Modal from "components/organisms/Modal";
import ModalQuery from "components/templates/Query/Modal";

const InputItem = ({
  data,
  onChange = () => { },
  values = {},
  size,
  list,
  view,
  readOnly,
}: {
  data: ItemType;
  list: ItemType[];
  onChange?: OnChangeType;
  values?: any;
  size?: number;
  view?: boolean;
  readOnly?: boolean;
}) => {
  // 각 input 컴포넌트에서 data의 id는 item의 id
  const {
    id,
    name,
    help,
    valueOptions,
    type,
    is_required,
    value,
    query_id,
    query_state,
  } = {
    ...data,
  };

  const subItemCondition = (parent: ItemParentType) =>
    parent.id === id &&
    (view
      ? valueOptions?.includes(parent.optionId)
      : values[id]?.includes(parent.optionId));

  const subItems = list?.filter(({ parent }) => subItemCondition(parent));

  //시작 20221212 오토쿼리 관련 추가 by 장희준
  const location = useLocation();
  const itemRef = useRef<HTMLDivElement>(null);
  const { isProjectSuper, projectId } = getTokenPayload();
  const { patientId, templateId, sectionId } = useParams();

  // 쿼리에서 최초접근시에만 해당item focus
  useEffect(() => {
    itemRef.current?.focus();
  }, []);
  //끝 20221212 오토쿼리 관련 추가 by 장희준

  // console.log(location.pathname);
  // console.log(location.pathname.indexOf("admin"));
  // console.log(location.pathname.indexOf("admin") < 0);

  return (
    <>
      <Item
        size={size ?? data.size ?? 1}
        ref={location.hash.substring(1) === "" + id ? itemRef : null}
        tabIndex={-1} /*20221212 오토쿼리 관련 추가 by 장희준*/
      >
        <Name
          division={type === "division"}
          isSection151={
            sectionId === "151" &&
            (name.includes("Start date") ||
              name.includes("End date") ||
              name.includes("Total daily dose") ||
              name.includes("용법"))
          }
        >
          {(() => {
            if (
              parseInt(projectId) === 674 &&
              location.pathname.indexOf("admin") < 0
            ) {
              return (
                <>
                  {!query_state || query_state === 3 ? (
                    <>{name}</>
                  ) : (
                    <Red>{name}</Red>
                  )}
                </>
              );
            } else {
              return <>{name}</>;
            }
          })()}
          {is_required === 1 && <Required>*</Required>}
          {help && <ItemHelp data={help} />}
          {(() => {
            if (parseInt(projectId) === 674) {
              return (
                <>
                  {(isProjectSuper !== 0 || query_state > 0) &&
                    location.pathname.indexOf("admin") < 0 && (
                      <Modal
                        activator={(onOpen) => (
                          <BtnQuery type="button" onClick={onOpen}>
                            Q
                          </BtnQuery>
                        )}
                        content={(onClose) => (
                          <ModalQuery
                            onClose={onClose}
                            data={{
                              id: query_id,
                              item_id: id,
                              patient_id: patientId,
                              template_id: templateId,
                            }}
                          />
                        )}
                      />
                    )}
                </>
              );
            }
          })()}
        </Name>
        {type !== "division" && (
          <Flex>
            <Value
              isSection151={
                sectionId === "151" &&
                (name.includes("Start date") ||
                  name.includes("End date") ||
                  name.includes("Total daily dose") ||
                  name.includes("용법"))
              }
            >
              <ItemValue
                data={data}
                values={values}
                onChange={onChange}
                view={view}
                readOnly={readOnly}
              />
            </Value>
            {subItems.length > 0 && (
              <SubItems>
                {subItems.map((item) => (
                  <InputItem
                    list={list}
                    data={item}
                    key={item.id}
                    onChange={onChange}
                    values={values}
                    view={view}
                    readOnly={readOnly}
                  />
                ))}
              </SubItems>
            )}
          </Flex>
        )}
      </Item>
    </>
  );
};

const Flex = styled.div<{ visibility?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const SubItems = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export default InputItem;

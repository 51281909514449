import instance from ".";

export const upload = async (file: File) => {
  const form = new FormData();
  form.append("file", file);
  const result = await instance.post(`/upload`, form);
  return result.data;
};

export const remove = async (url: any, id: number) => {
  try {
    const response = await instance.post(`/remove`, { url, itemId: id });
    // await instance.delete(`/upload/${id}/content`);
    // return response.data;
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
  }
};

export const uploadAttachment = async (file: File) => {
  console.log("uploadAttachment file:", file);
  const form = new FormData();
  form.append("file", file);
  const result = await instance.post(`/upload/attachments`, form);
  console.log("uploadAttachment result:", result);
  console.log("uploadAttachment result.data:", result.data);
  return { url: result.data, name: file.name };
};

export const removeAttachment = async (url: any) => {
  try {
    const response = await instance.post(`/remove/attachments`, { url });
    // await instance.delete(`/upload/${id}/content`);
    return response.data;
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
  }
};

import { atom } from "recoil";

export const FirstResearchFlag = atom<boolean>({
  key: "FirstResearch",
  default: false,
});

export const SRNSFlag = atom<boolean>({
  key: "SRNSFlag",
  default: false,
});

export const eGFR = atom<number>({
  key: "eGFR",
  default: 0,
});

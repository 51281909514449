import { getPatient } from "apis/patient";
import { createTemplate, editTemplate } from "apis/template";
import { BtnPink, BtnWhite } from "components/atoms/button";
import { SpaceBetween, TemplateHeader } from "components/atoms/common";
import { Input } from "components/atoms/input";
import { Item, Items, Name, Value } from "components/atoms/item";
import { ModalContainer } from "components/atoms/modal";
import { FormEvent, useState } from "react";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useParams } from "react-router";
import { TemplateType } from "types";
import { getLocaleDate } from "utils/time";
import { getTokenPayload } from "utils/user";

const ModalTemplate = ({
  data,
  onClose,
}: {
  data?: TemplateType;
  onClose: () => void;
}) => {
  const { projectId } = getTokenPayload();
  const queryClient = useQueryClient();
  const { patientId = "" } = useParams();
  const [scheduled_date, setScheduledDate] = useState(
    data ? getLocaleDate(data.scheduled_date) : ""
  );
  const [reporting_date, setReportingDate] = useState(
    data ? getLocaleDate(data.reporting_date) : ""
  );
  const [name, setName] = useState(data ? data.name : "");

  console.log("name", name);

  const form = {
    id: data?.id || 0,
    name,
    scheduled_date,
    reporting_date,
    patientId,
  };

  const { isLoading, data: patientData } = useQuery(["getPatient", patientId], () =>
    getPatient(patientId)
  );

  const { initial_diagnosis_date } = patientData;

  const mutation = useMutation(
    () => (data ? editTemplate(form) : createTemplate(form)),
    {
      onSuccess: () => {
        alert("저장 성공");
        queryClient.invalidateQueries(["getTemplates"]);
        onClose();
      },
    }
  );

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (mutation.isLoading || isLoading) return;

    const init_date = getLocaleDate(initial_diagnosis_date);
    console.log('reporting_date=' + reporting_date + ', projectId=' + projectId);
    console.log('initial_diagnosis_date=' + initial_diagnosis_date);
    console.log('init_date=' + init_date);
    console.log('결과1 init_date=' + (reporting_date < init_date));
    console.log('결과2 projectId =' + (projectId === 674));

    if (projectId === 674 && reporting_date !== "") {
      if (reporting_date < init_date) {
        alert('방문일이 환자 등록일 이전입니다.\n방문일을 다시 등록해 주세요.');
        return;
      }
    }

    if (window.confirm(`템플릿을 ${data ? "수정" : "추가"}하시겠습니까?`)) {
      mutation.mutate();
    }
  };

  return (
    <ModalContainer>
      <form onSubmit={handleSubmit}>
        <TemplateHeader>템플릿 {data ? "수정" : "추가"}</TemplateHeader>
        <Items>
          <Item>
            <Name>이름</Name>
            <Value>
              <Input
                onChange={(e) => setName(e.target.value)}
                value={name}
                required
              />
            </Value>
          </Item>
          <Item>
            <Name>방문예정일</Name>
            <Value>
              <Input
                type="date"
                value={scheduled_date}
                onChange={(e) => setScheduledDate(e.target.value)}
                max="2999-12-31"
                small
              />
            </Value>
          </Item>
          <Item>
            <Name>방문일</Name>
            <Value>
              <Input
                type="date"
                value={reporting_date}
                onChange={(e) => setReportingDate(e.target.value)}
                max="2999-12-31"
                small
              />
            </Value>
          </Item>
        </Items>
        <SpaceBetween>
          <BtnWhite onClick={onClose}>닫기</BtnWhite>
          <BtnPink type="submit">저장</BtnPink>
        </SpaceBetween>
      </form>
    </ModalContainer>
  );
};

export default ModalTemplate;

import styled from "styled-components";

import ItemView from "components/organisms/item/View";
import { TemplateHeader } from "components/atoms/common";
import ViewBasic from "./ViewBasic";
import PatientType from "types/patient";

const PatientView = ({ data }: { data: PatientType }) => {
  return (
    <>
      <Wrapper>
        <ViewBasic data={data} />
      </Wrapper>
      <Wrapper>
        <TemplateHeader>추가 정보</TemplateHeader>
        <ItemView data={data.items} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  & + & {
    margin-top: 30px;
  }
`;

export default PatientView;

import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router";
import styled from "styled-components";

import { getSections } from "apis/section";
import { getTemplateDefaults } from "apis/templateDefault";

import { BtnPink, BtnWhite } from "components/atoms/button";
import { SpaceBetween, TemplateHeader } from "components/atoms/common";
import { Item, Items, Value } from "components/atoms/item";
import { ModalContainer } from "components/atoms/modal";

const ModalOneToMany = ({
  type,
  data: defaultIds,
  onSubmit,
  onClose,
}: {
  type: string;
  data: number[];
  onSubmit: (sections: number[]) => void;
  onClose: () => void;
}) => {
  const [ids, setIds] = useState<number[]>(defaultIds);
  const { projectId = "" } = useParams();

  const queryParams = {
    template: {
      title: "템플릿 섹션",
      key: ["getSections", { projectId }],
      function: () => getSections({ projectId }),
    },
    type: {
      title: "환자 타입별 기본 템플릿",
      key: ["getTemplateDefaults", { projectId }],
      function: () => getTemplateDefaults({ projectId }),
    },
  }[type] ?? { key: "", function: () => {} };
  const { isLoading, data } = useQuery(queryParams.key, queryParams.function);

  const handleChange = (id: number) => {
    setIds((prev) =>
      prev.includes(id) ? prev.filter((e) => e !== id) : [...prev, +id]
    );
  };

  const handleSubmit = () => {
    onSubmit(ids);
  };

  if (isLoading) return <></>;

  return (
    <ModalContainer>
      <TemplateHeader>{queryParams.title} 관리</TemplateHeader>
      <Items>
        <Item>
          <Value>
            <Sections>
              {data.map(({ id, name }: { id: number; name: string }) => (
                <label key={id}>
                  <input
                    type="checkbox"
                    value={id}
                    checked={ids.includes(id)}
                    onChange={() => handleChange(id)}
                  />
                  {name}
                </label>
              ))}
            </Sections>
          </Value>
        </Item>
      </Items>
      <SpaceBetween>
        <BtnWhite onClick={onClose}>닫기</BtnWhite>
        <BtnPink onClick={handleSubmit}>저장</BtnPink>
      </SpaceBetween>
    </ModalContainer>
  );
};

const Sections = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export default ModalOneToMany;

import { Fragment, useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from 'react-query';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { getTokenPayload } from 'utils/user';

import { SampleStockSearchType } from 'types';
import HospitalType from 'types/hospital';

import sampleStockSearchStateInit from 'stores/sampleStockSearchState';

import axios from 'axios';

import { getSampleStocks, getSampleStocksExcel } from 'apis/sampleStock';
import { getHospitals } from 'apis/hospital';

import styled from 'styled-components';

import { SpaceBetween } from 'components/atoms/common';
import { Table } from 'components/atoms/table';
import { BtnBlue, BtnGray, BtnSalmon } from 'components/atoms/button';

import Pagination from 'components/organisms/Pagination';


const display = 15;

const SampleStockList = ({
  count
}: {
  count: any;
}) => {
  const { projectId } = getTokenPayload();

  const [sampleStockSearchState, setSampleStockSearchState] = useRecoilState<SampleStockSearchType>(sampleStockSearchStateInit);

  const params: any = {
    tab: sampleStockSearchState.tab,
    templateDefaultId: sampleStockSearchState.templateDefaultId || count.templates[0].id,
    keyword: sampleStockSearchState.keyword,
    isHold: sampleStockSearchState.isHold,
    hospitalId: sampleStockSearchState.hospitalId,
    page: sampleStockSearchState.page
  };

  const { isLoading: isLoadingSampleStocks, data: sampleStocks } = useQuery(
    ['getSampleStocks', params],
    () => getSampleStocks(params)
  );

  const { isLoading: isLoadingHospitals, data: hospitals } = useQuery(
    ['getHospitals', { projectId }],
    () => getHospitals({ projectId })
  );

  let location = useLocation();
  const locationObj = JSON.parse(JSON.stringify(location));

  const [searchParam, setSearchParam] = useSearchParams();
  const defaultState: any = {
    tab: searchParam.get('tab') ?? 'stock',
    templateDefaultId: parseInt(searchParam.get('templateDefaultId') ?? count.templates[0].id),
    keyword: searchParam.get('keyword') ?? '',
    isHold: searchParam.get('isHold') ?? '',
    hospitalId: searchParam.get('hospitalId') ?? '',
    page: parseInt(searchParam.get('page') ?? '1')
  };
  const [state, setState] = useState(defaultState);
  const [link, setLink] = useState('');
  const [isDone, setIsDone] = useState(true);

  const resetState: any = {
    tab: 'stock',
    templateDefaultId: count.templates[0].id,
    keyword: '',
    isHold: '',
    hospitalId: '',
    page: 1
  };

  const section = count[defaultState.templateDefaultId].sections[0];
  const thItems: any = count[defaultState.templateDefaultId][section.id].items.filter((item: any) => item.type === 'unit');
  const thOptions: any = count[defaultState.templateDefaultId][section.id].options.map((option: any) => ({
    ...option,
    colSpan: thItems.filter((item: any) => item.item_option_id === option.id).length,
    rowSpan: thItems.filter((item: any) => item.item_option_id === option.id && item.name === '').length + 1
  })).filter((option: any) => option.colSpan > 0);

  const onChange = ({ target }: { target: any; }) => {
    setState({ ...state, [target.name]: target.value });
  };

  const onKeyUp = ({ key }: { key: any; }) => {
    if (key === 'Enter') {
      handleSearch();
    }
  };

  const onClick = () => {
    const templateDefaultName = count[sampleStockSearchState.templateDefaultId || count.templates[0].id].name;

    if (!window.confirm(`해당 주차(${templateDefaultName}) 데이터를 다운로드 하시겠습니까?`)) {
      return;
    }

    setLink('');
    setIsDone(false);

    getExcelMutation.mutate();
  };

  const handleSearch = () => {
    setSearchParam({ ...state, page: 1 });
    setSampleStockSearchState({ ...state, page: 1 });
    setState({ ...state, page: 1 });
  };

  const handleReset = () => {
    setSearchParam(resetState);
    setSampleStockSearchState(resetState);
    setState(resetState);
  };

  const onChangePagination = (page: number) => {
    setSearchParam({ ...sampleStockSearchState, page: page + '' });
    setSampleStockSearchState({ ...sampleStockSearchState, page: page });
    setState({ ...sampleStockSearchState, page: page });
  };

  const getExcelMutation = useMutation(
    () => getSampleStocksExcel({
      templateDefaultId: sampleStockSearchState.templateDefaultId || count.templates[0].id
    }),
    {
      onSuccess: (result) => setLink(result),
      onError: (err: any) => alert('다운로드에 실패하였습니다.')
    }
  );

  useEffect(() => {
    if (!locationObj.search) {
      setSampleStockSearchState(resetState);
      setState(resetState);
    }
  }, [locationObj.search]);

  useEffect(() => {
    setSampleStockSearchState({ ...defaultState });
    setState({ ...defaultState });
  }, [
    defaultState.tab,
    defaultState.templateDefaultId,
    defaultState.keyword,
    defaultState.isHold,
    defaultState.hospitalId,
    defaultState.page
  ]);

  const btnExcel = useRef<HTMLAnchorElement>(null);
  const intervalId = useRef<string | NodeJS.Timer>('');

  useEffect(() => {
    if (link) {
      intervalId.current = setInterval(() => {
        checkDownloadAvailable();
      }, 2000);

      return () => {
        clearInterval(intervalId.current);
      };
    }
  }, [link]);

  const checkDownloadAvailable = async () => {
    try {
      await axios.get(link);
      setIsDone(true);
    } catch (e) {}
  };

  useEffect(() => {
    if (isDone) {
      if (link) {
        btnExcel.current?.click();

        setLink('');
      }

      clearInterval(intervalId.current);
    }
  }, [isDone]);

  if (isLoadingSampleStocks || isLoadingHospitals) {
    return <></>;
  }

  const { total, stocks } = sampleStocks;

  return <Container>
    <SearchTable>
      <tbody>
        <tr>
          <SearchTH>주차</SearchTH>
          <SearchTD width="100px" left>
            <Select name="templateDefaultId" value={state.templateDefaultId} onChange={onChange}>
              {count.templates.map((template: any) =>
                <option key={`key-stock-template-${template.id}`} value={template.id}>{template.name}</option>
              )}
            </Select>
          </SearchTD>
          <SearchTH width="150px">이름/고유번호</SearchTH>
          <SearchTD width="241px" left>
            <InputText name="keyword" value={state.keyword} onChange={onChange} onKeyUp={onKeyUp} />
          </SearchTD>
          <SearchTD float="right">
            <BtnBlue small onClick={handleSearch}>Search</BtnBlue>
            <LeftMargin />
            <BtnSalmon small onClick={handleReset}>Reset</BtnSalmon>
          </SearchTD>
        </tr>
      </tbody>
    </SearchTable>
    <SearchTable width="591px">
      <tbody>
        <tr>
          <SearchTH>잠김여부</SearchTH>
          <SearchTD width="100px" left>
            <Select name="isHold" value={state.isHold} onChange={onChange}>
              <option value="">-</option>
              <option value="Y">예</option>
              <option value="N">아니오</option>
            </Select>
          </SearchTD>
          <SearchTH width="150px">병원</SearchTH>
          <SearchTD left>
            <Select name="hospitalId" value={state.hospitalId} onChange={onChange}>
              <option value="">병원 전체</option>
              {hospitals.map((hospital: HospitalType) => (
                <option key={hospital.id} value={hospital.id}>
                  {hospital.name}
                </option>
              ))}
            </Select>
          </SearchTD>
        </tr>
      </tbody>
    </SearchTable>
    <SearchTable>
      <tbody>
        <tr>
          <SearchTD float="right">
            {isDone
              ? <BtnBlue small onClick={onClick}>Excel</BtnBlue>
              : <BtnGray small disabled>생성 중</BtnGray>
            }
            <a href={link} ref={btnExcel} download target="_self" style={{ display: "none" }} />
          </SearchTD>
        </tr>
      </tbody>
    </SearchTable>
    <SpaceBetween>
      <Title>총 {total}건</Title>
    </SpaceBetween>
    <Table>
      <thead>
        <TR>
          <TH rowSpan={2} width="40px" borderLeft={false}>순번</TH>
          <TH rowSpan={2} width="120px">이름</TH>
          <TH rowSpan={2} width="100px">고유번호</TH>
          <TH rowSpan={2} width="180px">병원</TH>
          {thOptions.map((option: any, index: number) =>
            <TH key={`key-stock-thead-group-${index}`} colSpan={option.colSpan} rowSpan={option.rowSpan}>{option.value}</TH>
          )}
        </TR>
        <TR>
          {thItems.filter((item: any) => item.name !== '').map((item: any, index: number) =>
            <TH key={`key-stock-thead-${index}`} borderTop={false}>{item.name}</TH>
          )}
        </TR>
      </thead>
      <tbody>
        {stocks.map((stock: any, rowIndex: number) =>
          <TR key={`key-stock-tbody-${rowIndex}`}>
            <TD borderLeft={false}>{total - ((state.page - 1) * display + rowIndex)}</TD>
            <TD>
              <Link to={`../stock/${stock.id}/template/${defaultState.templateDefaultId}`}>
                <Underline>{stock.name}</Underline>
              </Link>
            </TD>
            <TD>{stock.code}</TD>
            <TD>{stock.hospital_name}</TD>
            {thItems.map((thItem: any, index: number) => {
              const find = stock.items.find((item: any) => item.id === thItem.id);

              return <Fragment key={`key-stock-tbody-${rowIndex}-${index}`}>
                {find
                  && <TD title={find.origin_value}>
                    <span style={{ color: find.origin_value > 0 ? '' :  '#989899' }}>{find.value}</span>
                    {find.hold_value > 0
                      && <Hold>({find.hold_value})</Hold>
                    }
                  </TD>
                }
                {!find
                  && <TD style={{ color: '#989899' }}>-</TD>
                }
              </Fragment>;
            })}
          </TR>
        )}
      </tbody>
    </Table>
    <Footer>
      <Pagination total={total} display={display} page={state.page} onChange={onChangePagination} />
    </Footer>
  </Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 10px;
`;

const SearchTable = styled.table<{
  width?: string;
}>`
  width: ${({ width }) => width ?? '100%'};
  border-collapse: collapse;
`;

const SearchTH = styled.th<{
  width?: string;
}>`
  font-style: Arial;
  text-align: center;
  color: #0043b7;
  height: 40px;
  font-size: 14px;
  width: ${({ width }) => width ?? '100px'};
`;

const SearchTD = styled.td<{
  float?: string;
  width?: string;
  left?: boolean;
}>`
  font-style: Arial;
  font-weight: 400;
  background: white;
  font-size: 12px;
  height: 40px;
  float: ${({ float }) => float};
  width: ${({ width }) => width};
  text-align: ${({ left = false }) => (left ? 'left' : 'center')};
`;

const InputText = styled.input`
  padding: 10px;
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  width: 100%;
  height: 30px;
`;

const Select = styled.select`
  width: 100%;
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  height: 30px;
`;

const LeftMargin = styled.span`
  margin-left: 10px;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  flex: 1;
  margin-left: 10px;
`;

const TR = styled.tr`
  border-bottom: 1px solid #dfe0e0;
  &:hover { background: #f9f9f8; }
`;

const TH = styled.th<{
  width?: string;
  borderTop?: boolean;
  borderLeft?: boolean;
}>`
  text-align: center;
  color: #989899;
  background: #fafafc;
  height: 45px;
  font-size: 14px;
  width: ${({ width }) => width};
  border-top: ${({ borderTop = true }) => borderTop && '1px solid #000000'};
  border-left: ${({ borderLeft = true }) => borderLeft && '1px solid #dfe0e0'};
`;

const TD = styled.td<{
  borderLeft?: boolean;
}>`
  font-weight: 400;
  font-size: 14px;
  height: 60px;
  text-align: center;
  border-left: ${({ borderLeft = true }) => borderLeft && '1px solid #dfe0e0'};
  &:hover { background: #f3f3f1; }
`;

const Underline = styled.span`
  text-decoration: underline;
`;

const Hold = styled.span`
  margin-left: 2px;
  color: #ff0000;
`;

const Footer = styled.footer`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;

export default SampleStockList;

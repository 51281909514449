import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import styled from "styled-components";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  data: number[];
  labels: string[];
}
const BarChart = ({ data, labels }: Props) => {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "right" as const,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
  };
  const chartData = {
    labels,
    datasets: [
      {
        backgroundColor: ["#0043B7", "#FF4090", "#69D49A", "#5792F5"],
        borderColor: ["#0043B7", "#FF4090", "#69D49A", "#5792F5"],
        data,
      },
    ],
  };

  const total = data.reduce((a, b) => a + b, 0);
  const percent = data.map((e) => Math.floor((e / total) * 100 || 0) + "%");
  const dataset = chartData.datasets[0];
  return (
    <Container>
      <Wrapper>
        <Bar options={options} data={chartData} />
      </Wrapper>
      <SubContainer>
        {data.map((item, i) => {
          return (
            <SubContent key={i}>
              <Percent>
                {labels[i]}&nbsp;
                {dataset.data[i]}명({percent[i]})
              </Percent>
            </SubContent>
          );
        })}
      </SubContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  flex: 1;
`;
const Wrapper = styled.div`
  flex: 1;
`;
const SubContainer = styled.div`
  margin-left: 15px;
`;
const SubContent = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
`;
const Percent = styled.p``;

export default BarChart;

import instance from ".";

export const getTemplateDefaults = async (params: { projectId: string }) => {
  const result = await instance({
    method: "GET",
    url: `/template-defaults`,
    params,
  });
  return result.data;
};

export const createTemplateDefault = async (data: {
  projectId: string;
  name: string;
  day_after: number;
}) => {
  const result = await instance({
    method: "POST",
    url: `/template-defaults`,
    data,
  });
  return result.data;
};

export const editTemplateDefault = async (data: {
  id: number;
  name: string;
  day_after: number;
}) => {
  const result = await instance({
    method: "PUT",
    url: `/template-defaults/${data.id}`,
    data,
  });
  return result.data;
};

export const deleteTemplateDefault = async (id: number) => {
  const result = await instance({
    method: "DELETE",
    url: `/template-defaults/${id}`,
  });
  return result.data;
};

export const getTemplateDefaultSection = async (id: number) => {
  const result = await instance({
    method: "GET",
    url: `/template-defaults/${id}/sections`,
  });
  return result.data;
};

export const editTemplateDefaultSection = async (
  id: number,
  sections: number[]
) => {
  const result = await instance({
    method: "PUT",
    url: `/template-defaults/${id}/sections`,
    data: { sections },
  });
  return result.data;
};

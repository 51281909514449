import instance from '.';


// 재고 목록.
export const getSampleStocks = async (params: any) => {
  try {
    const result = await instance.get('/sample-stock', { params });
    return result.data;
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
  }
};

// 재고 상세.
export const getSampleStock = async (id: string, params: any) => {
  if (!id) return;
  try {
    const result = await instance.get(`/sample-stock/${id}`, { params });
    return result.data;
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
  }
};

export const getSampleStocksExcel = async (params: any) => {
  const result = await instance.get('/sample-stock/excel', { params });
  return result.data;
};

import instance from ".";

export const getProjects = async (params?: { page: number; query: string }) => {
  try {
    const result = await instance.get("/projects", { params });
    return result.data;
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
  }
};

export const getProject = async ({ projectId }: { projectId: string }) => {
  const result = await instance.get(`/projects/${projectId}`);
  return result.data;
};

export const createProject = async (data: { name: string }) => {
  const result = await instance.post(`/projects`, data);
  return result.data;
};

export const editProject = async (data: { id: number; name: string }) => {
  const result = await instance.put(`/projects/${data.id}`, data);
  return result.data;
};

export const deleteProject = async (id: number) => {
  try {
    const response = await instance.delete(`/projects/${id}`);
    if (response.status === 200) {
      alert("프로젝트를 삭제하였습니다.");
    }
    return response;
  } catch (err: any) {
    if (err.response) {
      alert(err.response.data.message);
    }
  }
};

export const getProjectItems = async ({ projectId }: { projectId: string }) => {
  const result = await instance.get(`/projects/${projectId}/items`);
  return result.data;
};

export const getProjectStatistics = async ({
  projectId,
  startDate,
  endDate,
}: {
  projectId: string;
  startDate?: string;
  endDate?: string;
}) => {
  const result = await instance.get(`/projects/${projectId}/statistics`, {
    params: { startDate, endDate },
  });
  return result.data;
};

export const createProjectExcel = async ({
  projectId,
  templateDefaultId,
  type,
  hospitalId,
}: {
  projectId: string;
  templateDefaultId: number;
  type: string;
  hospitalId: number;
}) => {
  const result = await instance.post(`/projects/${projectId}/excel`, {
    templateDefaultId,
    type,
    hospitalId,
  });
  return result.data;
};

import styled from "styled-components";

import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg";

const BtnLeft = () => {
  return (
    <Btn>
      <ArrowLeft />
    </Btn>
  );
};

const Btn = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
`;

export default BtnLeft;

import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import styled from "styled-components";

import { getProject } from "apis/project";
import useInputs from "hooks/useInputs";

import { PageLayout, PageContainer } from "components/atoms/page";

import BtnLeft from "components/molecules/BtnLeft";
import { getMyInfo } from "apis/user";
import { useEffect, useState } from "react";
import { TemplateHeader, SpaceBetween } from "components/atoms/common";
import { Items, Item, Name, Required } from "components/atoms/item";
import { Input, Textarea } from "components/atoms/input";
import File from "components/molecules/InputItem/Attachment";
import { ChangeEvent, useRef, FormEvent } from "react";
import { BtnBlue } from "components/atoms/button";
import { getTokenPayload } from "utils/user";
import { BoardType } from "types";
import { createBoard, editBoard } from "apis/board";
import { uploadAttachment } from "apis/upload";

const BoardForm = ({ data }: { data: BoardType }) => {
  const { isSuper, isProjectSuper, adminId } = getTokenPayload();
  const isResearcher = !isSuper && !isProjectSuper;
  console.log("isResearcher:", isResearcher);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId = "", boardId = "" } = useParams();
  console.log("boardId:", boardId);

  const { isLoading: isLoadingMyInfo, data: myInfo } = useQuery(
    ["getMyInfo"],
    getMyInfo
  );

  const defaultState = data ?? {
    admin_id: adminId,
    title: "",
    content: "",
    attachments: [],
  };

  const [state, setState] = useState(defaultState);

  console.log("state: ", state);

  const [content, setContent] = useState("");

  const contentInput = useRef<any>();

  const onChangeInputText = (e: any) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const action = data
    ? (values: BoardType) => editBoard(values)
    : (values: BoardType) => createBoard(values);

  const mutation = useMutation(action, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getBoard"]);
      navigate(`/boards`);
    },
  });

  const handleSubmit = (e: FormEvent) => {
    if (window.confirm("저장하시겠습니까?")) {
      e.preventDefault();
      mutation.mutate({ ...state, content });
    } else {
      e.preventDefault();
      return;
    }
  };

  const handleImageDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const result = await uploadAttachment(file);
      if (result) {
        const img = `<img src="${result.url}" alt="${file.name}" />`;
        document.execCommand("insertHTML", false, img);
      }
    }
  };

  const handlePaste = async (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        const file = item.getAsFile();
        if (file) {
          const result = await uploadAttachment(file);
          if (result) {
            const img = `<img src="${result.url}" alt="${file.name}" />`;
            document.execCommand("insertHTML", false, img);
          }
        }
      }
    }
  };

  const isCurrentUserAuthor = () => {
    if (!isResearcher || state.admin_id === adminId) return true;
    else return false;
  };

  if (isLoadingMyInfo) return null;

  return (
    <PageLayout>
      <Title>
        <Link to={`/boards`}>
          <BtnLeft />
        </Link>
        돌아가기
      </Title>
      <PageContainer>
        <TemplateHeader>
          {location.pathname.includes("new") ? "새 글 작성" : "글 수정하기"}
        </TemplateHeader>
        <form onSubmit={handleSubmit}>
          <Items>
            <Item size={1}>
              <Name>
                제목<Required>*</Required>
              </Name>
              <Value>
                <Input
                  style={{ height: "35px" }}
                  name="title"
                  value={state.title}
                  onChange={onChangeInputText}
                  required
                />
              </Value>
            </Item>
            <Item size={1}>
              <Name>내용</Name>
              <Value>
                {/* <Textarea
                  rows={15}
                  name="content"
                  value={state.content}
                  onChange={onChangeInputText}
                /> */}
                <div
                  dangerouslySetInnerHTML={{ __html: state.content }}
                  contentEditable={true}
                  suppressContentEditableWarning={true}
                  onBlur={(t) => setContent(t.currentTarget.innerHTML)}
                  onDrop={handleImageDrop}
                  onDragOver={(e) => e.preventDefault()}
                  onPaste={handlePaste}
                  ref={contentInput}
                  style={{
                    minWidth: "100%",
                    minHeight: "300px",
                    padding: "15px",
                    border: "1px solid #dfe0e0",
                  }}
                ></div>
              </Value>
            </Item>
            <Item size={1}>
              <Name>첨부파일</Name>
              <Value>
                <File
                  state={state}
                  setState={setState}
                  isCurrentUserAuthor={isCurrentUserAuthor()}
                />
              </Value>
            </Item>
          </Items>
          <SpaceBetween>
            <div></div>
            <BtnBlue type="submit">저장</BtnBlue>
          </SpaceBetween>
        </form>
      </PageContainer>
    </PageLayout>
  );
};

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  align-items: center;
  flex: 1;
  svg {
    margin-right: 10px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #989899;
    margin-left: 10px;
  }
`;

const InputHide = styled.input`
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  outline: 0;
  opacity: 0;
`;

const A = styled.a`
  margin-left: 10px;
`;

const Deletebutton = styled.button`
  height: 33px;
  width: 75px;
  background-color: rgb(0, 67, 183);
  color: #ffffff;
  padding: 7px 10px;
  font-size: 12px;
  line-height: 17px;
  font-style: Arial;
  border: none;
`;

const Value = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  flex: 1;
  font-size: 14px;
  border-left: 1px solid #dfe0e0;
  position: relative;
  white-space: pre-wrap;
`;

export default BoardForm;

import { TemplateType } from "types";
import PatientType from "types/patient";
import getAge from "./common/getAge";

const age = {
  id: 1,
  name: "age",
  datas: [],
  formula: (
    {}: any,
    { birthday }: PatientType,
    { reporting_date }: TemplateType
  ) => {
    if (!birthday || !reporting_date) {
      return;
    }
    const result = getAge(birthday, reporting_date);
    return result !== 0 ? result : 0;
  },
  help: `만나이(개월) = ((측정년도-출생년도)×12))+(측정월-출생월)+((측정일-출생일)÷30.4))`,
};

export default age;

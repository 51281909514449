import { Fragment } from 'react';

import sampleRequestTypes from 'datas/sampleRequestTypes';

import styled from 'styled-components';

import { Textarea } from 'components/atoms/input';
import { Required } from 'components/atoms/item';


const RenderItemValue = ({
  state, item, section,
  isTemp,
  onChange, onClick
}: {
  state: any; item: any; section: any;
  isTemp: boolean;
  onChange: any; onClick: any;
}) => {
  if (item.type === 'radio') {
    return <RenderItemRadio state={state} item={item} section={section} isTemp={isTemp} onChange={onChange} onClick={onClick} />
  } else if (item.type === 'formula' || item.type === 'unit') {
    return <RenderItemMinMax state={state} item={item} isTemp={isTemp} onChange={onChange} />
  } else if (item.type === 'checkbox') {
    return <RenderItemCheckbox state={state} item={item} isTemp={isTemp} section={section} onChange={onChange} onClick={onClick} />
  } else {
    return <RenderItemText state={state} item={item} isTemp={isTemp} onChange={onChange} />
  }
};

const RenderItemRadio = ({
  state, item, section,
  isTemp,
  onChange, onClick
}: {
  state: any; item: any; section: any; isTemp: boolean;
  onChange: any; onClick: any;
}) => {
  return (isTemp || state.where[item.id])
    && <Item>
      <Name>{item.name}{item.is_required === 1 && <Required>*</Required>}</Name>
      <ValueDown>
        {isTemp
          ? section.options.filter((option: any) => option.item_id === item.id).map((option: any) =>
            <Value key={`key-request-where-item-${item.id}-${option.id}`}>
              <input type="radio" name={`where-item-${item.id}`} value={option.id} checked={state.where[item.id]?.[0] == option.id || false} onChange={onChange} onClick={onClick} /><Span>{option.value}</Span>
            </Value>
          )
          : <Value><Span>{section.options.find((option: any) => option.id === state.where[item.id][0]).value}</Span></Value>
        }
        {section.items.filter((whereItem: any) => whereItem.item_option_id && state.where[item.id]?.[0] == whereItem.item_option_id).map((item: any) =>
          <RenderItemValue key={`key-request-where-item-${item.id}`} state={state} item={item} section={section} isTemp={isTemp} onChange={onChange} onClick={onClick} />
        )}
      </ValueDown>
    </Item>;
};

const RenderItemMinMax = ({
  state, item,
  isTemp,
  onChange
}: {
  state: any; item: any;
  isTemp: boolean;
  onChange: any;
}) => {
  return (isTemp || state.where[item.id])
    && <Item>
      <Name>{item.name}{item.is_required === 1 && <Required>*</Required>}</Name>
      <Value>
        {(isTemp || state.where[item.id].min != null)
          && <>
            {isTemp
              ? <><Input type="number" name={`where-item-${item.id}-min`} value={state.where[item.id]?.min ?? ''} onChange={onChange} width="80px" /><Span>{item.unit} 이상</Span></>
              : <Span>{state.where[item.id].min} {item.unit} 이상</Span>
            }
          </>
        }
        {(isTemp || state.where[item.id].max != null)
          && <>
            {isTemp
              ? <><Input type="number" name={`where-item-${item.id}-max`} value={state.where[item.id]?.max ?? ''} onChange={onChange} width="80px" /><Span>{item.unit} 이하</Span></>
              : <Span>{state.where[item.id].max} {item.unit} 이하</Span>
            }
          </>
        }
      </Value>
    </Item>;
};

const RenderItemCheckbox = ({
  state, item, section,
  isTemp,
  onChange, onClick
}: {
  state: any; item: any; section: any; isTemp: boolean;
  onChange: any; onClick: any;
}) => {
  return (isTemp || state.where[item.id])
    && <Item>
      <Name>{item.name}{item.is_required === 1 && <Required>*</Required>}</Name>
      <ValueDown>
        {section.options.filter((option: any) => isTemp ? option.item_id === item.id : state.where[item.id].includes(option.id)).map((option: any) =>
          <Value key={`key-request-where-item-${item.id}-${option.id}`}>
            <Label>
              {isTemp
                && <input type="checkbox" name={`where-item-${item.id}`} value={option.id} checked={state.where[item.id]?.find((value: any) => value == option.id) || false} onChange={onChange} />
              }
              <Span>{option.value}</Span>
            </Label>
          </Value>
        )}
        {section.items.filter((whereItem: any) => whereItem.item_option_id && state.where[item.id]?.find((value: any) => value == whereItem.item_option_id)).map((item: any) =>
          <RenderItemValue key={`key-request-where-item-${item.id}`} state={state} item={item} section={section} isTemp={isTemp} onChange={onChange} onClick={onClick} />
        )}
      </ValueDown>
    </Item>;
};

const RenderItemText = ({
  state, item,
  isTemp,
  onChange
}: {
  state: any; item: any; isTemp: boolean;
  onChange: any;
}) => {
  return (isTemp || state.where[item.id])
    && <Item>
      <Name>{item.name}{item.is_required === 1 && <Required>*</Required>}</Name>
      <Value>
        {isTemp
          ? <Input type="text" name={`where-item-${item.id}`} value={state.where[item.id] || ''} onChange={onChange} />
          : <Span>{state.where[item.id]}</Span>
        }
      </Value>
    </Item>;
};

const SampleRequestFormWhere = ({
  where, state, setState,
  isTemp, isRequest,
  isAdmin,
  getPatientsSize
}: {
  where: any; state: any; setState: any;
  isTemp: boolean; isRequest: boolean;
  isAdmin: boolean;
  getPatientsSize: any;
}) => {
  const deleteState = (key: string) => {
    delete state.where[key];

    setState({ ...state });
  };

  const deleteStateDown = (item: any) => {
    if (!item) {
      return;
    }

    deleteState(item.id);

    const finds = where[item.template_default_id][item.section_id].options.filter((option: any) => option.item_id === item.id);

    finds.forEach((find: any) => {
      deleteStateDown(findItemByOptionId({
        templateDefaultId: item.template_default_id,
        sectionId: item.section_id,
        id: find.id
      }));
    });
  };

  const findItemByOptionId = ({
    templateDefaultId,
    sectionId,
    id
  }: {
    templateDefaultId?: number;
    sectionId?: number;
    id: number;
  }) => {
    if (templateDefaultId && sectionId) {
      return where[templateDefaultId][sectionId].items.find((item: any) => item.item_option_id === id);
    } else {
      const templateDefaultIds = Object.keys(where).filter(key => key !== 'templates');
      let find: any;

      for (let i = 0; i < templateDefaultIds.length; i++) {
        const sectionIds = Object.keys(where[templateDefaultIds[i]]).filter(key => key !== 'sections' && key !== 'name');

        for (let j = 0; j < sectionIds.length; j++) {
          find = where[templateDefaultIds[i]][sectionIds[j]].items.find((item: any) => item.item_option_id === id);

          if (find) {
            return find;
          }
        }
      }
    }

    return null;
  }

  const onChange = ({ target }: { target: any; }) => {
    if (target.name === 'study') {
      state.study = target.value || null;
    } else if (target.name === 'template-default-id') {
      const resetWhere: any = {};

      where.null.null.items.forEach((item: any) => {
        if (state.where[item.id]) {
          resetWhere[item.id] = state.where[item.id];

          delete state.where[item.id];
        }
      });

      if (Object.keys(state.where).length > 0) {
        if (!window.confirm('주차를 변경하실 경우 입력하신 조건 데이터가 모두 초기화됩니다.\n변경하시겠습니까?')) {
          return;
        }
      }

      state.templateDefaultId = target.value || null;
      state.where = resetWhere;
    } else if (target.name === 'sex') {
      state.sex = target.value || null;
    } else if (target.name === 'note-temp') {
      state.noteTemp = target.value || null;
    } else if (target.name === 'note-request') {
      state.noteRequest = target.value || null;
    } else if (target.name === 'patient-count') {
      if (Number(target.value) > 0) {
        state.patientCount = (target.value.includes('.') ? target.value.replace('.', '') : target.value);
      } else {
        state.patientCount = null;
      }
    } else {
      let key = target.name.replace('where-item-', '');

      if (target.type === 'radio') {
        if (state.where[key]) {
          deleteStateDown(findItemByOptionId({ id: Number(state.where[key][0]) }));
        }

        state.where[key] = [target.value];
      } else if (target.type === 'number') {
        const type = key.substring(key.indexOf('-') + 1);
        key = key.replace('-min', '').replace('-max', '');

        if (target.value) {
          if (state.where[key]) {
            state.where[key][type] = target.value;
          } else {
            state.where[key] = { [type]: target.value };
          }
        } else {
          delete state.where[key][type];

          if (Object.keys(state.where[key]).length === 0) {
            deleteState(key);

            return;
          }
        }
      } else if (target.type === 'checkbox') {
        if (state.where[key]) {
          if (target.checked) {
            state.where[key].push(target.value);
          } else {
            deleteStateDown(findItemByOptionId({ id: Number(target.value) }));

            const index = state.where[key].indexOf(target.value);

            state.where[key].splice(index, 1);

            if (state.where[key].length === 0) {
              deleteState(key);

              return;
            }
          }
        } else {
          state.where[key] = [target.value];
        }
      } else {
        if (target.value) {
          state.where[key] = target.value;
        } else {
          deleteState(key);

          return;
        }
      }
    }

    setState({ ...state });
  };

  const onClick = ({ target }: { target: any; }) => {
    if (target.name === 'sex' && state.sex === target.value) {
      setState({ ...state, sex: null });
    } else {
      const key = target.name.replace('where-item-', '');

      if (target.type === 'radio' && state.where[key]?.[0] == target.value) {
        deleteStateDown(findItemByOptionId({ id: Number(target.value) }));
        deleteState(key);
      }
    }
  };

  const isRenderSection = (section: any) => {
    return where[state.templateDefaultId][section.id].items.find((item: any) => state.where[item.id]) ? true : false;
  };

  return <>
    <Title>조건 정보</Title>
    <TemplateHeader isTop={true}>기본 정보</TemplateHeader>
    {!isTemp && isAdmin
      && <Item>
        <Name>요청자</Name>
        <Value><Span>{state.adminName}</Span></Value>
      </Item>
    }
    <Item>
      <Name>과제명<Required>*</Required></Name>
      <Value>
        {isTemp
          ? <Input type="text" name="study" value={state.study || ''} onChange={onChange} />
          : <Span>{state.study}</Span>
        }
      </Value>
    </Item>
    {state.id &&
      <Item>
        <Name>상태</Name>
        <Value>
          <Span>{sampleRequestTypes.find(({ code }) => code === state.status)?.name}</Span>
        </Value>
      </Item>
    }
    <Item>
      <Name>주차<Required>*</Required></Name>
      <Value>
        {isTemp
          ? <Select name="template-default-id" value={state.templateDefaultId || ''} onChange={onChange}>
            <option value="">-</option>
            {where.templates.map((template: any) => (
              <option key={`key-request-where-template-${template.id}`} value={template.id}>{template.name}</option>
            ))}
          </Select>
          : <Span>{where.templates.find((template: any) => template.id === state.templateDefaultId).name}</Span>
        }
      </Value>
    </Item>
    {where.null.null.items.filter((item: any) => !item.item_option_id).map((item: any) =>
      <RenderItemValue key={`key-request-where-item-${item.id}`} state={state} item={item} section={where.null.null} isTemp={isTemp} onChange={onChange} onClick={onClick} />
    )}
    {(isTemp || state.sex)
      && <Item>
        <Name>성별</Name>
        <Value>
          {(isTemp || state.sex === 'male')
            && <>
              {isTemp
                && <input type="radio" name="sex" value="male" checked={state.sex === 'male'} onChange={onChange} onClick={onClick} />
              }
              <Span>남</Span>
            </>
          }
          {(isTemp || state.sex === 'female')
            && <>
              {isTemp
                && <input type="radio" name="sex" value="female" checked={state.sex === 'female'} onChange={onChange} onClick={onClick} />
              }
              <Span>여</Span>
            </>
          }
        </Value>
      </Item>
    }
    {where[state.templateDefaultId]?.sections.map((section: any) =>
      <Fragment key={`key-request-where-${section.id}`}>
        {(isTemp || isRenderSection(section))
          && <>
            <TemplateHeader>{section.name}</TemplateHeader>
            {where[state.templateDefaultId][section.id].items.filter((item: any) => !item.item_option_id).map((item: any) =>
              <RenderItemValue key={`key-request-where-item-${item.id}`} state={state} item={item} section={where[state.templateDefaultId][section.id]} isTemp={isTemp} onChange={onChange} onClick={onClick} />
            )}
          </>
        }
      </Fragment>
    )}
    <TemplateHeader>기타 정보</TemplateHeader>
    <Item>
      <Name>총 환자 수</Name>
      <Value>
        {isTemp
          ? <Input type="number" name="patient-count" value={state.patientCount || ''} onChange={onChange} />
          : <Span>{getPatientsSize()}</Span>
        }
      </Value>
    </Item>
    <Item>
      <Name whiteSpace="pre-line">{`비고\n(연구원)`}</Name>
      <Value>
        <Textarea name="note-temp" value={state.noteTemp || ''} onChange={onChange} disabled={!isTemp} />
      </Value>
    </Item>
    {!isTemp && (!isRequest || isAdmin)
      && <Item>
        <Name whiteSpace="pre-line">{`비고\n(관리자)`}</Name>
        <Value>
          <Textarea name="note-request" value={state.noteRequest || ''} onChange={onChange} disabled={!isRequest || !isAdmin} />
        </Value>
      </Item>
    }
  </>;
};

const Title = styled.h3`
  margin-left: 10px;
`;

const TemplateHeader = styled.header<{
  isTop?: boolean;
}>`
  border-top: ${({ isTop = false }) => isTop ? '1px solid #000000' : '1px solid #dfe0e0'};
  color: #0043b7;
  font-weight: 600;
  font-size: 15px;
  line-height: 35px;
  text-align: center;
  background: #edf1fa;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Item = styled.div`
  display: flex;
  width: 100%;
  border-top: 1px solid #dfe0e0;
  min-height: 30px;
`;

const Name = styled.div<{
  whiteSpace?: string;
}>`
  background: #fafafc;
  border-right: 1px solid #dfe0e0;
  width: 150px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  word-break: keep-all;
  white-space: ${({ whiteSpace }) => whiteSpace};
`;

const ValueDown = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 14px;
  position: relative;
`;

const Value = styled.div`
  display: flex;
  min-height: 20px;
  align-items: center;
  padding: 2px;
  flex: 1;
  font-size: 14px;
  position: relative;
`;

const Input = styled.input<{
  width?: string;
}>`
  padding: 5px;
  background: #ffffff;
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  height: 30px;
  width: ${({ width }) => width ?? '100%'};
`;

const Select = styled.select`
  outline: none;
  border: 1px solid #dfe0e0;
  font-size: 14px;
  width: 100px;
  height: 30px;
`;

const Label = styled.label`
  display: flex;
  align-items: baseline;
  border: none;
`;

const Span = styled.span`
  margin-left: 5px;
  margin-right: 15px;
`;

export default SampleRequestFormWhere;

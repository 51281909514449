import { ReactNode } from "react";
import styled from "styled-components";

import Basic from "./Basic";
import { TemplateHeader } from "components/atoms/common";
import ItemForm from "../item/Form";

const PatientForm = ({
  state,
  items,
  onChange,
  onSubmit,
  btnLeft,
}: {
  state: any;
  items: any;
  onChange: () => void;
  onSubmit: (e: any) => void;
  btnLeft?: () => ReactNode;
}) => {
  return (
    <>
      <Wrapper>
        <Basic state={state} onChange={onChange} />
      </Wrapper>
      <Wrapper>
        <TemplateHeader>추가 정보</TemplateHeader>
        <ItemForm data={items} onSubmit={onSubmit} btnLeft={btnLeft} />
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  & + & {
    margin-top: 30px;
  }
`;

export default PatientForm;

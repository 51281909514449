import instance from "apis";

export const login = async (data: { user_name: string; password: string }) => {
  try {
    const response = await instance.post("/auth/logIn", data);
    return response.data;
  } catch (err: any) {
    return err.response.data;
  }
};

export const changePassword = async (data: {
  pre_password: string;
  password: string;
}) => {
  try {
    const response = await instance.patch("/auth/password", data);
    return response;
  } catch (err: any) {
    if (err.response) {
      alert(err.response.data.message);
    }
  }
};

export const getUserToken = (): string => {
  const token = localStorage.getItem("userToken");
  return token ? token : "";
};

export const getMyInfo = async () => {
  const response = await instance.get("/auth/my-info");
  return response.data;
};

import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";

import isLoginState from "stores/isLoginState";

import { getUserToken } from "../apis/user";
import instance from "../apis/index";

const useTokenCheck = () => {
  const [checked, setChecked] = useState(false);
  const setIsLoggedIn = useSetRecoilState(isLoginState);
  const token = getUserToken();
  useEffect(() => {
    if (token) {
      checkToken();
    } else {
      setChecked(true);
    }
  }, []);
  const checkToken = async () => {
    try {
      await instance.get(`/projects`, {
        headers: { Authorization: "Bearer " + token },
      });
      instance.defaults.headers.common["Authorization"] = "Bearer " + token;
      setIsLoggedIn(true);
    } catch (error: any) {
      window.location.href = "/login";
      setIsLoggedIn(false);
      localStorage.removeItem("userToken");
    }
    setChecked(true);
  };
  return checked;
};

export default useTokenCheck;

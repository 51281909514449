import { useState } from "react";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import styled from "styled-components";
import moment from "moment";

import { getProjectStatistics } from "apis/project";

import PieChart from "components/organisms/charts/Pie";
import BarChart from "components/organisms/charts/Bar";
import { Input } from "components/atoms/input";

const Statistics = () => {
  const longAgo = moment("2000-01-01").format("YYYY-MM-DD");
  const today = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(longAgo);
  const [endDate, setEndDate] = useState(today);
  const { projectId = "" } = useParams();
  const { data, isFetching } = useQuery(
    ["getProjectStatistics", { projectId, startDate, endDate }],
    () => getProjectStatistics({ projectId, startDate, endDate })
  );
  // console.log(projectId);

  console.log(data);

  return (
    <Container>
      환자 등록일
      <Filter>
        <Input
          type="date"
          xSmall
          value={startDate}
          max={endDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        &nbsp;~&nbsp;
        <Input
          type="date"
          xSmall
          value={endDate}
          min={startDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </Filter>
      {!isFetching && (
        <>
          <Total>총 환자 수 : {data?.total}명</Total>
          <TopRow>
            <Wrapper>
              <Title>환자 성별 통계 </Title>
              <PieChart {...data.gender} />
            </Wrapper>
            <Wrapper>
              <Title>환자 나이 통계</Title>
              <BarChart {...data.age} />
            </Wrapper>
          </TopRow>
          <Row>
            <Wrapper>
              <Title>병원별 통계</Title>
              <BarChart {...data.hospital} />
            </Wrapper>
          </Row>
          <BottomRow>
            {data.diagnosis.data.length > 0 ? (
              <Wrapper>
                <Title> 진단별 통계</Title>
                <PieChart {...data.diagnosis} />
              </Wrapper>
            ) : (
              ""
            )}

            {data.study.data.length > 0 ? (
              <Wrapper>
                <Title>임상군 통계</Title>
                <PieChart {...data.study} />
              </Wrapper>
            ) : (
              ""
            )}
          </BottomRow>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: #fafbfc;
  border: 1px solid #dfe0e0;
  padding: 20px;
`;
const Filter = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const Total = styled.div`
  font-size: 20px;
  margin-bottom: 10px;
`;

const Row = styled.div`
  display: grid;
  grid-gap: 15px;
  & + & {
    margin-top: 20px;
  }
`;

const TopRow = styled(Row)`
  grid-template-columns: 1fr 2fr;
`;

const BottomRow = styled(Row)`
  grid-template-columns: 1fr 1fr 1fr;
`;

export const Wrapper = styled.div`
  padding: 25px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  background: #fff;
  border: 1px solid #dfe0e0;
  width: auto;
`;
//height: auto; //

export const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 160%;
`;

export default Statistics;

import { BtnPink, BtnWhite } from "components/atoms/button";
import { SpaceBetween } from "components/atoms/common";
import { Item, Items, Value, Name } from "components/atoms/item";
import { Input } from "components/atoms/input";
import useInputs from "hooks/useInputs";
import styled from "styled-components";
import { changePassword } from "apis/user";

const ModalPasswordChange = ({ onClose }: { onClose: () => void }) => {
  const [state, onChange] = useInputs({
    currentPW: "",
    changePW: "",
    confirmPW: "",
  });
  const { currentPW, changePW, confirmPW } = state;
  const handleSubmit = async () => {
    if (changePW === confirmPW) {
      const data = { pre_password: currentPW, password: changePW };
      const result = await changePassword(data);
      if (result) {
        alert("비밀번호가 변경되었습니다.");
        onClose();
      }
    } else alert("비밀번호를 확인해주세요.");
  };
  return (
    <Container>
      <Items>
        <Title>비밀번호 변경</Title>
        <Item size={1}>
          <Name>현재 비밀번호</Name>
          <Value>
            <Input name="currentPW" onChange={onChange} type="password" />
          </Value>
        </Item>
        <Item size={1}>
          <Name>변경 비밀번호</Name>
          <Value>
            <Input name="changePW" onChange={onChange} type="password" />
          </Value>
        </Item>
        <Item size={1}>
          <Name>비밀번호 확인</Name>
          <Value>
            <Input name="confirmPW" onChange={onChange} type="password" />
            {confirmPW && (
              <PwCheck isSamePw={changePW === confirmPW}>
                {changePW === confirmPW ? "일치" : "불일치"}
              </PwCheck>
            )}
          </Value>
        </Item>
      </Items>
      <SpaceBetween>
        <BtnCancel onClick={onClose}>취소</BtnCancel>
        <BtnPink
          large
          onClick={() => {
            if (window.confirm("비밀번호를 변경하시겠습니까?")) {
              handleSubmit();
            }
          }}
        >
          저장
        </BtnPink>
      </SpaceBetween>
    </Container>
  );
};

const Container = styled.div`
  padding: 32px 40px 30px;
  background: #fff;
  width: 614px;
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 19px;
`;
const PwCheck = styled.p<{ isSamePw: boolean }>`
  position: absolute;
  font-size: 14px;
  bottom: 23px;
  right: 20px;
  color: ${({ isSamePw }) => (isSamePw ? "#00c773" : "#f50000")};
`;
const BtnCancel = styled(BtnWhite)`
  width: 90px;
`;
export default ModalPasswordChange;

import { getPrednisoloneDose as getTotalDose } from "./common/getCumulativeDose";

const totalDose10 = {
  id: 29,
  name: "Total Dose (10)",
  datas: [
    "daily_dose_1",
    "daily_dose_2",
    "daily_dose_3",
    "daily_dose_4",
    "daily_dose_5",
    "daily_dose_6",
    "daily_dose_7",
    "daily_dose_8",
    "daily_dose_9",
    "daily_dose_10",
    "start_date_1",
    "end_date_1",
    "start_date_2",
    "end_date_2",
    "start_date_3",
    "end_date_3",
    "start_date_4",
    "end_date_4",
    "start_date_5",
    "end_date_5",
    "start_date_6",
    "end_date_6",
    "start_date_7",
    "end_date_7",
    "start_date_8",
    "end_date_8",
    "start_date_9",
    "end_date_9",
    "start_date_10",
    "end_date_10",
  ],
  formula: ({
    daily_dose_1,
    daily_dose_2,
    daily_dose_3,
    daily_dose_4,
    daily_dose_5,
    daily_dose_6,
    daily_dose_7,
    daily_dose_8,
    daily_dose_9,
    daily_dose_10,
    start_date_1,
    end_date_1,
    start_date_2,
    end_date_2,
    start_date_3,
    end_date_3,
    start_date_4,
    end_date_4,
    start_date_5,
    end_date_5,
    start_date_6,
    end_date_6,
    start_date_7,
    end_date_7,
    start_date_8,
    end_date_8,
    start_date_9,
    end_date_9,
    start_date_10,
    end_date_10,
  }: any) => {
    return getTotalDose([
      [daily_dose_1, start_date_1, end_date_1],
      [daily_dose_2, start_date_2, end_date_2],
      [daily_dose_3, start_date_3, end_date_3],
      [daily_dose_4, start_date_4, end_date_4],
      [daily_dose_5, start_date_5, end_date_5],
      [daily_dose_6, start_date_6, end_date_6],
      [daily_dose_7, start_date_7, end_date_7],
      [daily_dose_8, start_date_8, end_date_8],
      [daily_dose_9, start_date_9, end_date_9],
      [daily_dose_10, start_date_10, end_date_10],
    ]);
  },
  help: `누적용량`,
};

export default totalDose10;

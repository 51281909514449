import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Key } from "assets/icons/Key.svg";
import Modal from "components/organisms/Modal";
import ModalPasswordChange from "components/templates/ModalPasswordChange";
import { useSetRecoilState } from "recoil";
import isLoginState from "stores/isLoginState";
import { useQuery } from "react-query";
import { getMyInfo } from "apis/user";
import { getUserRank, getTokenPayload } from "utils/user";
import { getQueryCount } from "apis/query";
import { Red } from "components/atoms/query";

const Top = () => {
  const navigate = useNavigate();
  const rank = getUserRank();
  const setIsLoggedIn = useSetRecoilState(isLoginState);
  const { data, isLoading } = useQuery("getMyInfo", getMyInfo);
  const logout = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      localStorage.removeItem("userToken");
      setIsLoggedIn(false);
      navigate("/login");
    }
  };

  //시작 20221212 오토쿼리 관련 추가 by 장희준
  const {isSuper, projectId} = getTokenPayload();
  const { data: query_data, isLoading: isQueryLoading } = useQuery("getQueryCount", () => getQueryCount(), { refetchOnWindowFocus: true });
  const getQuerys = () => { navigate("/querys"); };
  //끝 20221212 오토쿼리 관련 추가 by 장희준

  if (isLoading || isQueryLoading) return <></>;

  return (
    <Layout>
      <Container>
        <Name>{data.name} 님</Name>
        <Modal
          activator={(onOpen) => <Key onClick={onOpen} />}
          content={(onClose) => <ModalPasswordChange onClose={onClose} />}
        />
        <Devider />
        <Type>{rank}</Type>
      </Container>
      <Container>
        <BtnLogout onClick={logout}>로그아웃</BtnLogout>
        {(!isSuper && parseInt(projectId) === 674) && 
          <>
            <Devider />
            <BtnLogout onClick={getQuerys}><Red>{query_data.state1_cnt}</Red></BtnLogout>
          </>
        }
      </Container>
    </Layout>
  );
};

const Layout = styled.div`
  padding: 50px 20px 40px;
  border-bottom: 1px solid #e4e6e6;
`;
const Container = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    cursor: pointer;
  }
`;
const BtnLogout = styled.button`
  border: 1px solid #e4e6e6;
  border-radius: 2px;
  padding: 3px 5px;
  color: #989899;
  background: #fff;
  font-size: 12px;
`;
const Name = styled.span`
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  cursor: default;
`;
const Devider = styled.div`
  height: 15px;
  background: #e4e6e6;
  width: 1px;
  margin: 0 10px;
`;
const Type = styled.span`
  color: #989899;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  cursor: default;
`;

export default Top;

import { useQuery, useMutation, useQueryClient } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import { getProject } from "apis/project";
import { createPatient, checkPatientDuplicated } from "apis/patient";
import useInputs from "hooks/useInputs";

import { PageLayout, PageContainer } from "components/atoms/page";

import BtnLeft from "components/molecules/BtnLeft";
import PatientForm from "components/organisms/PatientForm";
import { getItems } from "apis/item";
import { getMyInfo } from "apis/user";
import { useEffect } from "react";

const PatientCreate = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { projectId = "" } = useParams();

  const { isLoading: isLoadingMyInfo, data: myInfo } = useQuery(
    ["getMyInfo"],
    getMyInfo
  );
  const { isLoading: isLoadingProject, data: project } = useQuery(
    ["getProject", projectId],
    () => getProject({ projectId })
  );
  const { isLoading: isLoadingItems, data: items } = useQuery(
    ["getItems", projectId],
    () => getItems({ projectId })
  );

  const [state, onChange] = useInputs({
    name: "",
    birthday: "",
    sex: "male",
    note: "",
    hospital: "",
    admin_id: "",
    hospital_id: "",
    //initial_diagnosis_date:"",
    //patient_type_id: "",
  });

  const { name, birthday } = state;

  const mutation = useMutation(
    (values) => createPatient({ projectId, ...state, values }),
    {
      onSuccess: () => {
        alert("환자 추가 성공");
        queryClient.invalidateQueries(["getPatients"]);
        navigate("..");
      },
    }
  );

  useEffect(() => {
    if (!isLoadingMyInfo) {
      onChange({ target: { name: "admin_id", value: myInfo.id } });
      onChange({ target: { name: "hospital_id", value: myInfo.hospital_id } });
    }
  }, [myInfo]);

  const handleSubmit = async (values: any) => {
    if (mutation.isLoading) return;
    if (!state.name) return alert("이름을 입력하세요");
    if (!state.birthday) return alert("생년월일을 입력하세요");
    if (!state.initial_diagnosis_date) return alert("최초진단일을 입력하세요");

    const isDuplicated = await checkPatientDuplicated({
      projectId,
      birthday,
      name,
    });

    if (isDuplicated) {
      const message =
        "생년월일과 이름이 중복되는 환자가 있습니다. 추가하시겠습니까?";
      if (!window.confirm(message)) return;
    }
    //console.log("isDuplicated: values ", values);
    //console.log("isDuplicated: state", state);
    //console.log("isDuplicated: items", items);
    mutation.mutate(values);
  };

  if (isLoadingMyInfo || isLoadingProject || isLoadingItems) return null;

  return (
    <PageLayout>
      <Title>
        <Link to={`/project/${projectId}`}>
          <BtnLeft />
        </Link>
        신규 환자 등록
        <span> / {project.name}</span>
      </Title>
      <PageContainer>
        <PatientForm
          state={state}
          items={items}
          onChange={onChange}
          onSubmit={handleSubmit}
        />
      </PageContainer>
    </PageLayout>
  );
};

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  display: flex;
  align-items: center;
  flex: 1;
  svg {
    margin-right: 10px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #989899;
    margin-left: 10px;
  }
`;

export default PatientCreate;

import PatientType from "types/patient";
import { TemplateType } from "types";
import getAge from "./common/getAge";
import getLMS from "./common/getLMS";
import { NORMSDIST } from "@formulajs/formulajs";
import getBloodPressurePercentile, {
  getBpStdAge,
} from "./common/getBpPercentile";

const sbpPercentile = {
  id: 22,
  name: "Blood Pressure Percentile (SBP)",
  datas: ["height", "SBP"],
  formula: (
    { height, SBP }: any,
    { sex, birthday }: PatientType,
    { reporting_date }: TemplateType
  ) => {
    if (!height || !SBP || !sex || !birthday || !reporting_date) {
      return;
    }

    const isSBP = true;
    if (SBP == null || height == null) {
      return 0;
    }

    const age = getAge(birthday, reporting_date);

    // n 개월 -> k.5 세로 변환
    const stdAge = getBpStdAge(age);

    if (stdAge > 18.5) {
      return;
    }

    // 체고백분위
    const { L, M, S } = getLMS({ type: "height", sex, age });
    const heightPercentile = parseFloat(
      (NORMSDIST((Math.pow(height / M, L) - 1) / (L * S), true) * 100).toFixed(
        1
      )
    );

    // SBP Percentile
    return getBloodPressurePercentile(
      sex,
      SBP,
      stdAge,
      heightPercentile,
      isSBP
    );
  },
  help: `SBP 백분위수`,
};

export default sbpPercentile;

import { atom } from "recoil";
import SampleRequestSearchType from "types/sampleRequestSearch";

const sampleRequestSearchStateInit = atom<SampleRequestSearchType>({
  key: "sampleRequestSearchStateInit",
  default: {
    tab: 'request',
    study: '',
    startDate: '',
    endDate: '',
    status: '',
    page: 1
  },
});

export default sampleRequestSearchStateInit;

import { Outlet } from "react-router-dom";

import useTokenCheck from "hooks/useTokenCheck";

const TokenCheck = () => {
  const isTokenChecked = useTokenCheck();
  return isTokenChecked ? <Outlet /> : null;
};

export default TokenCheck;

import { useQuery } from "react-query";
import { Outlet, useParams } from "react-router";

import { getItems } from "apis/item";
import EditItemWrapper from "components/organisms/item/EditWrapper";

const Patients = () => {
  const { projectId = "" } = useParams();

  const { data, isLoading } = useQuery(["getItems", { projectId }], () =>
    getItems({ projectId })
  );

  if (isLoading) return <></>;

  return (
    <>
      <EditItemWrapper data={data} />
      <Outlet />
    </>
  );
};

export default Patients;

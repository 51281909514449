import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";

import { deleteProject, getProjects } from "apis/project";
import { ProjectType } from "types";

import { SpaceBetween } from "components/atoms/common";
import { PageContainer, PageLayout } from "components/atoms/page";
import { TabConatiner, Tab, TabLine } from "components/atoms/tab";
import { Table, TR, TH, TD } from "components/atoms/table";
import { BtnBlue } from "components/atoms/button";
import SearchBox from "components/molecules/SearchBox";
import Modal from "components/organisms/Modal";
import ModalProjectName from "components/organisms/ModalProjectName";
import { ReactComponent as Trash } from "assets/icons/Trash.svg";
import { getTokenPayload } from "utils/user";
import { useState } from "react";
import Pagination from "components/organisms/Pagination";
import styled from "styled-components";
import { getLocaleDate } from "utils/time";

const display = 15;
const AdminProjects = () => {
  const queryClient = useQueryClient();
  const { isSuper } = getTokenPayload();
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState("");
  const { data, isLoading } = useQuery(
    ["getProjects", { page, query }],
    () => getProjects({ page, query }),
    { keepPreviousData: true }
  );
  const mutation = useMutation((id: number) => deleteProject(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(["getProjects"]);
    },
  });

  const handleDelete = (id: number) => {
    if (!window.confirm("정말 삭제하시겠습니까?")) return;
    mutation.mutate(id);
  };

  const handleSearch = (query: string) => {
    setQuery(query);
    setPage(1);
  };

  return (
    <PageLayout>
      <SpaceBetween>
        <h3> 프로젝트 관리 / 생성</h3>
        {isSuper === 1 && (
          <Modal
            activator={(onOpen) => (
              <BtnBlue onClick={onOpen}>프로젝트 추가 +</BtnBlue>
            )}
            content={(onClose) => <ModalProjectName onClose={onClose} />}
          />
        )}
      </SpaceBetween>
      <PageContainer>
        <TabConatiner>
          <Tab isActive>프로젝트 목록</Tab>
          <TabLine />
        </TabConatiner>
        <SpaceBetween>
          <div>총 {data?.total}개 프로젝트</div>
          <SearchBox
            query={query}
            onSubmit={handleSearch}
            placeholder="이름으로 검색"
          />
        </SpaceBetween>
        <Table>
          <colgroup>
            <col width="" />
            <col width="50%" />
            <col width="" />
            <col width="" />
            <col width="" />
          </colgroup>
          <thead>
            <TR>
              <TH>순번</TH>
              <TH>프로젝트 명</TH>
              <TH>등록일</TH>
              <TH>섹션 수</TH>
              <TH>관리</TH>
            </TR>
          </thead>
          <tbody>
            {!isLoading &&
              data.items.map(
                (
                  { id, name, created_at, section_count }: ProjectType,
                  i: number
                ) => (
                  <TR key={id}>
                    <TD>{(page - 1) * display + i + 1}</TD>
                    <TD left>
                      <Link to={`${id}/researcher`}>{name}</Link>
                    </TD>
                    <TD>{getLocaleDate(created_at)}</TD>
                    <TD>{section_count}</TD>
                    <TD>
                      {isSuper === 1 && (
                        <Trash onClick={() => handleDelete(id)} />
                      )}
                    </TD>
                  </TR>
                )
              )}
          </tbody>
        </Table>
        <Footer>
          <Pagination
            total={data?.total}
            display={display}
            page={page}
            onChange={(page: number) => setPage(page)}
          />
        </Footer>
      </PageContainer>
    </PageLayout>
  );
};
const Footer = styled.footer`
  margin: 30px auto;
  display: flex;
  justify-content: center;
`;
export default AdminProjects;

type bpType = {
  [sex: string]: {
    [age: number]: {
      p50: number[];
      p90: number[];
    };
  };
};

// 수축기 혈압
export const sbpData: bpType = {
  male: {
    0.08: {
      p50: [75, 76, 76, 77, 77, 78, 78],
      p90: [90, 90, 91, 92, 92, 93, 93],
    },
    0.3: {
      p50: [81, 81, 82, 83, 83, 83, 84],
      p90: [96, 96, 97, 98, 98, 98, 98],
    },
    0.8: {
      p50: [87, 88, 88, 89, 89, 90, 90],
      p90: [102, 103, 103, 104, 104, 105, 105],
    },
    1.5: {
      p50: [92, 93, 93, 94, 94, 95, 95],
      p90: [107, 108, 108, 109, 109, 110, 110],
    },
    2.5: {
      p50: [94, 94, 95, 95, 96, 96, 96],
      p90: [109, 109, 110, 110, 111, 111, 111],
    },
    3.5: {
      p50: [94, 95, 96, 96, 97, 97, 97],
      p90: [109, 110, 110, 111, 112, 112, 112],
    },
    4.5: {
      p50: [97, 97, 98, 99, 99, 100, 100],
      p90: [112, 112, 113, 114, 114, 114, 115],
    },
    5.5: {
      p50: [101, 102, 102, 103, 103, 104, 104],
      p90: [116, 117, 117, 118, 118, 119, 119],
    },
    6.5: {
      p50: [104, 104, 105, 105, 106, 106, 106],
      p90: [119, 119, 120, 120, 121, 121, 121],
    },
    7.5: {
      p50: [99, 100, 101, 103, 105, 107, 108],
      p90: [114, 115, 116, 118, 120, 122, 123],
    },
    8.5: {
      p50: [99, 99, 101, 103, 105, 106, 107],
      p90: [114, 115, 116, 118, 120, 122, 123],
    },
    9.5: {
      p50: [99, 100, 102, 103, 105, 107, 108],
      p90: [115, 116, 117, 119, 121, 122, 124],
    },
    10.5: {
      p50: [101, 101, 103, 105, 106, 108, 109],
      p90: [116, 117, 118, 120, 122, 124, 125],
    },
    11.5: {
      p50: [102, 103, 105, 106, 108, 110, 111],
      p90: [118, 119, 120, 122, 124, 126, 127],
    },
    12.5: {
      p50: [105, 105, 107, 108, 110, 112, 113],
      p90: [120, 121, 122, 124, 126, 128, 129],
    },
    13.5: {
      p50: [107, 108, 109, 111, 113, 115, 116],
      p90: [122, 123, 125, 126, 128, 130, 131],
    },
    14.5: {
      p50: [110, 110, 112, 114, 115, 117, 118],
      p90: [125, 126, 127, 129, 131, 133, 134],
    },
    15.5: {
      p50: [112, 113, 115, 116, 118, 120, 121],
      p90: [128, 129, 130, 132, 134, 136, 137],
    },
    16.5: {
      p50: [115, 116, 117, 119, 121, 123, 124],
      p90: [131, 132, 133, 135, 137, 138, 140],
    },
    17.5: {
      p50: [118, 119, 120, 122, 124, 126, 127],
      p90: [134, 135, 136, 138, 140, 141, 143],
    },
    18.5: {
      p50: [121, 122, 124, 125, 127, 129, 130],
      p90: [137, 138, 139, 141, 143, 144, 146],
    },
  },
  female: {
    0.08: {
      p50: [76, 76, 76, 77, 77, 78, 78],
      p90: [90, 90, 91, 91, 91, 92, 92],
    },
    0.3: {
      p50: [79, 80, 80, 80, 81, 81, 81],
      p90: [94, 94, 94, 95, 95, 95, 96],
    },
    0.8: {
      p50: [84, 84, 85, 85, 85, 86, 86],
      p90: [98, 98, 99, 99, 100, 100, 100],
    },
    1.5: {
      p50: [89, 89, 89, 90, 90, 90, 91],
      p90: [103, 103, 103, 104, 104, 105, 105],
    },
    2.5: {
      p50: [92, 92, 92, 93, 93, 94, 94],
      p90: [106, 106, 107, 107, 107, 108, 108],
    },
    3.5: {
      p50: [94, 94, 94, 95, 95, 96, 96],
      p90: [108, 108, 109, 109, 110, 110, 110],
    },
    4.5: {
      p50: [96, 96, 97, 97, 98, 98, 98],
      p90: [110, 111, 111, 111, 112, 112, 112],
    },
    5.5: {
      p50: [99, 99, 100, 100, 100, 101, 101],
      p90: [113, 114, 114, 114, 115, 115, 115],
    },
    6.5: {
      p50: [102, 102, 103, 103, 104, 104, 104],
      p90: [116, 117, 117, 117, 118, 118, 118],
    },
    7.5: {
      p50: [97, 98, 98, 100, 101, 103, 104],
      p90: [112, 112, 113, 114, 115, 117, 118],
    },
    8.5: {
      p50: [99, 99, 100, 101, 102, 104, 105],
      p90: [113, 113, 114, 115, 116, 118, 119],
    },
    9.5: {
      p50: [100, 100, 101, 102, 104, 105, 106],
      p90: [114, 114, 115, 116, 118, 119, 120],
    },
    10.5: {
      p50: [101, 102, 102, 103, 105, 106, 107],
      p90: [115, 116, 116, 118, 119, 121, 122],
    },
    11.5: {
      p50: [102, 103, 103, 105, 106, 108, 109],
      p90: [116, 117, 118, 119, 120, 122, 123],
    },
    12.5: {
      p50: [103, 104, 104, 106, 107, 109, 110],
      p90: [117, 118, 119, 120, 121, 123, 124],
    },
    13.5: {
      p50: [104, 104, 105, 106, 108, 109, 110],
      p90: [118, 119, 119, 120, 122, 123, 124],
    },
    14.5: {
      p50: [105, 105, 106, 107, 108, 110, 111],
      p90: [119, 119, 120, 121, 123, 124, 125],
    },
    15.5: {
      p50: [106, 106, 107, 108, 109, 111, 112],
      p90: [120, 120, 121, 122, 123, 125, 126],
    },
    16.5: {
      p50: [106, 107, 108, 109, 110, 112, 113],
      p90: [121, 121, 122, 123, 124, 126, 127],
    },
    17.5: {
      p50: [108, 108, 109, 110, 111, 113, 114],
      p90: [122, 122, 123, 124, 126, 127, 128],
    },
    18.5: {
      p50: [110, 110, 111, 112, 113, 115, 116],
      p90: [124, 124, 125, 126, 128, 129, 130],
    },
  },
};

// 이완기 혈압
export const dbpData: bpType = {
  male: {
    0.08: {
      p50: [43, 43, 44, 44, 44, 44, 44],
      p90: [54, 54, 54, 55, 55, 55, 55],
    },
    0.3: {
      p50: [50, 50, 50, 51, 51, 51, 51],
      p90: [60, 61, 61, 61, 62, 62, 61],
    },
    0.8: {
      p50: [56, 57, 57, 57, 58, 57, 57],
      p90: [67, 67, 68, 68, 68, 68, 68],
    },
    1.5: {
      p50: [60, 60, 61, 61, 61, 61, 61],
      p90: [71, 71, 71, 72, 72, 72, 72],
    },
    2.5: {
      p50: [58, 58, 59, 59, 59, 59, 59],
      p90: [69, 69, 70, 70, 70, 70, 70],
    },
    3.5: {
      p50: [56, 56, 57, 57, 57, 57, 57],
      p90: [67, 67, 67, 68, 68, 68, 68],
    },
    4.5: {
      p50: [57, 57, 58, 58, 58, 58, 58],
      p90: [67, 68, 68, 69, 69, 69, 69],
    },
    5.5: {
      p50: [59, 60, 60, 61, 61, 61, 61],
      p90: [70, 71, 71, 71, 71, 71, 71],
    },
    6.5: {
      p50: [58, 59, 59, 59, 60, 60, 60],
      p90: [69, 69, 70, 70, 70, 70, 70],
    },
    7.5: {
      p50: [57, 57, 58, 58, 59, 60, 60],
      p90: [67, 68, 68, 69, 69, 70, 70],
    },
    8.5: {
      p50: [58, 58, 58, 59, 59, 60, 60],
      p90: [68, 68, 68, 69, 70, 70, 70],
    },
    9.5: {
      p50: [58, 58, 59, 59, 60, 60, 61],
      p90: [68, 68, 69, 69, 70, 70, 71],
    },
    10.5: {
      p50: [58, 59, 59, 60, 60, 61, 61],
      p90: [69, 69, 69, 70, 70, 71, 71],
    },
    11.5: {
      p50: [59, 59, 59, 60, 61, 61, 61],
      p90: [69, 69, 70, 70, 71, 71, 72],
    },
    12.5: {
      p50: [59, 59, 60, 60, 61, 61, 62],
      p90: [69, 70, 70, 71, 71, 72, 72],
    },
    13.5: {
      p50: [60, 60, 60, 61, 61, 62, 62],
      p90: [70, 70, 70, 71, 72, 72, 72],
    },
    14.5: {
      p50: [60, 60, 61, 61, 62, 62, 63],
      p90: [70, 70, 71, 71, 72, 72, 73],
    },
    15.5: {
      p50: [61, 61, 61, 62, 62, 63, 63],
      p90: [71, 71, 71, 72, 72, 73, 73],
    },
    16.5: {
      p50: [61, 61, 62, 62, 63, 63, 64],
      p90: [71, 72, 72, 73, 73, 74, 74],
    },
    17.5: {
      p50: [62, 62, 63, 63, 64, 64, 65],
      p90: [72, 72, 73, 73, 74, 74, 75],
    },
    18.5: {
      p50: [63, 63, 64, 64, 65, 66, 66],
      p90: [73, 74, 74, 75, 75, 76, 76],
    },
  },
  female: {
    0.08: {
      p50: [44, 44, 44, 44, 44, 44, 44],
      p90: [54, 55, 55, 55, 55, 55, 55],
    },
    0.3: {
      p50: [49, 49, 49, 49, 49, 49, 49],
      p90: [60, 60, 60, 60, 60, 60, 60],
    },
    0.8: {
      p50: [54, 55, 55, 55, 55, 55, 55],
      p90: [65, 65, 66, 66, 66, 66, 66],
    },
    1.5: {
      p50: [58, 58, 59, 59, 59, 59, 59],
      p90: [69, 69, 69, 70, 70, 70, 70],
    },
    2.5: {
      p50: [57, 57, 57, 58, 58, 58, 57],
      p90: [69, 69, 69, 69, 69, 70, 69],
    },
    3.5: {
      p50: [57, 57, 57, 58, 58, 58, 57],
      p90: [68, 68, 68, 68, 68, 68, 68],
    },
    4.5: {
      p50: [57, 58, 58, 58, 58, 58, 58],
      p90: [68, 68, 69, 69, 69, 69, 69],
    },
    5.5: {
      p50: [59, 59, 59, 60, 60, 60, 59],
      p90: [70, 70, 70, 70, 70, 70, 70],
    },
    6.5: {
      p50: [59, 59, 59, 59, 59, 59, 59],
      p90: [70, 70, 70, 70, 70, 70, 70],
    },
    7.5: {
      p50: [57, 57, 57, 58, 58, 59, 59],
      p90: [67, 67, 67, 67, 68, 69, 69],
    },
    8.5: {
      p50: [57, 57, 58, 58, 59, 59, 60],
      p90: [67, 67, 67, 68, 68, 69, 69],
    },
    9.5: {
      p50: [58, 58, 58, 59, 59, 60, 60],
      p90: [67, 67, 68, 68, 69, 69, 70],
    },
    10.5: {
      p50: [58, 58, 59, 59, 60, 60, 60],
      p90: [68, 68, 68, 69, 69, 70, 70],
    },
    11.5: {
      p50: [59, 59, 59, 59, 60, 61, 61],
      p90: [68, 68, 69, 69, 70, 70, 71],
    },
    12.5: {
      p50: [59, 59, 60, 60, 61, 61, 61],
      p90: [69, 69, 69, 70, 70, 71, 71],
    },
    13.5: {
      p50: [60, 60, 60, 61, 61, 62, 62],
      p90: [69, 70, 70, 70, 71, 71, 72],
    },
    14.5: {
      p50: [60, 60, 61, 61, 62, 62, 63],
      p90: [70, 70, 70, 71, 71, 72, 72],
    },
    15.5: {
      p50: [61, 61, 61, 62, 62, 63, 63],
      p90: [71, 71, 71, 71, 72, 73, 73],
    },
    16.5: {
      p50: [62, 62, 62, 62, 63, 64, 64],
      p90: [71, 71, 72, 72, 73, 73, 74],
    },
    17.5: {
      p50: [62, 62, 63, 63, 64, 64, 65],
      p90: [72, 72, 72, 73, 73, 74, 74],
    },
    18.5: {
      p50: [63, 63, 63, 64, 65, 65, 65],
      p90: [73, 73, 73, 74, 74, 75, 75],
    },
  },
};

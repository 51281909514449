import { useMutation } from "react-query";

import { BtnPink, BtnBlue } from "components/atoms/button";
import { SpaceBetween, TemplateHeader } from "components/atoms/common";
import { Items, Name, Value } from "components/atoms/item";
import { Textarea } from "components/atoms/input";
import { ModalContainer } from "components/atoms/modal";
import { BtnCancel, QueryItem, QueryName, QueryValue } from "components/atoms/query";

import useInputs from "hooks/useInputs";

import { getTokenPayload } from "utils/user";

import { createQueryAnswer } from "apis/query";
import { useParams } from 'react-router-dom';

const ModalAnswer = ({
  onClose,
  data,
}: {
  onClose: () => void;
  data?: any;
}) => {
  const { isSuper, isProjectSuper } = getTokenPayload();

  const [state, onChange] = useInputs({ ...data });
  const { rn, id, admin_name, item_name, patient_name, content, state_code, state_name, value, value_option } = state;

  const { projectId, patientId } = useParams();

  const mutation = useMutation(
    () => createQueryAnswer(state),
    {
      onSuccess: () => {
        alert("Answer 저장 성공");
        onClose();
      },
      onError: ({ response: { data } }) => {
        alert(data.message);
      },
    }
  );

  const querySave = async () => {
    if (mutation.isLoading) return;
    if (!content) {
      alert("내용을 적어주세요");
      return;
    }
    if (window.confirm(`쿼리${isProjectSuper !== 0 ? '를 open' : '에 답변'} 하시겠습니까?`)) {
      await onChange({ target: { name: "state_code", value: (isProjectSuper !== 0 ? 1 : 2) } });
      mutation.mutate();
    }
  };

  const queryClose = async () => {
    if (mutation.isLoading) return;
    if (!content) {
      alert("내용을 적어주세요");
      return;
    }
    if (window.confirm(`쿼리를 close 하시겠습니까?`)) {
      await onChange({ target: { name: "state_code", value: 3 } });
      mutation.mutate();
    }
  };

  return (
    <ModalContainer>
      <TemplateHeader>쿼리 문답</TemplateHeader>
      <Items>
        <QueryItem>
          <QueryName>순번</QueryName>
          <QueryName>대상자번호</QueryName>
          <QueryName>담당자명</QueryName>
          <QueryName>항목명</QueryName>
          <QueryName>상태</QueryName>
        </QueryItem>
        <QueryItem hsize={40}>
          <QueryValue>{rn}</QueryValue>
          <QueryValue>{patient_name}</QueryValue>
          <QueryValue>{admin_name}</QueryValue>
          <QueryValue>{item_name}</QueryValue>
          <QueryValue>
            {!state_code ? (isProjectSuper !== 0 ? "오픈" : "답변") : state_name}
          </QueryValue>
        </QueryItem>
        <QueryItem>
          <Name>내용</Name>
          <Value>
            <Textarea
              value={content || ''}
              name="content"
              onChange={onChange}
              readOnly={(!id) ? false : true}
              rows={10}
            />
          </Value>
        </QueryItem>
      </Items>
      <SpaceBetween>
        <BtnCancel large type="button" onClick={onClose}>취소</BtnCancel>
        {(isProjectSuper !== 0 && !data.id) && <BtnBlue large onClick={queryClose}>클로즈</BtnBlue>}
        {(!data.id) && <BtnPink large type="button" onClick={querySave}>저장</BtnPink>}
      </SpaceBetween>
    </ModalContainer>
  );
};

export default ModalAnswer;

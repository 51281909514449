import styled from "styled-components";

import { ItemType, OnChangeType } from "types";
import { BtnBlack } from "components/atoms/button";

import Radio from "./Radio";
import Text from "./Text";
import Select from "./Select";
import Textarea from "./Textarea";
import Date from "./Date";
import Unit from "./Unit";
import Checkbox from "./Checkbox";
import Formula from "./Formula";
import File from "./File";
import { useParams } from "react-router";

const ItemValue = ({
  data,
  onChange,
  values,
  readOnly,
  view,
  checkInput,
}: {
  data: ItemType;
  values: any;
  onChange: OnChangeType;
  readOnly?: boolean;
  view?: boolean;
  checkInput?: boolean;
}) => {
  const { id, value, valueOptions, unit, options, type, formula, formula_id } = data;
  const props = { data, onChange, value: values[id], readOnly, checkInput };
  //console.log("props:" ,props);

  // 계산식은 template 정보가 필요해서 어드민페이지에서는 출력하지 않음
  const { templateId = "" } = useParams();
  if (!templateId && type === "formula") return <></>;

  // FIXME: radio, checkbox의 경우 선택 해제(변경)시 초기화
  const viewValue = () => {
    switch (type) {
      case "text":
      case "date":
        return <>{value}</>;
      case "textarea":
        return <PreWrap>{value}</PreWrap>;
      case "select":
      case "radio":
      case "checkbox":
        return (
          <div>
            {valueOptions?.map((id) => (
              <div key={id}>{options.find((o) => o.id === id)?.value}</div>
            ))}
          </div>
        );
      case "formula":
        return <>{value}</>;
      case "unit":
        return (
          <>
            {value} {unit}
          </>
        );
      case "file":
        return (
          value && (
            <a href={value}>
              <BtnBlack small>다운로드</BtnBlack>
            </a>
          )
        );
      default:
        return <></>;
    }
  };

  const ValueComponent = view
    ? viewValue()
    : {
        text: <Text {...props} />,
        radio: <Radio {...props} />,
        checkbox: <Checkbox {...props} />,
        select: <Select {...props} />,
        textarea: <Textarea {...props} />,
        date: <Date {...props} />,
        unit: <Unit {...props} />,
        formula: (
          <Formula
            formulaId={formula_id}
            formula={formula}
            values={values}
            view={view}
            data2={data}
            onChange={onChange}
          />
        ),
        file: <File {...props} />,
      }[type];
  return ValueComponent || null;
};

const PreWrap = styled.div`
  white-space: pre-wrap;
`;

export default ItemValue;

const dateCalculator = (start_date: string, end_date: string) => {
  if (!start_date || !end_date) {
    return;
  }
  const endDate = new Date(end_date);
  const startDate = new Date(start_date);
  const diffDate = endDate.getTime() - startDate.getTime();
  return Math.abs(diffDate / (1000 * 60 * 60 * 24)) + 1;
};

export default dateCalculator;

import { useSetRecoilState } from "recoil";
import { useQuery } from "react-query";
import { Link, Outlet, useParams, useSearchParams } from "react-router-dom";

import { getTemplate } from "apis/template";
import { getLocaleDate } from "utils/time";
import templateState from "stores/templateState";

import styled from "styled-components";
import SectionList from "components/templates/Template/SectionList";

const Template = () => {
  const setTemplate = useSetRecoilState(templateState);
  const { templateId = "", projectId = "" } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const reportingDT = searchParams.get('reportingDT');

  const { isLoading, data } = useQuery(
    ["getTemplate", { templateId }],
    () => getTemplate({ templateId }),
    {
      onSuccess: (data) => {
        setTemplate(data);
      },
    }
  );

  if (isLoading) return <></>;
  const { name, reporting_date } = data;

  return (
    <>
      <Title>
        {projectId === "674" && name !== "진단" && reportingDT ? (

          <Link to="">
            {name} /이전 방문일:  {reportingDT}
          </Link>

        ) :
          projectId === "674" && reportingDT === "null" ? (

            <Link to="">
              {name} / {reporting_date}
            </Link>

          ) :
            (
              <Link to="">
                {name} / {getLocaleDate(reporting_date)}
              </Link>
            )
        }
      </Title>
      <SectionList />

      <Outlet />
    </>
  );
};

const Title = styled.h3``;

export default Template;
import getBMI from "./common/getBMI";

const bmi = {
  id: 2,
  name: "bmi",
  datas: ["height", "weight"],
  formula: ({ height, weight }: any) => {
    if (!height || !weight) {
      return;
    }
    return getBMI({ height, weight });
  },
  help: `kg/m^2`,
};

export default bmi;

import PatientType from "types/patient";

const eGFR = {
  id: 5,
  name: "estimated GFR(IDMS)",
  datas: ["height", "Cr_IDMS", "cystatinC", "BUN"],
  formula: ({ height, Cr_IDMS, cystatinC, BUN }: any, { sex }: PatientType) => {
    const bySex = sex === "male" ? 1.076 : 1;
    return (
      Math.round(
        39.8 *
          (height / Cr_IDMS) ** 0.456 *
          (1.8 / (cystatinC / 1.17)) ** 0.418 *
          (30 / BUN) ** 0.079 *
          bySex *
          ((height * 100) / 1.4) ** 0.179 *
          100
      ) / 100
    );
  },
  help: `39.8 x [height/Cr(IDMS)]^0.456 x [1.8/(cystatinC/1.17)]^0.418 x [30/BUN]^0.079 x [1.076male] [1.00female] x [height/1.4]^0.179
  
  * height(m)`,
};

export default eGFR;

import NoticeType from "types/notice";
import instance from ".";

// notice: 공지사항 게시판
export const getNotices = async (params: {
  page: number;
  query: string;
  startDate: string;
  endDate: string;
}) => {
  try {
    const result = await instance.get("/notices", { params });
    return result.data;
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
  }
};

export const getNotice = async (id: string) => {
  if (!id) return;
  const result = await instance.get(`/notices/${id}`);
  return result.data;
};

export const createNotice = async (values: NoticeType) => {
  const data = {
    ...values,
  };
  const result = await instance.post(`/notices`, data);
  return result.data;
};

export const editNotice = async (values: NoticeType) => {
  const data = {
    ...values,
  };
  const result = await instance.put(`/notices/${values.id}`, data);
  return result.data;
};

export const deleteNotice = async (id: number) => {
  try {
    const response = await instance.delete(`/notices/${id}`);
    if (response.status === 200) {
      alert("공지사항을 삭제하였습니다.");
    }
    return response;
  } catch (err: any) {
    if (err.response) {
      alert(err.response.data.message);
    }
  }
};

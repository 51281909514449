import styled from "styled-components";

import { ItemType } from "types";
import { ReactComponent as ThreeLines } from "assets/icons/ThreeLines.svg";

import { BtnGreen } from "components/atoms/button";

const ItemTitle = ({ data, index }: { data: ItemType; index: string }) => {
  const { item_option_id, parent, name } = data;
  return (
    <Container>
      <ThreeLines />
      <BtnGreenWrapper>
        <BtnGreen xSmall outline={!!item_option_id}>
          {item_option_id ? "하위" : "상위"} 항목
        </BtnGreen>
      </BtnGreenWrapper>
      {index && index + "."}{" "}
      {parent.name ? `${parent.name} / ${parent.value}` : name}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0;
`;
const BtnGreenWrapper = styled.div`
  margin: 0 12px;
`;

export default ItemTitle;

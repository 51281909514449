import { OnChangeType, ItemType } from "types";

import { getTokenPayload } from "utils/user";
import { InputUnit } from "components/atoms/input";

const Unit = ({
  data,
  onChange,
  value,
  readOnly,
}: {
  data: ItemType;
  onChange: OnChangeType;
  value: any;
  readOnly?: boolean;
}) => {
  const { projectId } = getTokenPayload();
  const { id, unit, min, max, is_required } = data;

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      projectId === 675
      && (
        e.key === "ArrowUp" || e.keyCode === 38
        || e.key === "ArrowDown" || e.keyCode === 40
      )
    ) {
      e.preventDefault();
    }
  };

  return (
    <>
      <InputUnit
        type="number"
        name={id + ""}
        onChange={onChange}
        value={value ?? ""}
        readOnly={readOnly}
        min={min}
        max={max}
        required={is_required === 1}
        step="0.001"
        onKeyDown={onKeyDown}
        //onWheel={event => event.currentTarget.blur()}
      />
      {unit}
    </>
  );
};

export default Unit;

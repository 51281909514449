import { Pie } from "react-chartjs-2";

import "chart.js/auto";
import styled from "styled-components";

interface Props {
  data: number[];
  labels: string[];
}
const PieChart = ({ labels, data }: Props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "right" as const,
      },
    },
  };
  const chartData = {
    labels,
    datasets: [
      {
        type: "pie" as const,
        backgroundColor: ["#0043B7", "#FF4090", "#69D49A", "#5792F5"],
        borderWidth: 0,
        hoverBorderWidth: 0,
        data,
      },
    ],
  };

  const total = data.reduce((a, b) => a + b, 0);
  const percent = data.map((e) => Math.round((e / total) * 100 || 0) + "%");
  const dataset = chartData.datasets[0];
  return (
    <Container>
      <Wrapper>
        <Pie options={options} data={chartData} />
      </Wrapper>
      <SubContainer>
        {data.map((item, i) => {
          return (
            <SubContent key={i}>
              <ColorBox color={dataset.backgroundColor[i]} />
              <Percent>
                {labels[i]}
                <br />
                {dataset.data[i]}명({percent[i]})
              </Percent>
            </SubContent>
          );
        })}
      </SubContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
const Wrapper = styled.div`
  width: 115px;
`;
const SubContainer = styled.div`
  margin-left: 15px;
`;
const SubContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 20px;
  & + & {
    margin-top: 5px;
  }
`;
const ColorBox = styled.div`
  width: 9px;
  height: 9px;
  margin-right: 6px;
  background-color: ${({ color }) => color};
`;
const Percent = styled.p`
  line-height: 1;
`;
export default PieChart;
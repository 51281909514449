import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Sections = styled.div`
  margin: 0 -5px;
  display: flex;
  flex-wrap: wrap;
  button {
    margin: 5px;
  }
`;

export const BtnMore = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: -7px;
`;

export const BtnSection = styled.button`
  padding: 12px 15px;
  background: #fafafc;
  border: 1px solid #dfe0e0;
  color: #989899;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  display: flex;
`;

export const SectionLink = styled(NavLink)`
  &.active {
    ${BtnSection} {
      background: #ffffff;
      border: 1px solid #000000;
      color: #000;
      path {
        fill: #000;
      }
    }
  }
`;

import { KeyboardEvent } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router";

import { Backdrop } from "components/atoms/modal";
import ModalEditItem from "components/templates/admin/Item/ModalEdit";

import { getItem } from "apis/item";
import useDisableScroll from "hooks/useDisableScroll";

const PageItem = () => {
  const navigate = useNavigate();
  const { itemId = "" } = useParams();
  const { data, isFetching } = useQuery(["getItem", { itemId }], () =>
    getItem(itemId)
  );
  useDisableScroll();

  const handleClose = () => {
    navigate("..");
  };

  const handleESCDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Escape") handleClose();
  };

  return (
    <Backdrop
      onKeyDown={handleESCDown}
      onClick={(e) => {
        if (e.currentTarget !== e.target) return;
        handleClose();
      }}
    >
      {!isFetching && (
        <ModalEditItem data={data} onClose={handleClose} index={""} />
      )}
    </Backdrop>
  );
};

export default PageItem;

import { Link, Outlet, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import { getSection } from "apis/section";
import { getTemplate } from 'apis/template';

import styled from "styled-components";
import { BtnBlue } from "components/atoms/button";
import { SpaceBetween, TemplateHeader } from "components/atoms/common";
import ViewTemplate from "components/organisms/item/View";

const Section = () => {
  const { projectId = "", templateId = "", sectionId = "", patientId = "" } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const reportingDT = searchParams.get('reportingDT');

  const { isLoading: isLoadingTemplate, data: template } = useQuery(
    ["getTemplate", { templateId }],
    () => getTemplate({ templateId })
  );

  const { isLoading, data: section } = useQuery(
    ["getSection", { templateId, sectionId, patientId }],
    () => getSection({ templateId, sectionId, patientId })
  );

  if (isLoadingTemplate || isLoading) return <></>;
  const { name, items } = section;

  return (
    <div>
      <SpaceBetween noMargin>
        <div></div>
        {projectId == "674" && template.name !== "진단" && reportingDT ? (
          <Link to={{ pathname: "edit", search: 'reportingDT=' + reportingDT }}>
            <BtnBlue outline>입력하기</BtnBlue>
          </Link>
        ) :
          projectId == "674" && reportingDT === "null" ? (
            <Link to="edit">
              <BtnBlue outline>입력하기</BtnBlue>
            </Link>
          ) : (
            <Link to="edit">
              <BtnBlue outline>입력하기</BtnBlue>
            </Link>
          )
        }
      </SpaceBetween>
      <Container>
        <TemplateHeader>{name}</TemplateHeader>
        <ViewTemplate data={items} />
      </Container>
      <SpaceBetween>
        <div></div>
        {projectId == "674" && template.name !== "진단" && reportingDT ? (
          <Link to={{ pathname: "edit", search: 'reportingDT=' + reportingDT }}>
            <BtnBlue outline>입력하기</BtnBlue>
          </Link>
        ) :
          projectId == "674" && reportingDT === "null" ? (
            <Link to="edit">
              <BtnBlue outline>입력하기</BtnBlue>
            </Link>
          ) : (
            <Link to="edit">
              <BtnBlue outline>입력하기</BtnBlue>
            </Link>
          )
        }
      </SpaceBetween>

      <Outlet />
    </div>
  );
};

const Container = styled.div`
  margin-top: 35px;
`;

export default Section;

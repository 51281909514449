import { useRecoilValue } from "recoil";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Main from "./pages/Main";
import Project from "./pages/Project";
import Patient from "./pages/Patient";
import PatientCreate from "./pages/PatientCreate";
import PatientInfo from "./pages/PatientInfo";
import Section from "./pages/Section";

import DataEdit from "./pages/DataEdit";
import Template from "./pages/Template";

import Login from "./pages/Login";
import Empty from "./pages/Empty";
import Dashboard from "./pages/Dashboard";

import SideBar from "./organisms/SideBar";
import TokenCheck from "./organisms/TokenCheck";

import AdminRouter from "./AdminRouter";
import PrivateRoute from "./PrivateRoute";
import isLoginState from "stores/isLoginState";
import Templates from "./pages/Templates";
import Sections from "./pages/Sections";

import Query from "./pages/Query";
import SampleManagement from "./pages/sample/SampleManagement";
import SampleList from "./pages/sample/SampleList";
import SampleRequest from "./pages/sample/request/detail/SampleRequest";
import SampleStock from "./pages/sample/stock/detail/SampleStock";

import Notice from "./pages/Notice";
import NoticeWrap from "./pages/NoticeWrap";

import Board from "./pages/Board";
import BoardWrap from "./pages/BoardWrap";

const Router = () => {
  const isLoggedIn = useRecoilValue(isLoginState);
  return (
    <BrowserRouter>
      <Routes>
        {!isLoggedIn && <Route path="/login" element={<Login />} />}
        <Route element={<TokenCheck />}>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<SideBar />}>
              <Route path="/" element={<Main />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/querys" element={<Query />} />{" "}
              {/*// 20230210 오토쿼리 관련 추가 by 장희준*/}
              <Route path="/sample-management" element={<SampleManagement />}>
                <Route path="list" element={<SampleList />}></Route>
                <Route path="request" element={<SampleRequest />} />
                <Route path="request/:sampleRequestId" element={<SampleRequest />} />
                <Route path="stock/:patientId/template/:templateDefaultId" element={<SampleStock />} />
              </Route>
              <Route path="project/:projectId">
                <Route path="" element={<Project />} />
                <Route path="patient" element={<PatientCreate />} />
                <Route path="patient/:patientId" element={<Patient />}>
                  <Route path="" element={<PatientInfo />} />
                  <Route path="template" element={<Templates />} />

                  <Route path="template/:templateId" element={<Template />}>
                    <Route path="" element={<Sections />} />
                    <Route path="section/:sectionId" element={<Section />}>
                      <Route path="edit" element={<DataEdit />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route path="/admin">
                <Route path="*" element={<AdminRouter />} />
              </Route>
              <Route path="/notices" element={<Notice />} />
              <Route path="/notices/new" element={<NoticeWrap />} />
              <Route path="/notices/edit/:noticeId" element={<NoticeWrap />} />
              <Route path="/notices/:noticeId" element={<NoticeWrap />} />
              <Route path="/boards" element={<Board />} />
              <Route path="/boards/new" element={<BoardWrap />} />
              <Route path="/boards/edit/:boardId" element={<BoardWrap />} />
              <Route path="/boards/:boardId" element={<BoardWrap />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Empty />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

import instance from ".";

export const createItemOption = async (data: {
  itemId: number;
  value: string;
  help: string;
}) => {
  const result = await instance.post(`/item-options`, data);
  return result.data;
};

export const editItemOption = async (data: {
  id: number;
  value: string;
  position: number;
  help: string;
}) => {
  const result = await instance.put(`/item-options/${data.id}`, data);
  return result.data;
};

export const getItemOptions = async (params: { itemId: number }) => {
  const result = await instance.get(`/item-options`, { params });
  return result.data;
};

export const deleteItemOption = async (id: number) => {
  const result = await instance.delete(`/item-options/${id}`);
  return result.data;
};

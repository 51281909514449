import { FormEvent, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";

import { BtnGray, BtnPink, BtnWhite } from "components/atoms/button";
import { Click, SpaceBetween, TemplateHeader } from "components/atoms/common";
import { Items, Name, Value } from "components/atoms/item";
import { Textarea } from "components/atoms/input";
import { ModalContainer } from "components/atoms/modal";
import { Table, TD, TH, TR } from "components/atoms/table";
import { Footer, QueryItem, QueryName, QueryValue } from "components/atoms/query";
import Pagination from "components/organisms/Pagination";

import useInputs from "hooks/useInputs";

import { getQuery, editQuery, createQuery, manualQuery, getQueryAnswers } from "apis/query";

import { getTokenPayload } from "utils/user";
import { getLocaleDateTime } from "utils/time";

import { QueryAnswerType } from "types/query";

import Modal from "components/organisms/Modal";
import ModalAnswer from "./ModalAnswer";
import { QueryTD } from "components/pages/Query";

const ModalQuery = ({
  onClose,
  data,
}: {
  onClose: () => void;
  data?: any;
}) => {

  const display = 3;
  const [page, setPage] = useState(1);
  const textRef = useRef(null);

  const { isSuper, isProjectSuper, projectId } = getTokenPayload();

  const [state, onChange] = useInputs({ ...data });
  const { id, patient_id, admin_id, item_id } = state;


  const mutation = useMutation(
    () => (id ? editQuery(state) : createQuery(state)),
    {
      onSuccess: () => {
        alert("저장 성공");
        onClose();
      },
      onError: ({ response: { data } }) => {
        alert(data.message);
      },
    }
  );


  const { data: query, isLoading } = useQuery(
    ["getQuery", { id, item_id, patient_id }],
    () => id ? getQuery({ id, item_id }) : manualQuery({ item_id, patient_id })
    , { refetchOnWindowFocus: true }
  );

  const { data: query_answers, isLoading: isAnswerLoading } = useQuery(
    ["getQueryAnswers", { queryId: id, page, display, isSuper, isProjectSuper }],
    () => id ? getQueryAnswers({ queryId: id, page, display, isSuper, isProjectSuper })
      : () => { return { total: 0 }; }
    , { refetchOnWindowFocus: true }
  );

  useEffect(() => {
    if (!isLoading)
      onChange({ target: query });
  }, []);

  if (isLoading || isAnswerLoading) return <></>;

  const { total, items } = query_answers;
  const { patient_name, admin_name, item_name, state_name, value, value_option } = query;

  const querySave = async (e: FormEvent) => {
    e.preventDefault();
    if (mutation.isLoading) return;
    if (window.confirm(`쿼리를 ${id ? "수정" : "추가"}하시겠습니까?`)) {
      mutation.mutate();
    }
  };

  return (
    <ModalContainer large={true}>
      <TemplateHeader>쿼리 {id ? "수정" : "추가"}</TemplateHeader>
      <Items>
        <QueryItem>
          <QueryName>순번</QueryName>
          <QueryName>대상자번호</QueryName>
          <QueryName>담당자명</QueryName>
          <QueryName>항목명</QueryName>
          <QueryName>상태</QueryName>
        </QueryItem>
        <QueryItem hsize={40}>
          <QueryValue>{query.id}</QueryValue>
          <QueryValue>{query.patient_name}</QueryValue>
          <QueryValue>{query.admin_name}</QueryValue>
          <QueryValue>{query.item_name}</QueryValue>
          <QueryValue>{query.state_name}</QueryValue>
        </QueryItem>
        <QueryItem>
          <Name>내용</Name>
          <Value>
            <Textarea
              ref={textRef}
              name="content"
              onChange={onChange}
              readOnly={(isProjectSuper !== 0 && !query.content) ? false : true}
              defaultValue={query.content}
            />
          </Value>
        </QueryItem>
      </Items>
      {id &&
        <>
          <SpaceBetween />
          <Table>
            <thead>
              <TR>
                <TH>순번</TH>
                <TH>상태</TH>
                <TH>내용</TH>
                <TH>작성자명</TH>
                <TH>작성일시</TH>
              </TR>
            </thead>
            <tbody>
              {items.map(
                (
                  {
                    rn,
                    id,
                    query_id,
                    admin_id,
                    content,
                    state_code,
                    created_at,
                    admin_name,
                    item_name,
                    patient_name,
                    state_name,
                  }: QueryAnswerType,
                  i: number
                ) => (
                  <TR key={id}>
                    <TD>{rn}</TD>
                    <TD>{state_name}</TD>
                    <QueryTD>
                      <Modal
                        activator={(onOpen) => (<Click onClick={onOpen}>{content?.length < 50 ? content : (content?.slice(0, 40) || '') + '...'}</Click>)}
                        content={(onClose) => (<ModalAnswer onClose={onClose} data={{
                          rn, id, query_id, admin_id, content, state_code, created_at,
                          admin_name, item_name, patient_name, state_name
                        }} />)}
                      />
                    </QueryTD>
                    <TD>{admin_name}</TD>
                    <TD>{getLocaleDateTime(created_at)}</TD>
                  </TR>
                )
              )}
            </tbody>
          </Table>
          <Footer>
            <Pagination
              total={total}
              display={display}
              page={page}
              onChange={(page: number) => setPage(page)}
            />
          </Footer>
        </>
      }
      <SpaceBetween>
        <BtnWhite large onClick={onClose}>취소</BtnWhite>
        {(isProjectSuper !== 0 && !query.content) && <BtnPink large type="button" onClick={querySave}>저장</BtnPink>}
        {/* {(isProjectSuper !== 0 && id) && <BtnPink large type="button" onClick={queryClose}>close</BtnPink>} */}
        {id &&
          <Modal
            activator={(onOpen) => (
              (query.content === null
                || (query.state_code === 3 && isProjectSuper === 0) ? <BtnGray large type="button">추가</BtnGray>
                : <BtnPink large type="button" onClick={onOpen}>추가</BtnPink>)
            )}
            content={(onClose) => (
              <ModalAnswer onClose={onClose} data={{ query_id: id, patient_name, admin_name, item_name, value, value_option }} />
            )}
          />
        }
      </SpaceBetween>
    </ModalContainer>
  );
};

export default ModalQuery;

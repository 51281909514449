import { getPrednisoloneDose as getTotalDose } from "./common/getCumulativeDose";

const totalDose5 = {
  id: 31,
  name: "Total Dose (5)",
  datas: [
    "daily_dose_1",
    "daily_dose_2",
    "daily_dose_3",
    "daily_dose_4",
    "daily_dose_5",
    "start_date_1",
    "end_date_1",
    "start_date_2",
    "end_date_2",
    "start_date_3",
    "end_date_3",
    "start_date_4",
    "end_date_4",
    "start_date_5",
    "end_date_5",
  ],
  formula: ({
    daily_dose_1,
    daily_dose_2,
    daily_dose_3,
    daily_dose_4,
    daily_dose_5,
    start_date_1,
    end_date_1,
    start_date_2,
    end_date_2,
    start_date_3,
    end_date_3,
    start_date_4,
    end_date_4,
    start_date_5,
    end_date_5,
  }: any) => {
    if (!daily_dose_1) {
      return;
    }
    return getTotalDose([
      [daily_dose_1, start_date_1, end_date_1],
      [daily_dose_2, start_date_2, end_date_2],
      [daily_dose_3, start_date_3, end_date_3],
      [daily_dose_4, start_date_4, end_date_4],
      [daily_dose_5, start_date_5, end_date_5],
    ]);
  },
  help: `누적용량`,
};

export default totalDose5;
